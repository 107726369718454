import { FIELDS, FieldsGroup } from "../../../fields.d";
import { Document } from "../documents";

export type ContractPreparationRequestInputs = {
  parentIds?: Document[];
  contractorName: string;
  contractorPesel: string;
  serviceType: string;
  contractTypes?: { id: number; label?: string }[];
  contractType?: string;
  beginAt: Date | null;
  endAt: Date | null;
  grossHourlyRate: number | string;
  grossFlatRate: number | string;
  otherGrossRates: string;
  isOrganizationCoveringTravelExpenses: boolean;
  isOrganizationCoveringMaterialCosts: boolean;
  isRequiringDataProcessingAuthorization: boolean;
  isGrossValueIncreasedBySocialInsurance: boolean;
  files?: FileList;
  cases?: { id: number; label?: string }[];
  caseId: number;
  organizationalUnits: { id: number; label?: string }[];
  organizationalUnitId: number;
  currencies?: {
    id: string;
    label?: string;
    code?: string;
    currencyId?: string;
  }[];
  currencyId?: number;
};

export type LinkFormInputs = {
  parentIds: Document[];
};

export const CONTRACT_PREPARATION_REQUEST_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "contractTypes",
        label: "Rodzaj umowy",
        type: FIELDS.DictionaryValuesSelect,
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        placeholder: "Wybierz",
      },
      {
        id: "contractType",
        label: "Rodzaj umowy",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        placeholder: "Wybierz",
      },
      {
        id: "parentIds",
        label: "Wniosek o wydatkowanie",
        type: FIELDS.RequestFormsSelect,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "organizationalUnits",
        label: "Jednostka organizacyjna",
        type: FIELDS.UsersOrganizationalUnitsSelect,
        span: 6,
        dataType: "model:organizationalUnit",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        placeholder: "Wybierz",
      },
      {
        id: "serviceType",
        label: "Rodzaj wykonywanej usługi",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "organizationalUnits",
        label: "Jednostka organizacyjna",
        type: FIELDS.Input,
        span: 3,
        dataType: "model:organizationalUnit",
        show: { visible: true, accessor: ["organizationalUnit", "name"] },
        create: { visible: false },
        edit: { visible: false },
        placeholder: "Wybierz",
      },
      {
        id: "parentIds",
        label: "Wniosek o wydatkowanie",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "requestFormAmount",
        label: "Kwota brutto z wniosku",
        type: FIELDS.Input,
        dataType: "quota",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "state",
        label: "Stan dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "document-state",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "createdAt",
        label: "Data rejestracji w systemie",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdBy",
        label: "Autor",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "responsible",
        label: "Odpowiedzialny",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "serviceType",
        label: "Rodzaj wykonywanej usługi",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "case",
    label: "Znak sprawy",
    fields: [
      // {
      //   id: "cases",
      //   label: "",
      //   type: FIELDS.CasesSelect,
      //   span: 12,
      //   show: { visible: false },
      //   create: { visible: true },
      //   edit: { visible: true },
      //   placeholder: "Wybierz",
      //   caption:
      //     "W tym polu należy wybrać odpowiedni znak sprawy z jaką zostanie powiązany dokument. Nie jest to obowiązkowe na etapie rejestracji dokumentu w recepcji",
      // },
      {
        id: "case",
        label: "",
        type: FIELDS.Input,
        span: 12,
        show: { accessor: ["case", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        additionalData: "isConductedElectronically",
        dataType: "model:cases",
      },
    ],
  },
  {
    id: "contractor",
    label: "Dane wykonawcy",
    fields: [
      {
        id: "contractorName",
        label: "Imię i nazwisko",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "contractorPesel",
        label: "PESEL",
        type: FIELDS.NumberInput,
        span: 4,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
    ],
  },
  {
    id: "contractData",
    label: "Dane do umowy",
    fields: [
      {
        id: "currencies",
        label: "Waluta",
        type: FIELDS.CurrencySelect,
        placeholder: "PLN",
        span: 3,
        show: { visible: true, accessor: ["currency", "code"] },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "grossFlatRate",
        label: "Określona kwota brutto",
        type: FIELDS.NumberInput,
        dataType: "quota",
        endEnhancer: "PLN",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "grossHourlyRate",
        label: "Stawka godzinowa brutto",
        type: FIELDS.NumberInput,
        dataType: "quota",
        endEnhancer: "PLN",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "otherGrossRates",
        label: "Inne stawki brutto",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "beginAt",
        label: "Początek obowiązywania umowy",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "endAt",
        label: "Koniec obowiązywania umowy",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "isOrganizationCoveringTravelExpenses",
        label:
          "Poniesienie kosztów związanych z odbyciem podróży niezbędnej do wykonania usługi",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        info: "firstCheckbox",
      },
      {
        id: "isOrganizationCoveringMaterialCosts",
        label:
          "Poniesienie kosztów związanych z zakupem materiałów niezbędnych do wykonania usługi",
        type: FIELDS.Checkbox,
        dataType: "boolean",

        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isRequiringDataProcessingAuthorization",
        label:
          "Umowa wymaga sporządzenia upoważnienia do przetwarzania danych osobowych",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isGrossValueIncreasedBySocialInsurance",
        label:
          "Kwota brutto wynagrodzenia/stawek zostanie zwiększona o obowiązkowo płatne przez FRSE składki na ubezpieczenie społeczne",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        info: "lastCheckbox",
      },
    ],
  },
  {
    id: "files",
    label: "Załączniki",
    fields: [
      {
        id: "filesList",
        label: "",
        type: FIELDS.FilesList,
        span: 12,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
        caption:
          "Kliknięcie ikony kosza spowoduje usunięcia załącznika po zapisaniu dokumentu",
      },
      {
        id: "files",
        label: "Dodaj nowy",
        type: FIELDS.FilesPicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        caption: "Maksymalny rozmiar pliku to 128 MB.",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
    ],
  },
];
