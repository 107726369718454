import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  ArrowBack,
  ArrowBigRightLines,
  ArrowForwardUp,
  Check,
  ChevronDown,
  ClipboardList,
  Edit,
  EditOff,
  FileArrowLeft,
  FileCheck,
  FileX,
  FileZip,
  FolderPlus,
  Folders,
  HandGrab,
  HandOff,
  Link,
  Lock,
  LockOpen,
  Pencil,
  Send,
  Settings,
  Share,
  UserPlus,
  X,
} from "tabler-icons-react";

import AssignedDocumentsTable from "../../../../components/assigned-documents-table";
import AttachmentsTable from "../../../../components/attachments-table";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import Content from "../../../../components/content";
import DocumentBarcode from "../../../../components/document-barcode";
import {
  DocumentCommonModalsRenderer,
  DocumentModalName,
} from "../../../../components/document-common-modals-renderer";
import FlowActionModal from "../../../../components/flow-action-modal";
import FlowProgress from "../../../../components/flow-progress";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import HistoryTable from "../../../../components/history-table";
import {
  DOCUMENT_STATES,
  HIDDEN_FILE,
  NOT_FOUND_ERROR,
} from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useDictionaries } from "../../../../contexts/dictionaries-context";
import { useLoading } from "../../../../contexts/loading-context";
import { useSubstitution } from "../../../../contexts/substitution-context";
import { FILTERS } from "../../../../filters.d";
import { checkPermission } from "../../../../utils/check-permission";
import {
  handleDataForProgressBar,
  handleHistoryFlowSteps,
} from "../../../../utils/document-flow";
import {
  checkIsDispatchable,
  checkIsShareable,
} from "../../../../utils/documents/assignment-actions";
import { renderMutationSuccessLabel } from "../../../../utils/documents/mutations-snackbar";
import { generateUUID } from "../../../../utils/misc";
import { PERMISSIONS } from "../../../../utils/permissions";
import Error404 from "../../../ErrorPages/404";
import {
  DocumentFlowAction,
  HistoryFlowStep,
} from "../../../Flow/document-flow";
import {
  AssignmentLocationState,
  AssignmentType,
} from "../../../Folders/folders.d";
import {
  DocumentLink,
  DocumentMutationAction,
  DocumentStatus,
} from "../../documents.d";
import {
  DOCUMENT_FLOW_AVAILABLE_ACTIONS,
  DOCUMENTS_ACCEPT,
  DOCUMENTS_CANCEL_DISPATCH,
  DOCUMENTS_COMPLETE_EDITION,
  DOCUMENTS_RELEASE_RESPONSIBLE,
  DOCUMENTS_SET_RESPONSIBLE,
  DOCUMENTS_START_EDITION,
  DOCUMENTS_TRACK_OPEN,
  DOCUMENTS_ZIP_EXPORT,
  FILES_REVEAL,
} from "../../documents.gql";
import { REQUEST_FORM_NOTE_FIELDS } from "../request-form-note.form";
import { REQUEST_FORM_NOTE_SHOW } from "../request-form-note.gql";

export default function DocumentsShowRequestFormNote(): React.ReactElement {
  const [isFlowActionModalOpen, setIsFlowActionModalOpen] = useState(false);
  const [targetFlowAction, setTargetFlowAction] = useState<
    DocumentFlowAction | undefined
  >(undefined);

  const [flowStepsHistory, setFlowStepsHistory] = useState<HistoryFlowStep[]>(
    []
  );
  const [statusesForProgressBar, setStatusesForProgressBar] = useState<
    DocumentStatus[]
  >([]);

  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [
    openedCommonModal,
    setOpenedCommonModal,
  ] = useState<DocumentModalName>();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);
  const { refetch: userRefetch, user } = useAuth();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();
  const { findValue } = useDictionaries();
  const { substitution, isSubstitutionActive } = useSubstitution();

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const assignmentContext = {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  };

  const { refetch, data, error } = useQuery(REQUEST_FORM_NOTE_SHOW, {
    variables: { id: documentId },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const documentLinks = data?.requestFormNote?.documentLinks;

  const assignedDocuments = documentLinks?.filter(
    ({ type }: DocumentLink) => type === null
  );

  const files = data?.requestFormNote?.files;

  const hasHiddenFiles =
    !!files?.length && files[0]?.__typename === HIDDEN_FILE;

  const {
    data: availableActionsData,
    refetch: availableActionsRefetch,
  } = useQuery(DOCUMENT_FLOW_AVAILABLE_ACTIONS, {
    variables: {
      documentId,
    },
  });

  const availableActionsButtons = availableActionsData?.documentFlowAvailableActions?.map(
    (action: DocumentFlowAction) => {
      return {
        label: action.label,
        kind: KIND.primary,
        startEnhancer:
          action.style === "positive" ? (
            <Check size={18} />
          ) : action.style === "negative" ? (
            <X size={18} />
          ) : (
            <Send size={18} />
          ),
        isPositive: action.style === "positive",
        isNegative: action.style === "negative",
        onClick: () => {
          setTargetFlowAction(action);
          setIsFlowActionModalOpen(true);
        },
      };
    }
  );

  const hasFlowSteps = !!data?.requestFormNote?.currentStatus;
  const hasAnySignaturesBefore = statusesForProgressBar?.some(
    (status: DocumentStatus) => !!status.signatureDownloadUrl
  );
  const lastStatusWithSignature = statusesForProgressBar
    ?.filter((status: DocumentStatus) => !!status.signatureDownloadUrl)
    .pop();

  useEffect(() => {
    refetch();
  }, [data?.requestFormNote?.currentStatus]);

  const [acceptDocument] = useMutation(DOCUMENTS_ACCEPT, assignmentContext);

  const [cancelDispatch] = useMutation(DOCUMENTS_CANCEL_DISPATCH);

  const [completeEdition] = useMutation(
    DOCUMENTS_COMPLETE_EDITION,
    assignmentContext
  );

  const [exportZipFile] = useMutation(DOCUMENTS_ZIP_EXPORT, assignmentContext);

  const [releaseResponsible] = useMutation(
    DOCUMENTS_RELEASE_RESPONSIBLE,
    assignmentContext
  );

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    assignmentContext
  );

  const [setResponsible] = useMutation(
    DOCUMENTS_SET_RESPONSIBLE,
    assignmentContext
  );

  const [startEdition] = useMutation(
    DOCUMENTS_START_EDITION,
    assignmentContext
  );

  const [trackOpen] = useMutation(DOCUMENTS_TRACK_OPEN, assignmentContext);

  const documentMutationVariables = {
    variables: {
      documentId,
    },
  };

  const onDocumentMutationSubmit = async (
    action: DocumentMutationAction
  ): Promise<void> => {
    setIsLoading(true);

    try {
      if (action === DocumentMutationAction.Accept)
        await acceptDocument(documentMutationVariables);

      if (action === DocumentMutationAction.CancelDispatch)
        await cancelDispatch(documentMutationVariables);

      if (action === DocumentMutationAction.CompleteEdition)
        await completeEdition(documentMutationVariables);

      if (action === DocumentMutationAction.ExportZip) {
        const response = await exportZipFile(documentMutationVariables);

        const apiUrl = process.env.REACT_APP_GRAPHQL_API_URL?.replace(
          "/graphql",
          ""
        );

        window.open(
          `${apiUrl}/documents/download-zip-export/${id}/${response?.data?.documentExportZip}`,
          "_self"
        );
      }

      if (action === DocumentMutationAction.ReleaseResponsible)
        await releaseResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.RevealFiles)
        await revealFiles(documentMutationVariables);

      if (action === DocumentMutationAction.SetResponsible)
        await setResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.StartEdition)
        await startEdition(documentMutationVariables);

      enqueueSnackbar({
        message: renderMutationSuccessLabel(action),
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
      setOpenedCommonModal(undefined);
    }
  };

  useEffect(() => {
    if (
      error?.graphQLErrors &&
      (error as ApolloError).graphQLErrors?.map(
        ({ extensions }) => extensions?.code
      )[0] !== NOT_FOUND_ERROR
    )
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.requestFormNote) refetch();
    if (availableActionsData?.documentFlowAvailableActions)
      availableActionsRefetch();
    setIsFetching(true);
    setStatusesForProgressBar([]);
    trackOpen({ variables: { id: documentId } }).then(() => userRefetch());
  }, []);

  useEffect(() => {
    if (availableActionsData) availableActionsRefetch();
  }, [data?.requestFormNote?.currentStatus]);

  useEffect(() => {
    if (data?.requestFormNote?.currentAssignment) {
      setAssignment(data?.requestFormNote?.currentAssignment);
      setIsAssignmentActive(true);
    }
    setFlowStepsHistory(
      handleHistoryFlowSteps(data?.requestFormNote?.statuses)
    );
    setStatusesForProgressBar(
      handleDataForProgressBar(data?.requestFormNote?.statuses)
    );
    if (data?.requestFormNote) setIsFetching(false);
  }, [data]);

  if (
    !checkPermission(PERMISSIONS.requestFormNote.read) &&
    !checkPermission(PERMISSIONS.document.readAll)
  ) {
    return <Redirect to="/" />;
  } else if (
    error?.graphQLErrors &&
    (error as ApolloError).graphQLErrors?.map(
      ({ extensions }) => extensions?.code
    )[0] === NOT_FOUND_ERROR
  ) {
    return <Error404 />;
  }

  return (
    <article>
      <Header
        title={data?.requestFormNote?.internalNumber}
        labels={[
          <FormattedValue
            key="label_1"
            dataType={"model:cases"}
            data={data?.requestFormNote?.case?.id}
            additionalData={
              data?.requestFormNote?.case?.isConductedElectronically
            }
            showBlankWhenEmpty
          >
            {`${data?.requestFormNote?.case?.number}`}
          </FormattedValue>,
          "Dokument",
        ]}
        goBackOption
        hasActiveAssignmentBar
        hasCancellationBar={
          data?.requestFormNote?.state === DOCUMENT_STATES.Canceled
        }
        buttons={[
          {
            label: "Przejmij",
            startEnhancer: <HandGrab size={18} />,
            kind: KIND.secondary,
            isPositive: true,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.SetResponsible),
            permission: data?.requestFormNote?.isResponsibleSettable,
          },
          {
            label: "Zwolnij",
            startEnhancer: <HandOff size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(
                DocumentMutationAction.ReleaseResponsible
              ),
            permission:
              data?.requestFormNote?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) &&
              data?.requestFormNote?.isReleasable &&
              !data?.requestFormNote?.isEditCompleted,
          },
          {
            label: "Zakończ edycję",
            startEnhancer: <EditOff size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.CompleteEdition),
            permission:
              data?.requestFormNote?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) &&
              !data?.requestFormNote?.isEditCompleted &&
              !data?.documerequestFormNotent?.isDocumentUsedInFlow,
          },
          {
            label: "Anuluj zakończenie edycji",
            startEnhancer: <Edit size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.StartEdition),
            permission:
              data?.requestFormNote?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) && data?.requestFormNote?.isEditCompleted,
          },
          {
            label: "Załóż sprawę",
            kind: KIND.primary,
            startEnhancer: <Link size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            permission:
              !data?.requestFormNote?.case?.id &&
              data?.requestFormNote?.isEditable,
            subactions: [
              {
                label: "Załóż nową sprawę",
                icon: FolderPlus,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("case-create"),
                permission:
                  checkPermission(PERMISSIONS.case.create) &&
                  data?.requestFormNote?.isEditable &&
                  data?.requestFormNote?.state !== DOCUMENT_STATES.Canceled,
              },
              {
                label: "Przypisz do istniejącej",
                icon: Folders,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("assign-to-case"),
                permission:
                  checkPermission(PERMISSIONS.document.update) &&
                  data?.requestFormNote?.isEditable &&
                  data?.requestFormNote?.state !== DOCUMENT_STATES.Canceled,
              },
            ],
          },
          {
            label: "Udostępnij",
            kind: KIND.primary,
            startEnhancer: <Share size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Udostępnij",
                icon: UserPlus,
                color: theme.colors.primary,
                permission: checkIsShareable(data?.requestFormNote),
                onClick: () =>
                  history.push(
                    location.pathname + `/share`,
                    state || data?.requestFormNote?.currentAssignment?.id
                      ? {
                          assignmentId:
                            data.requestFormNote.currentAssignment?.id,
                        }
                      : undefined
                  ),
              },
              {
                label: "Zarządzaj dostępem",
                icon: Settings,
                color: theme.colors.contentPrimary,
                onClick: () =>
                  history.push(
                    `/folders/shared?filters=${window.btoa(
                      unescape(
                        encodeURIComponent(
                          JSON.stringify([
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "primaryAssignableId",
                                label: "Obiekt",
                                type: FILTERS.PrimaryAssignable,
                              },
                              state: {
                                type: "Document",
                                value: data?.requestFormNote?.id,
                                internalNumber:
                                  data?.requestFormNote?.internalNumber,
                              },
                            },
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "status",
                                label: "Status",
                                type: FILTERS.AssignmentStatus,
                              },
                              state: {
                                value: [
                                  { id: "New", label: "Nowe" },
                                  { id: "InProgress", label: "W realizacji" },
                                ],
                              },
                            },
                          ])
                        )
                      )
                    )}`
                  ),
              },
            ],
            permission: !data?.requestFormNote?.documentFlowId,
          },
          {
            label: "Przekaż",
            startEnhancer: <ArrowForwardUp size={18} />,
            kind: KIND.primary,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Przekaż dokument",
                icon: ArrowForwardUp,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("document-dispatch"),
              },
              {
                label: "Zwróć dokument",
                icon: ArrowBack,
                color: theme.colors.negative,
                onClick: () =>
                  setOpenedCommonModal("document-dispatch-with-dispatcher"),

                permission:
                  !!data?.requestFormNote?.currentAssignment?.assigner?.id &&
                  data?.requestFormNote?.currentAssignment?.type !==
                    AssignmentType.Initial,
              },
            ],
            permission: checkIsDispatchable(data?.requestFormNote),
          },
          {
            label: "Anuluj przekazanie dokumentu",
            startEnhancer: <ArrowBack size={18} />,
            kind: KIND.secondary,
            isNegative: true,
            onClick: () => setOpenedCommonModal("cancel-assignment"),
            permission: data?.requestFormNote?.isDispatchCancelable,
          },
          {
            label: "Akceptuj",
            startEnhancer: <Check size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              !data?.requestFormNote?.documentFlowId &&
              data?.requestFormNote?.isDispatchable &&
              !!data?.requestFormNote?.currentAssignment
                ?.isAssessmentRequested &&
              data?.requestFormNote?.state !== DOCUMENT_STATES.Canceled,
            onClick: () =>
              setOpenedCommonModal("document-assignment-assessment-accept"),
          },
          {
            label: "Odrzuć",
            startEnhancer: <X size={18} />,
            kind: KIND.primary,
            isNegative: true,
            permission:
              !data?.requestFormNote?.documentFlowId &&
              data?.requestFormNote?.isDispatchable &&
              !!data?.requestFormNote?.currentAssignment
                ?.isAssessmentRequested &&
              data?.requestFormNote?.state !== DOCUMENT_STATES.Canceled,
            onClick: () =>
              setOpenedCommonModal("document-assignment-assessment-reject"),
          },
          {
            label: "Rozpocznij obieg",
            startEnhancer: <ArrowBigRightLines size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              data?.requestFormNote?.isEditable &&
              !data?.requestFormNote?.documentFlowId &&
              !!data?.requestFormNote?.documentFlow &&
              !data?.requestFormNote?.isEditCompleted,
            onClick: () => setOpenedCommonModal("document-flow-start"),
          },
          ...(availableActionsButtons || []),
        ]}
        actions={[
          {
            label: "Edytuj dokument",
            icon: Pencil,
            permission:
              checkPermission(PERMISSIONS.requestFormNote.update) &&
              data?.requestFormNote?.isEditable,
            onClick: () =>
              history.push(
                `/documents/internal/RequestFormNote/${id}/edit`,
                state
              ),
          },
          {
            label: "Akceptuj dokument",
            icon: FileCheck,
            color: theme.colors.positive,
            permission:
              data?.requestFormNote?.isEditable &&
              ((data?.requestFormNote?.isDispatchable &&
                !data?.requestFormNote?.documentFlowId &&
                !data?.requestFormNote?.currentAssignment
                  ?.isAssessmentRequested) ||
                !data?.requestFormNote?.isDispatchable),
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.Accept),
          },
          {
            label: "Powiąż z dokumentem",
            icon: Link,
            color: theme.colors.primary,
            onClick: () => setOpenedCommonModal("document-link"),
            permission:
              checkPermission(PERMISSIONS.document.update) &&
              data?.document?.isEditable &&
              data?.document?.state !== DOCUMENT_STATES.Canceled,
          },
          {
            label: "Anuluj dokument",
            icon: FileX,
            color: theme.colors.negative,
            onClick: () => setOpenedCommonModal("document-cancel"),
            permission:
              checkPermission(PERMISSIONS.document.cancel) &&
              data?.requestFormNote?.isEditable &&
              (data?.requestFormNote?.state === DOCUMENT_STATES.New ||
                data?.requestFormNote?.currentStatus?.documentFlowStep
                  ?.isInitial),
          },
          {
            label: "Cofnij anulowanie dokumentu",
            icon: FileArrowLeft,
            color: theme.colors.positive,
            onClick: () => setOpenedCommonModal("document-uncancel"),
            permission:
              checkPermission(PERMISSIONS.document.cancel) &&
              data?.requestFormNote?.state === DOCUMENT_STATES.Canceled,
          },
          {
            label: "Pokaż logi dokumentu",
            icon: ClipboardList,
            color: theme.colors.black,
            onClick: () => setOpenedCommonModal("activity-logs"),
            permission: checkPermission(PERMISSIONS.document.read),
          },
          {
            label: "Pobierz plik ZIP",
            icon: FileZip,
            color: theme.colors.black,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.ExportZip),
          },
        ]}
      >
        <DocumentBarcode
          documentId={documentId}
          internalNumber={data?.requestFormNote?.internalNumber}
        />
      </Header>

      <Content>
        <Grid>
          <Cell span={12}>
            <>
              {hasFlowSteps &&
                availableActionsData &&
                (isFetching ? (
                  <Skeleton rows={0} height="150px" width="100%" animation />
                ) : (
                  <FlowProgress
                    currentStep={
                      data?.requestFormNote?.currentStatus?.documentFlowStep
                    }
                    originalOrganizationalUnitName={
                      data?.requestFormNote?.organizationalUnit?.name
                    }
                    statuses={statusesForProgressBar}
                    actions={availableActionsData.documentFlowAvailableActions}
                  />
                ))}
            </>
          </Cell>
          {REQUEST_FORM_NOTE_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell
                  span={item.span || 6}
                  skip={item.skip || 0}
                  key={group.id + `-field` + index}
                >
                  <FormControl
                    label={item.label}
                    disabled={true}
                    overrides={{
                      ControlContainer: {
                        props: {
                          "data-test-id": item.id,
                        },
                      },
                    }}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "case"
                            ? data?.requestFormNote?.case?.id
                            : item.id === "organizationalUnit"
                            ? data?.requestFormNote?.organizationalUnit?.id
                            : item.id === "requestFormId"
                            ? `internal/RequestForm/${data?.requestFormNote?.requestForm?.id}`
                            : item.id === "senderWhenUser"
                            ? data?.requestFormNote?.sender?.id
                            : item.id === "createdBy" ||
                              item.id === "responsible"
                            ? data?.requestFormNote?.[item.id]?.id
                            : data?.requestFormNote[item.id]
                        }
                        additionalData={
                          data?.requestFormNote?.case?.[item.additionalData]
                        }
                      >
                        {item.dataType?.includes("cases") ||
                        item.dataType?.includes("documents")
                          ? data?.requestFormNote[item.id]
                            ? item.dataType === "model:documents"
                              ? `[${
                                  data?.requestFormNote?.requestForm &&
                                  (data?.requestFormNote?.requestForm
                                    ?.documentType === "requestForm"
                                    ? data?.requestFormNote?.requestForm
                                        ?.documentNumber
                                    : data?.requestFormNote?.requestForm
                                        ?.internalNumber)
                                }] ${
                                  data?.requestFormNote?.requestForm
                                    ?.orderSubject || ""
                                }`
                              : item.dataType === "model:cases"
                              ? `[${data?.requestFormNote?.case?.number}] ${data?.requestFormNote?.case?.name}`
                              : data?.requestFormNote[item.id].id
                            : null
                          : item.id === "documentType"
                          ? "Notatka do wniosku o wydatkowanie"
                          : item.id === "senderWhenUser" &&
                            data?.requestFormNote?.sender
                          ? `${data?.requestFormNote?.sender?.firstName} ${data?.requestFormNote?.sender?.lastName}`
                          : item.id === "createdBy" || item.id === "responsible"
                          ? `${data?.requestFormNote?.[item.id]?.firstName} ${
                              data?.requestFormNote?.[item.id]?.lastName
                            }`
                          : item.show.accessor
                          ? data?.requestFormNote[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : data?.requestFormNote[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          {hasFlowSteps && (
            <>
              <Cell span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  Historia obiegu dokumentu{" "}
                  {!flowStepsHistory?.length && "(brak)"}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
              <Cell span={12}>
                {isFetching ? (
                  <Skeleton rows={0} height="200px" width="100%" animation />
                ) : flowStepsHistory?.length ? (
                  <Block paddingTop="scale200" paddingBottom="scale600">
                    <HistoryTable
                      documentStatuses={data?.requestFormNote?.statuses}
                      flowStepsHistory={flowStepsHistory}
                    />
                  </Block>
                ) : (
                  <></>
                )}
              </Cell>
            </>
          )}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty powiązane {!assignedDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!assignedDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={documentId}
                    data={assignedDocuments}
                    assignmentId={data.requestFormNote.currentAssignment?.id}
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>
          <Cell span={12}>
            <>
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="scale200"
                marginTop="scale600"
              >
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {hasHiddenFiles
                    ? !!files?.length && (
                        <Lock
                          size={18}
                          color={theme.colors.negative}
                          className={css({ marginRight: "10px" })}
                        />
                      )
                    : !!files?.length && (
                        <LockOpen
                          size={18}
                          color={theme.colors.positive}
                          className={css({ marginRight: "10px" })}
                        />
                      )}
                  <LabelMedium>Załączniki {` (${files?.length})`}</LabelMedium>

                  {hasHiddenFiles && (
                    <Button
                      size={SIZE.mini}
                      kind={KIND.secondary}
                      $style={{ marginLeft: "10px" }}
                      onClick={() =>
                        setOpenedCommonModal("confirm-files-reveal")
                      }
                      isLoading={revealFilesLoading}
                    >
                      Pokaż pliki
                    </Button>
                  )}
                </Block>
                {!hasHiddenFiles && !!files?.length && (
                  <FormattedValue dataType="pre" $style={{ fontSize: "13px" }}>
                    Dostęp jest aktywny do końca trwania sesji
                  </FormattedValue>
                )}
              </Block>

              {!!files?.length && (
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              )}
            </>
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : hasHiddenFiles ? (
              <Skeleton
                rows={0}
                height="20px"
                width="100%"
                overrides={{
                  Root: {
                    style: {
                      backgroundColor: "#faebeb",
                    },
                  },
                }}
              />
            ) : files?.length ? (
              <Block paddingTop="scale200" paddingBottom="scale600">
                <AttachmentsTable data={files} />
              </Block>
            ) : (
              <></>
            )}
          </Cell>

          {targetFlowAction && (
            <FlowActionModal
              documentId={documentId}
              action={targetFlowAction}
              hasAnySignaturesBefore={hasAnySignaturesBefore}
              isOpen={isFlowActionModalOpen}
              label={`${
                data?.requestFormNote?.documentType &&
                findValue(data?.requestFormNote?.documentType)
              } ${data?.requestFormNote?.internalNumber}`}
              lastStatusWithSignature={lastStatusWithSignature}
              reloadPage={() => {
                refetch();
                availableActionsRefetch();
              }}
              setIsOpen={setIsFlowActionModalOpen}
            />
          )}

          <DocumentCommonModalsRenderer
            openedCommonModal={openedCommonModal}
            close={() => setOpenedCommonModal(undefined)}
            documentId={documentId}
            document={data?.requestFormNote}
            refetch={refetch}
            onDocumentMutationSubmit={onDocumentMutationSubmit}
          />
        </Grid>
      </Content>
    </article>
  );
}
