import { gql } from "@apollo/client";

export const CONTRACT_PREPARATION_REQUEST_EDIT = gql`
  mutation(
    $contractPreparationRequestUpdateInput: ContractPreparationRequestUpdateInput!
    $addressInput: AddressInput
    $files: [Upload!]
  ) {
    contractPreparationRequestUpdate(
      contractPreparationRequestUpdateInput: $contractPreparationRequestUpdateInput
      addressInput: $addressInput
      contractPreparationRequestFiles: $files
    ) {
      id
    }
  }
`;

export const CONTRACT_PREPARATION_REQUEST_DELETE = gql`
  mutation(
    $contractPreparationRequestDeleteInput: ContractPreparationRequestDeleteInput!
  ) {
    contractPreparationRequestDelete(
      contractPreparationRequestDeleteInput: $contractPreparationRequestDeleteInput
    ) {
      id
    }
  }
`;

export const CONTRACT_PREPARATION_REQUEST_SHOW = gql`
  query($id: Int!) {
    contractPreparationRequest(id: $id) {
      isDispatchable
      isDispatchCancelable
      isReleasable
      isShareable
      shippedAt
      currentAssignment {
        id
        primaryAssignableId
        isAssessmentRequested
        assignerComment
        createdAt
        type
        status
        assigner {
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
      }
      serviceType
      id
      type
      contractType
      beginAt
      endAt
      grossValueInPLN
      contractorName
      contractorPesel
      grossHourlyRate
      grossFlatRate
      otherGrossRates
      isOrganizationCoveringTravelExpenses
      isOrganizationCoveringMaterialCosts
      isRequiringDataProcessingAuthorization
      isGrossValueIncreasedBySocialInsurance
      internalNumber
      createdAt
      updatedAt
      description
      caseId
      isEditable
      state
      responsible {
        id
        firstName
        lastName
      }
      isResponsibleSettable
      isEditCompleted
      createdBy {
        id
        firstName
        lastName
      }
      currency {
        id
        code
      }
      case {
        id
        name
        number
        isConductedElectronically
      }
      documentType
      organizationalUnit {
        id
        name
        symbol
      }
      documentKind
      documentLinks {
        id
        linkerId
        linker {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            totalValue
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        linkedId
        linked {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            requestFormItems {
              orderValue
            }
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        type
        createdAt
      }
      documentRoot {
        ... on RequestForm {
          internalNumber
          legalAct
          id
          documentType
          type
          documentNumber
          documentKind
        }
        ... on BillIssuanceRequest {
          internalNumber
          id
          documentType
          type
          documentKind
        }
        ... on ContractPreparationRequest {
          internalNumber
          id
          documentType
          type
          documentKind
        }
        ... on ContractAgreement {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on FinancialAccountingDocument {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on Payment {
          id
          internalNumber
          documentType
          type
          documentKind
        }
        ... on Document {
          id
          internalNumber
          documentType
          type
          documentKind
        }
      }
      children {
        id
        documentNumber
        internalNumber
        documentKind
        documentType
        type
        documentDate
        createdAt
        postStampAt
      }
      currentStatus {
        documentFlowStepId
        documentFlowStep {
          id
          name
          isInitial
          isProcessedByDocumentFlowStarter
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
          }
          group {
            id
            name
          }
          users {
            firstName
            lastName
          }
        }
        createdAt
      }
      statuses {
        id
        comment
        signatureFilePath
        signatureFileMimetype
        signatureDownloadUrl
        user {
          id
          firstName
          lastName
          position
        }
        userPosition
        userOrganizationalUnits
        documentFlowRecipients
        substituter {
          id
          firstName
          lastName
          position
        }
        documentFlowStep {
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
            symbol
          }
          users {
            id
            firstName
            lastName
          }
          positions {
            id
            name
          }
          id
          name
          isProcessedByDocumentFlowStarter
        }
        documentFlowAction {
          label
          style
          resultingDocumentState
          isEndingDocumentFlow
        }
        createdAt
      }
      documentFlowId
      isDocumentUsedInFlow
      documentFlow {
        id
        name
        steps {
          id
          name
        }
      }
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
      activityLogs {
        id
        activity
        description
        user {
          firstName
          lastName
          id
        }
        substituter {
          id
          firstName
          lastName
        }
        assignmentUsers
        properties
        createdAt
        userOrganizationalUnits
      }
    }
  }
`;

export const CONTRACT_PREPARATION_REQUEST_FILES_SHOW = gql`
  query($id: Int!) {
    contractPreparationRequest(id: $id) {
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
    }
  }
`;

export const CONTRACT_PREPARATION_REQUEST_CREATE = gql`
  mutation CreateContractPreparationRequest(
    $contractPreparationRequestCreateInput: ContractPreparationRequestCreateInput!
    $addressInput: AddressInput
    $files: [Upload!]
  ) {
    contractPreparationRequestCreate(
      contractPreparationRequestCreateInput: $contractPreparationRequestCreateInput
      addressInput: $addressInput
      contractPreparationRequestFiles: $files
    ) {
      id
    }
  }
`;
