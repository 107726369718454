/**
 * App Constants
 */

export const AUTH_TOKEN = "auth-token";
export const SUBSTITUTION_TOKEN = "substitution-token";
export const NOT_FOUND_ERROR = "NOT_FOUND_ERROR";
export const APP_NAME = "FRSE EZD";
export const HIDDEN_ADDRESS = "HiddenAddress";
export const HIDDEN_FILE = "HiddenFile";

export const SUPPORT_ADMIN_NAME = "Jan Kowalski";
export const SUPPORT_MAIL = "magazyn@frse.org.pl";

export const DEFAULT_CURRENCY = [{ id: 1, label: "PLN" }];
export const DEFAULT_CURRENCY_RATE_SOURCE = [{ id: 1, label: "NBP" }];
export const CURRENCY_RATES = {
  EUR: { NBP: 4.5, ordinance: 4.4 },
  GBP: { NBP: 5.26, ordinance: 5 },
};

export const EUR = "EUR";

export enum FLOW_DIRECTION {
  Forward = "Forward",
  Backward = "Backward",
}

export const PAGE_SIZE = 25;

export const PARCEL_SIZES = [
  {
    id: "B4",
    width: 250,
    length: 353,
    height: 1,
  },
  {
    id: "B5",
    width: 176,
    length: 250,
    height: 1,
  },
  {
    id: "C4",
    width: 229,
    length: 324,
    height: 10,
  },
  {
    id: "C5",
    width: 162,
    length: 229,
    height: 1,
  },
  {
    id: "C6",
    width: 114,
    length: 162,
    height: 5,
  },
  {
    id: "DL",
    width: 110,
    length: 220,
    height: 5,
  },
  {
    id: "Paczka duża",
    width: 700,
    length: 700,
    height: 700,
  },
];

export const ENADAWCA_PARCELS = [
  {
    id: "ListZwykly",
    label: "List zwykły",
    urlToCreate: "list-zwykly",
  },
  {
    id: "PaczkaPocztowa",
    label: "Paczka pocztowa",
    urlToCreate: "paczka-pocztowa",
  },
  {
    id: "PaczkaZagraniczna",
    label: "Paczka zagraniczna",
    urlToCreate: "paczka-zagraniczna",
  },
  {
    id: "PrzesylkaListowaZadeklarowanaWartosc",
    label: "Przesyłka listowa zadeklarowana wartość",
    urlToCreate: "przesylka-listowa-zadeklarowana-wartosc",
  },
  {
    id: "PrzesylkaNierejestrowanaKrajowa",
    label: "Przesyłka nierejestrowana krajowa",
    urlToCreate: "przesylka-nierejestrowana-krajowa",
  },
  {
    id: "PrzesylkaPoleconaKrajowa",
    label: "Przesyłka polecona krajowa",
    urlToCreate: "przesylka-polecona-krajowa",
  },
  {
    id: "PrzesylkaPoleconaZagraniczna",
    label: "Przesyłka polecona zagraniczna",
    urlToCreate: "przesylka-polecona-zagraniczna",
  },
  {
    id: "PrzesylkaZagraniczna",
    label: "Przesyłka zagraniczna",
    urlToCreate: "przesylka-zagraniczna",
  },
  {
    id: "ListWartosciowyKrajowy",
    label: "List wartościowy krajowy",
    urlToCreate: "list-wartosciowy-krajowy",
  },
];

export const ENADAWCA_PARCEL_CATEGORIES = [
  { id: "EKONOMICZNA", label: "Ekonomiczna" },
  { id: "PRIORYTETOWA", label: "Priorytetowa" },
];

export const ENADAWCA_PARCEL_OVERALL_DIMENSIONS = [
  { id: "GABARYT_A", label: "Gabaryt A" },
  { id: "GABARYT_B", label: "Gabaryt B" },
];

export const ENADAWCA_FORMAT = [
  { id: "S", label: "S" },
  { id: "M", label: "M" },
  { id: "L", label: "L" },
];

export const DEKLARACJA_CELNA_RODZAJ = [
  { id: "CN22", label: "CN22" },
  { id: "CN23", label: "CN23" },
];

export const ZAWARTOSC_PRZESYLKI = [
  { id: "SPRZEDAZ_TOWARU", label: "Sprzedaż towaru" },
  { id: "ZWROT_TOWARU", label: "Zwrot towaru" },
  { id: "PREZENT", label: "Prezent" },
  { id: "PROBKA_HANDLOWA", label: "Próbka handlowa" },
  { id: "DOKUMENT", label: "Dokument" },
  { id: "INNE", label: "Inne" },
];

export const DOKUMENTY_TOWARZYSZACE_RODZAJ = [
  { id: "LICENCJA", label: "Licencja" },
  { id: "CERTYFIKAT", label: "Certyfikat" },
  { id: "FAKTURA", label: "Faktura" },
];

export const ENADAWCA_ADDRESS_TYPES = [
  { id: "ADRESAT_INDYWIDUALNY", label: "Adresat indywidualny" },
  { id: "ADRESAT_UMOWNY", label: "Adresat umowny" },
  { id: "ODDZIAL", label: "Oddział" },
];

export const COMPANY_ADDRESS = {
  nazwa: "Fundacja Rozwoju Systemu Edukacji",
  ulica: "Aleje Jerozolimskie",
  numerDomu: "142A",
  miejscowosc: "Warszawa",
  kodPocztowy: "02-305",
  kraj: "PL",
  telefon: "+48 22 463 11 01",
  email: "kontakt@frse.org.pl",
};

export enum REACT_APP_SYSTEM_VERSIONS {
  TEST = "TEST",
  PRODUCTION = "PRODUCTION",
  TRAINING = "TRAINING",
}

export const EXAMPLE_FUNDING_SOURCES = [
  "FRSE.EUROPASS.KE",
  "FRSE.EP.AKCJE.2021",
  "FRSE.EP.MEN",
  "FRSE.ECVET.MNISW",
  "FRSE.EURODESK.MEN",
  "FRSE.EURYDICE.MEN",
  "FRSE.TCA.MNISW",
  "FRSE.YW.MEN",
  "FRSE.EP.MNISW",
  "FRSE.ECVET.MEN",
  "FRSE.EURODESK.MNISW",
  "FRSE.SALTO.MEN",
  "FRSE.EPALE.MEN",
  "FRSE.EURYDICE.MNISW",
  "FRSE.TCA.MEN",
  "FRSE.SZA.MR",
  "FRSE.ETW.KE",
  "FRSE.ECVET.KE",
  "FRSE.D.1.A",
  "FRSE.FPL.MEN",
  "FRSE.SALTO.KE",
  "FRSE.EFS.MR",
  "FRSE.FS",
  "FRSE.PURWM.MEN",
  "FRSE.FWD.EOG",
  "FRSE.FWD.NMF",
  "FRSE.EKS.AKCJE",
  "FRSE.EKS.KE",
  "FRSE.ETW.MEN",
  "FRSE.KADRY",
  "FRSE.TCA.KE",
  "FRSE.TEC.KE",
  "FRSE.ZFSS",
  "FRSE.EUROPASS.MNISW",
  "FRSE.EUROPASS.MEN",
  "FRSE.EKS.AKCJE.2021",
  "FRSE.YW.KE",
  "FRSE.EKS.SALTO.KE",
  "FRSE.EKS.MEN",
  "FRSE.EKS.MNISW",
  "FRSE.EKS.SALTO.MEN",
  "FRSE.EURYDICE.KE",
  "FRSE.EPALE.KE",
  "FRSE.EDUKACJA.EOG",
  "FRSE.EDUKACJA.PL",
  "FRSE.EP.KE",
  "FRSE.EURODESK.KE",
];

export const MOVIES = [
  {
    id: "movie1",
    title: "Podstawy",
    href:
      "https://frse2-my.sharepoint.com/:v:/g/personal/fszkoleniowe_frse2_onmicrosoft_com/EXQdxfXgy2JFppOKXsTcLxEBAnjDkRTOCrUHdJeKVxBanw?e=36w7F4",
    topics: [
      "Dostęp do systemu",
      "Logowanie przy pomocy AD",
      "Moje konto",
      "Powiadomienia",
    ],
  },
  {
    id: "movie2",
    title: "Interfejs",
    href:
      "https://frse2-my.sharepoint.com/:v:/g/personal/fszkoleniowe_frse2_onmicrosoft_com/EfgwRIAP7z9Kr3QT5Z0aom4ByubPy2i2bZDHrzV_LEIsLw?e=b3cOhg",
    topics: [
      "Menu główne",
      "Listy elementów",
      "Dodawanie nowych elementów",
      "Wyszukiwanie, filtrowanie, sortowanie",
      "Edycja elementów",
      "Akcje",
    ],
  },
  {
    id: "movie3",
    title: "Sprawy, JRWA",
    href:
      "https://frse2-my.sharepoint.com/:v:/g/personal/fszkoleniowe_frse2_onmicrosoft_com/EYPm-YOwCqZMu2Z2UivDapMBcmVEYYYCqTu9F7sazAx8ZQ?e=HEUeHo",
    topics: [
      "Przeglądanie klasyfikacji JRWA",
      "Przeglądanie spraw",
      "Tworzenie spraw",
      "Zarządzanie sprawami",
    ],
  },
  {
    id: "movie4",
    title: "Dokumenty przychodzące",
    href:
      "https://frse2-my.sharepoint.com/:v:/g/personal/fszkoleniowe_frse2_onmicrosoft_com/EVgzjqZtMUNHvq4rU7GunxcB37ddN2P8i8v9mKgdo22wbA?e=Mt4a80",
    topics: [
      "Przeglądanie dokumentów",
      "Rejestracja dokumentów przychodzących",
      "Podłączanie dokumentu pod sprawę",
      "Przekazanie dokumentu do innej osoby lub jednostki",
    ],
  },
  {
    id: "movie5",
    title: "Dokumenty wychodzące",
    href:
      "https://frse2-my.sharepoint.com/:v:/g/personal/fszkoleniowe_frse2_onmicrosoft_com/EZXAd8DWV4pLm_DAnjuqTwkBIT7JolIHwaeLAaLR9ZFgBw?e=hxtEC9",
    topics: [
      "Rejestracja dokumentów wychodzących",
      "Przegląd dokumentów wychodzących",
    ],
  },
  {
    id: "movie6",
    title: "Wniosek i obieg",
    href:
      "https://frse2-my.sharepoint.com/:v:/g/personal/fszkoleniowe_frse2_onmicrosoft_com/EQUuatv1suBAih7GauyJeQABXDi0YEgmST0RJT7XurA2jA?e=y5j34s",
    topics: [
      "Tworzenie wniosku o wydatkowanie środków publicznych",
      "Obieg wniosku: przekazanie, akceptacja, odrzucenie, podpis elektroniczny",
    ],
  },
  {
    id: "movie7",
    title: "Faktura",
    href:
      "https://frse2-my.sharepoint.com/:v:/g/personal/fszkoleniowe_frse2_onmicrosoft_com/Ecs4lsnws65Nh3bBPYPauQkBRfGV3JB4FE8xjOXoxprE6Q?e=SbzwJd",
    topics: [
      "Rejestracja dokumentu przychodzącego o typie faktura",
      "Rejestracja właściwej faktury",
      "Konwersja dokumentu przychodzącego o typie faktura na właściwą fakturę",
      "Przejście do tworzenia polecenia płatności",
    ],
  },
  {
    id: "movie8",
    title: "Polecenia płatności",
    href:
      "https://frse2-my.sharepoint.com/:v:/g/personal/fszkoleniowe_frse2_onmicrosoft_com/EY3WODIg3VZPiR94hcOO9t8BLZiyXwivlw545ZJamdE5qg?e=IjdfRc",
    topics: [
      "Tworzenie polecenia płatności",
      "Zarządzanie kontami wewnętrznymi",
      "Zarządzanie podzielnikami",
    ],
  },
];

export enum DOCUMENT_TYPENAMES {
  Document = "Document",
  ContractAgreement = "ContractAgreement",
  RequestForm = "RequestForm",
  FinancialAccountingDocument = "FinancialAccountingDocument",
  Payment = "Payment",
  ContractPreparationRequest = "ContractPreparationRequest",
  BillIssuanceRequest = "BillIssuanceRequest",
  RequestFormNote = "RequestFormNote",
}

export enum DOCUMENT_TYPES {
  ContractAgreement = "contract",
  RequestForm = "requestForm",
  Invoice = "invoice",
  Payment = "payment",
  ContractPreparationRequest = "contractPreparationRequest",
  BillIssuanceRequest = "billIssuanceRequest",
  RequestFormNote = "requestFormNote",
  Policy = "policy",
  GlassesCostReimbursement = "glassesCostReimbursement",
  BusinessTripSettlement = "businessTripSettlement",
  CivilLawContractInvoice = "civilLawContractInvoice",
  AccountingNote = "accountingNote",
  IncurredCostsReimbursement = "incurredCostsReimbursement",
  BailiffSeizure = "bailiffSeizure",
  InternalTransfer = "internalTransfer",
}

export enum DOCUMENT_KINDS {
  Internal = "Internal",
  Incoming = "Incoming",
  Outgoing = "Outgoing",
}

export enum SENDER_TYPENAMES {
  Address = "Address",
  HiddenAddress = "HiddenAddress",
  User = "User",
}

export enum DOCUMENT_STATES {
  New = "New",
  InProgress = "InProgress",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Canceled = "Canceled",
  Closed = "Closed",
  Voided = "Voided",
}

export enum DOCUMENT_PICKUP_STATES {
  New = "New",
  PickupInProgress = "PickupInProgress",
  PickupConfirmed = "PickupConfirmed",
  ReturnInProgress = "ReturnInProgress",
  ReturnConfirmed = "ReturnConfirmed",
}

export const FINANCIAL_ACCOUNTING_DOCUMENTS_DOCUMENT_TYPES = [
  DOCUMENT_TYPES.Invoice,
  DOCUMENT_TYPES.AccountingNote,
  DOCUMENT_TYPES.BailiffSeizure,
  DOCUMENT_TYPES.BusinessTripSettlement,
  DOCUMENT_TYPES.GlassesCostReimbursement,
  DOCUMENT_TYPES.IncurredCostsReimbursement,
  DOCUMENT_TYPES.InternalTransfer,
  DOCUMENT_TYPES.CivilLawContractInvoice,
  DOCUMENT_TYPES.Policy,
];

export const DOCUMENT_PICKUP_STATE_OPTIONS = [
  { id: "New", label: "Nowe" },
  { id: "PickupInProgress", label: "Wysłano kod" },
  { id: "PickupConfirmed", label: "Potwierdzone kodem" },
  { id: "ReturnInProgress", label: "W trakcie zwrotu" },
  { id: "ReturnConfirmed", label: "Potwierdzone kodem" },
];

export enum LOCAL_STORAGE_VARIABLES {
  LastRegisteredIncomingDocument = "lastRegisteredIncomingDocument",
  LastRegisteredOutgoingDocument = "lastRegisteredOutgoingDocument",
  LastRegisteredInternalDocument = "lastRegisteredInternalDocument",
  ExpandedRowsByGroupIds = "expandedRowsByGroupIds",
}

export enum INVOICE_TYPES {
  Original = "Original",
  Correction = "Correction",
  Duplicate = "Duplicate",
  Proforma = "Proforma",
  Advance = "Advance",
}

export enum FLOW_ACTION_WARNINGS {
  NoPaymentAssignedToInvoice = "noPaymentAssignedToInvoice",
}

export const TEST_LOGIN = "boyle";
export const TEST_PASSWORD = "password";
