import { FIELDS, FieldsGroup } from "../../../fields.d";
import { RequestFormItem } from "../documents";

export type RequestFormInputs = {
  documentNumber: string;
  caseName: { id: string | number; label?: string }[];
  organizationalUnit: { id: number; label?: string }[];
  orderSubject: string;
  orderType: { id: string | number; label?: string }[];
  files?: FileList;
  requestFormItems: RequestFormItem[];
  endAt: Date | null;
  beginAt: Date | null;
  preferedExecutionBeginAt: Date | null;
  preferedExecutionEndAt: Date | null;
  legalDocumentsVerification: boolean;
  legalActSelect?: { id: number; label?: string }[];
  legalAct?: string;
  isContractAgreementRequired?: boolean;
  controllingNote?: string;
  nrRejestr?: string;
};

export const REQUEST_FORM_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "documentType",
        label: "Typ dokumentu",
        type: FIELDS.DictionaryValuesSelect,
        span: 4,
        show: { visible: true },
        edit: { visible: false },
        create: { visible: false },
        placeholder: "Wybierz",
      },
      {
        id: "documentNumber",
        label: "Numer wniosku",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        edit: { visible: false },
        create: { visible: false },
      },
      {
        id: "nrRejestr",
        label: "Numer wniosku z rejestr.frse.org.pl",
        caption: "Wypełnia ZCW",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "state",
        label: "Stan dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "document-state",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "createdAt",
        label: "Data rejestracji w systemie",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        edit: { visible: false },
        create: { visible: false },
      },
      {
        id: "createdBy",
        label: "Autor",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "responsible",
        label: "Odpowiedzialny",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "orderType",
        label: "Rodzaj zamówienia",
        type: FIELDS.OrderTypeSelect,
        span: 3,
        show: { visible: true },
        edit: { visible: true, required: true },
        create: { visible: true, required: true },
        placeholder: "Wybierz",
      },
      {
        id: "orderSubject",
        label: "Przedmiot zamówienia",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: true },
      },
    ],
  },
  {
    id: "case",
    label: "Znak sprawy",
    fields: [
      // {
      //   id: "caseName",
      //   label: "",
      //   type: FIELDS.CasesSelect,
      //   span: 12,
      //   show: { visible: false },
      //   edit: { visible: true },
      //   create: { visible: true },
      //   placeholder: "Wybierz",
      //   caption:
      //     "W tym polu należy wybrać odpowiedni znak sprawy z jaką zostanie powiązany dokument. Nie jest to obowiązkowe na etapie rejestracji dokumentu w recepcji",
      // },
      {
        id: "case",
        label: "",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        additionalData: "isConductedElectronically",
        dataType: "model:cases",
      },
    ],
  },
  {
    id: "sender",
    label: "Nadawca",
    fields: [
      {
        id: "organizationalUnit",
        label: "Jednostka organizacyjna",
        type: FIELDS.UsersOrganizationalUnitsSelect,
        span: 12,
        dataType: "model:organizational-units",
        show: {
          visible: true,
          accessor: ["organizationalUnit", "name"],
        },
        edit: { visible: true },
        create: { visible: true, required: true },
        placeholder: "Wybierz",
      },
    ],
  },
  {
    id: "dates",
    label: "Daty",
    fields: [
      {
        id: "preferedExecutionBeginAt",
        label: "Preferowany początek realizacji",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "preferedExecutionEndAt",
        label: "Preferowany koniec realizacji",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "beginAt",
        label: "Początek realizacji",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "endAt",
        label: "Koniec realizacji",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "legalInformation",
    label: "Podstawa prawna",
    fields: [
      {
        id: "legalActSelect",
        label: "",
        type: FIELDS.DictionaryValuesSelect,
        placeholder: "Wybierz",
        span: 6,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "legalAct",
        label: "",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        edit: { visible: false },
        create: { visible: false },
      },
    ],
  },
  {
    id: "controllingNote",
    label: "Uwagi ZCW",
    fields: [
      {
        id: "controllingNote",
        label: "",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        edit: { visible: true },
        create: { visible: false },
      },
    ],
  },
  {
    id: "table",
    label: "Pozycje wniosku *",
    fields: [
      {
        id: "requestFormItems",
        label: "",
        type: FIELDS.RequestFormTable,
        span: 12,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
      },
    ],
  },
  {
    id: "otherInformations",
    label: "Pozostałe informacje",
    fields: [
      {
        id: "legalDocumentsVerification",
        label: "Dodatkowa kontrola prawna dokumentów",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 6,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "isContractAgreementRequired",
        label: "Obowiązek zawarcia umowy",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 6,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
      },
    ],
  },
];
