export enum FILTERS {
  Text = "text",
  Date = "date",
  OrganizationalUnit = "organizational-unit",
  Boolean = "boolean",
  DictionaryValues = "dictionary-values",
  DocumentTypes = "document-types",
  DocumentKinds = "document-kinds",
  CaseStatus = "case-status",
  HasCaseAssigned = "has-case-assigned",
  DocumentStates = "document-states",
  User = "user",
  Case = "case",
  DocumentPickupState = "document-pickup-state",
  DocumentPickupState = "document-pickup-state",
  DocumentValueInPLN = "document-value-in-pln",
  DocumentValueInCurrency = "document-value-in-currency",
  Role = "role",
  CaseConduct = "case-conduct",
  FullName = "full-name",
  AssignmentStatus = "assignment-status",
  AssignmentType = "assignment-type",
  PrimaryAssignable = "primary-assignable",
  Activity = "activity",
  ActivityLogsSubjectType = "activity-logs-subject-type",
  Document = "document",
  Substitutee = "substitutee",
  Substituter = "substituter",
  Amount = "amount",
  Number = "number",
  LoginAd = "loginAd",
}

export type FilterState = {
  not?: boolean;
  [key: string]: any;
  dictionarySystemName?: string;
};

export type BasicFilter = {
  id: string;
  label: string;
  type: FILTERS;
  dictionarySystemName?: string;
  organizationalUnitId?: string;
};

export type SingleFilter = {
  uuid: string;
  filter: BasicFilter;
  state: FilterState;
};

export type FiltersState = SingleFilter[];
