import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  ArrowBack,
  ArrowBigRightLines,
  ArrowForwardUp,
  Check,
  ChevronDown,
  ClipboardList,
  DentalOff,
  Edit,
  EditOff,
  FileArrowLeft,
  FileCheck,
  FileX,
  FileZip,
  FolderPlus,
  Folders,
  HandGrab,
  HandOff,
  Link,
  Notes,
  Pencil,
  Send,
  Settings,
  Share,
  TableExport,
  UserPlus,
  X,
} from "tabler-icons-react";

import AssignedDocumentsTable from "../../../../components/assigned-documents-table";
import Cell from "../../../../components/cell";
import Content from "../../../../components/content";
import DocumentBarcode from "../../../../components/document-barcode";
import {
  DocumentCommonModalsRenderer,
  DocumentModalName,
} from "../../../../components/document-common-modals-renderer";
import EditBasedOnNoteModal from "../../../../components/edit-based-on-note-modal";
import FlowActionModal from "../../../../components/flow-action-modal";
import FlowProgress from "../../../../components/flow-progress";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import HistoryTable from "../../../../components/history-table";
import RequestFormItemsTable from "../../../../components/request-form-items-table";
import RequestFormValueTable from "../../../../components/request-form-value-table";
import RequestFormVoidModal from "../../../../components/request-form-void-modal";
import {
  DOCUMENT_STATES,
  DOCUMENT_TYPES,
  NOT_FOUND_ERROR,
} from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useDictionaries } from "../../../../contexts/dictionaries-context";
import { useLoading } from "../../../../contexts/loading-context";
import { useSubstitution } from "../../../../contexts/substitution-context";
import { FILTERS } from "../../../../filters.d";
import { checkPermission } from "../../../../utils/check-permission";
import {
  handleDataForProgressBar,
  handleHistoryFlowSteps,
} from "../../../../utils/document-flow";
import {
  checkIsDispatchable,
  checkIsShareable,
} from "../../../../utils/documents/assignment-actions";
import { renderMutationSuccessLabel } from "../../../../utils/documents/mutations-snackbar";
import { generateUUID } from "../../../../utils/misc";
import { PERMISSIONS } from "../../../../utils/permissions";
import Error404 from "../../../ErrorPages/404";
import {
  DocumentFlowAction,
  HistoryFlowStep,
} from "../../../Flow/document-flow";
import {
  AssignmentLocationState,
  AssignmentType,
} from "../../../Folders/folders.d";
import {
  DocumentLink,
  DocumentMutationAction,
  DocumentStatus,
} from "../../documents.d";
import {
  ALL_DOCUMENTS_FOLDER,
  DOCUMENT_FLOW_AVAILABLE_ACTIONS,
  DOCUMENTS_ACCEPT,
  DOCUMENTS_CANCEL_DISPATCH,
  DOCUMENTS_COMPLETE_EDITION,
  DOCUMENTS_RELEASE_RESPONSIBLE,
  DOCUMENTS_SET_RESPONSIBLE,
  DOCUMENTS_START_EDITION,
  DOCUMENTS_TRACK_OPEN,
  DOCUMENTS_ZIP_EXPORT,
} from "../../documents.gql";
import { REQUEST_FORM_FIELDS } from "../request-form.form";
import {
  REQUEST_FORM_EXPORT_TO_XLSX,
  REQUEST_FORM_SHOW,
} from "../request-form.gql";

export default function DocumentsShowRequestForm(): React.ReactElement {
  const [isFlowActionModalOpen, setIsFlowActionModalOpen] = useState(false);
  const [targetFlowAction, setTargetFlowAction] = useState<
    DocumentFlowAction | undefined
  >(undefined);
  const [isVoidDocumentDialogOpen, setIsVoidDocumentDialogOpen] = useState(
    false
  );
  const [
    isEditBasedOnNoteDialogOpen,
    setIsEditBasedOnNoteDialogOpen,
  ] = useState(false);
  const [flowStepsHistory, setFlowStepsHistory] = useState<HistoryFlowStep[]>(
    []
  );
  const [statusesForProgressBar, setStatusesForProgressBar] = useState<
    DocumentStatus[]
  >([]);

  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [
    openedCommonModal,
    setOpenedCommonModal,
  ] = useState<DocumentModalName>();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);
  const { refetch: userRefetch, user } = useAuth();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();
  const { findValue } = useDictionaries();
  const { substitution, isSubstitutionActive } = useSubstitution();

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const assignmentContext = {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  };

  const { refetch, data, error } = useQuery(REQUEST_FORM_SHOW, {
    variables: { id: documentId },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const documentLinks = data?.requestForm?.documentLinks;

  const parentDocuments = documentLinks?.filter(
    ({ type, linkerId }: DocumentLink) =>
      type === "Parent" && documentId === linkerId
  );

  const childrenDocuments = documentLinks?.filter(
    ({ type, linkedId }: DocumentLink) =>
      type === "Parent" && documentId === linkedId
  );

  const assignedDocuments = documentLinks?.filter(
    ({ type }: DocumentLink) => type === null
  );

  const { data: notesData } = useQuery(ALL_DOCUMENTS_FOLDER, {
    variables: {
      filter: {
        and: [
          {
            documentType: {
              eq: DOCUMENT_TYPES.RequestFormNote,
            },
          },
          {
            state: {
              eq: DOCUMENT_STATES.Accepted,
            },
          },
          {
            requestFormId: {
              eq: documentId,
            },
          },
        ],
      },
    },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const hasAnyAcceptedNote = notesData?.nodes;

  const {
    data: availableActionsData,
    refetch: availableActionsRefetch,
  } = useQuery(DOCUMENT_FLOW_AVAILABLE_ACTIONS, {
    variables: {
      documentId,
    },
  });

  const availableActionsButtons = availableActionsData?.documentFlowAvailableActions?.map(
    (action: DocumentFlowAction) => {
      return {
        label: action.label,
        kind: KIND.primary,
        startEnhancer:
          action.style === "positive" ? (
            <Check size={18} />
          ) : action.style === "negative" ? (
            <X size={18} />
          ) : (
            <Send size={18} />
          ),
        isPositive: action.style === "positive",
        isNegative: action.style === "negative",
        onClick: () => {
          setTargetFlowAction(action);
          setIsFlowActionModalOpen(true);
        },
      };
    }
  );

  const hasFlowSteps = !!data?.requestForm?.currentStatus;
  const hasAnySignaturesBefore = statusesForProgressBar?.some(
    (status: DocumentStatus) => !!status.signatureDownloadUrl
  );
  const lastStatusWithSignature = statusesForProgressBar
    ?.filter((status: DocumentStatus) => !!status.signatureDownloadUrl)
    .pop();

  const [acceptDocument] = useMutation(DOCUMENTS_ACCEPT, assignmentContext);

  const [cancelDispatch] = useMutation(DOCUMENTS_CANCEL_DISPATCH);

  const [completeEdition] = useMutation(
    DOCUMENTS_COMPLETE_EDITION,
    assignmentContext
  );

  const [exportZipFile] = useMutation(DOCUMENTS_ZIP_EXPORT, assignmentContext);

  const [exportXlsxFile] = useMutation(
    REQUEST_FORM_EXPORT_TO_XLSX,
    assignmentContext
  );

  const [releaseResponsible] = useMutation(
    DOCUMENTS_RELEASE_RESPONSIBLE,
    assignmentContext
  );

  const [setResponsible] = useMutation(
    DOCUMENTS_SET_RESPONSIBLE,
    assignmentContext
  );

  const [startEdition] = useMutation(
    DOCUMENTS_START_EDITION,
    assignmentContext
  );

  const [trackOpen] = useMutation(DOCUMENTS_TRACK_OPEN, assignmentContext);

  const documentMutationVariables = {
    variables: {
      documentId,
    },
  };

  const apiUrl = process.env.REACT_APP_GRAPHQL_API_URL?.replace("/graphql", "");

  const onDocumentMutationSubmit = async (
    action: DocumentMutationAction
  ): Promise<void> => {
    setIsLoading(true);

    try {
      if (action === DocumentMutationAction.Accept)
        await acceptDocument(documentMutationVariables);

      if (action === DocumentMutationAction.CancelDispatch)
        await cancelDispatch(documentMutationVariables);

      if (action === DocumentMutationAction.CompleteEdition)
        await completeEdition(documentMutationVariables);

      if (action === DocumentMutationAction.ExportXlsx) {
        const response = await exportXlsxFile(documentMutationVariables);

        window.open(
          `${apiUrl}${response?.data?.requestFormExportToXlsx?.downloadUrl}`,
          "_self"
        );
      }

      if (action === DocumentMutationAction.ExportZip) {
        const response = await exportZipFile(documentMutationVariables);

        window.open(
          `${apiUrl}/documents/download-zip-export/${id}/${response?.data?.documentExportZip}`,
          "_self"
        );
      }

      if (action === DocumentMutationAction.ReleaseResponsible)
        await releaseResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.SetResponsible)
        await setResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.StartEdition)
        await startEdition(documentMutationVariables);

      enqueueSnackbar({
        message: renderMutationSuccessLabel(action),
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
      setOpenedCommonModal(undefined);
    }
  };

  useEffect(() => {
    if (
      error?.graphQLErrors &&
      (error as ApolloError).graphQLErrors?.map(
        ({ extensions }) => extensions?.code
      )[0] !== NOT_FOUND_ERROR
    )
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.requestForm) {
      refetch();
    }
    if (availableActionsData?.documentFlowAvailableActions)
      availableActionsRefetch();
    setIsFetching(true);
    setStatusesForProgressBar([]);
    trackOpen({ variables: { id: documentId } }).then(() => userRefetch());
  }, []);

  useEffect(() => {
    if (availableActionsData) availableActionsRefetch();
  }, [data?.requestForm?.currentStatus]);

  useEffect(() => {
    if (data?.requestForm?.currentAssignment) {
      setAssignment(data?.requestForm?.currentAssignment);
      setIsAssignmentActive(true);
    }
    setFlowStepsHistory(handleHistoryFlowSteps(data?.requestForm?.statuses));
    setStatusesForProgressBar(
      handleDataForProgressBar(data?.requestForm?.statuses)
    );
    if (data?.requestForm?.internalNumber) setIsFetching(false);
  }, [data]);

  if (
    !checkPermission(PERMISSIONS.requestForm.read) &&
    !checkPermission(PERMISSIONS.document.readAll)
  ) {
    return <Redirect to="/" />;
  } else if (
    error?.graphQLErrors &&
    (error as ApolloError).graphQLErrors?.map(
      ({ extensions }) => extensions?.code
    )[0] === NOT_FOUND_ERROR
  ) {
    return <Error404 />;
  }

  return (
    <article>
      <Header
        title={data?.requestForm?.internalNumber}
        labels={[
          <FormattedValue
            key="label_1"
            dataType={"model:cases"}
            data={data?.requestForm?.case?.id}
            additionalData={data?.requestForm?.case?.isConductedElectronically}
            showBlankWhenEmpty
          >
            {`${data?.requestForm?.case?.number}`}
          </FormattedValue>,
          "Dokument",
        ]}
        goBackOption
        hasActiveAssignmentBar
        hasCancellationBar={
          data?.requestForm?.state === DOCUMENT_STATES.Canceled
        }
        buttons={[
          {
            label: "Przejmij",
            startEnhancer: <HandGrab size={18} />,
            kind: KIND.secondary,
            isPositive: true,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.SetResponsible),
            permission: data?.requestForm?.isResponsibleSettable,
          },
          {
            label: "Zwolnij",
            startEnhancer: <HandOff size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(
                DocumentMutationAction.ReleaseResponsible
              ),
            permission:
              data?.requestForm?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) &&
              data?.requestForm?.isReleasable &&
              !data?.requestForm?.isEditCompleted,
          },
          {
            label: "Zakończ edycję",
            startEnhancer: <EditOff size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.CompleteEdition),
            permission:
              data?.requestForm?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) &&
              !data?.requestForm?.isEditCompleted &&
              !data?.requestForm?.isDocumentUsedInFlow,
          },
          {
            label: "Anuluj zakończenie edycji",
            startEnhancer: <Edit size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.StartEdition),
            permission:
              data?.requestForm?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) && data?.requestForm?.isEditCompleted,
          },
          {
            label: "Załóż sprawę",
            kind: KIND.primary,
            startEnhancer: <Link size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            permission:
              !data?.requestForm?.case?.id && data?.requestForm?.isEditable,
            subactions: [
              {
                label: "Załóż nową sprawę",
                icon: FolderPlus,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("case-create"),
                permission:
                  checkPermission(PERMISSIONS.case.create) &&
                  data?.requestForm?.isEditable &&
                  data?.requestForm?.state !== DOCUMENT_STATES.Canceled,
              },
              {
                label: "Przypisz do istniejącej",
                icon: Folders,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("assign-to-case"),
                permission:
                  checkPermission(PERMISSIONS.document.update) &&
                  data?.requestForm?.isEditable &&
                  data?.requestForm?.state !== DOCUMENT_STATES.Canceled,
              },
            ],
          },
          {
            label: "Udostępnij",
            kind: KIND.primary,
            startEnhancer: <Share size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Udostępnij",
                icon: UserPlus,
                color: theme.colors.primary,
                permission: checkIsShareable(data?.requestForm),
                onClick: () =>
                  history.push(
                    location.pathname + `/share`,
                    state || data?.requestForm?.currentAssignment?.id
                      ? { assignmentId: data.requestForm.currentAssignment?.id }
                      : undefined
                  ),
              },
              {
                label: "Zarządzaj dostępem",
                icon: Settings,
                color: theme.colors.contentPrimary,
                onClick: () =>
                  history.push(
                    `/folders/shared?filters=${window.btoa(
                      unescape(
                        encodeURIComponent(
                          JSON.stringify([
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "primaryAssignableId",
                                label: "Obiekt",
                                type: FILTERS.PrimaryAssignable,
                              },
                              state: {
                                type: "Document",
                                value: data?.requestForm?.id,
                                internalNumber:
                                  data?.requestForm?.internalNumber,
                              },
                            },
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "status",
                                label: "Status",
                                type: FILTERS.AssignmentStatus,
                              },
                              state: {
                                value: [
                                  { id: "New", label: "Nowe" },
                                  { id: "InProgress", label: "W realizacji" },
                                ],
                              },
                            },
                          ])
                        )
                      )
                    )}`
                  ),
              },
            ],
            permission: !data?.requestForm?.documentFlowId,
          },
          {
            label: "Przekaż",
            startEnhancer: <ArrowForwardUp size={18} />,
            kind: KIND.primary,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Przekaż dokument",
                icon: ArrowForwardUp,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("document-dispatch"),
              },
              {
                label: "Zwróć dokument",
                icon: ArrowBack,
                color: theme.colors.negative,
                onClick: () =>
                  setOpenedCommonModal("document-dispatch-with-dispatcher"),

                permission:
                  !!data?.requestForm?.currentAssignment?.assigner?.id &&
                  data?.requestForm?.currentAssignment?.type !==
                    AssignmentType.Initial,
              },
            ],
            permission: checkIsDispatchable(data?.requestForm),
          },
          {
            label: "Anuluj przekazanie dokumentu",
            startEnhancer: <ArrowBack size={18} />,
            kind: KIND.secondary,
            isNegative: true,
            onClick: () => setOpenedCommonModal("cancel-assignment"),
            permission: data?.requestForm?.isDispatchCancelable,
          },
          {
            label: "Akceptuj",
            startEnhancer: <Check size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              !data?.requestForm?.documentFlowId &&
              data?.requestForm?.isDispatchable &&
              !!data?.requestForm?.currentAssignment?.isAssessmentRequested &&
              data?.requestForm?.state !== DOCUMENT_STATES.Canceled,
            onClick: () =>
              setOpenedCommonModal("document-assignment-assessment-accept"),
          },
          {
            label: "Odrzuć",
            startEnhancer: <X size={18} />,
            kind: KIND.primary,
            isNegative: true,
            permission:
              !data?.requestForm?.documentFlowId &&
              data?.requestForm?.isDispatchable &&
              !!data?.requestForm?.currentAssignment?.isAssessmentRequested &&
              data?.requestForm?.state !== DOCUMENT_STATES.Canceled,
            onClick: () =>
              setOpenedCommonModal("document-assignment-assessment-reject"),
          },
          {
            label: "Rozpocznij obieg",
            startEnhancer: <ArrowBigRightLines size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              data?.requestForm?.isEditable &&
              !data?.requestForm?.documentFlowId &&
              !!data?.requestForm?.documentFlow &&
              !data?.requestForm?.isEditCompleted,
            onClick: () => setOpenedCommonModal("document-flow-start"),
          },
          ...(availableActionsButtons || []),
        ]}
        actions={[
          {
            label: "Edytuj dokument",
            icon: Pencil,
            permission:
              checkPermission(PERMISSIONS.requestForm.update) &&
              data?.requestForm?.isEditable,
            onClick: () =>
              history.push(
                `/documents/internal/RequestForm/${id}/edit`,
                state || data?.requestForm?.currentAssignment?.id
                  ? { assignmentId: data.requestForm.currentAssignment?.id }
                  : undefined
              ),
          },
          {
            label: "Akceptuj dokument",
            icon: FileCheck,
            color: theme.colors.positive,
            permission:
              data?.requestForm?.isEditable &&
              ((data?.requestForm?.isDispatchable &&
                !data?.requestForm?.documentFlowId &&
                !data?.requestForm?.currentAssignment?.isAssessmentRequested) ||
                !data?.requestForm?.isDispatchable),
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.Accept),
          },
          {
            label: "Powiąż z dokumentem",
            icon: Link,
            color: theme.colors.primary,
            onClick: () => setOpenedCommonModal("document-link"),
            permission:
              checkPermission(PERMISSIONS.requestForm.update) &&
              data?.requestForm?.isEditable &&
              data?.requestForm?.state !== DOCUMENT_STATES.Canceled,
          },
          {
            label: "Pobierz plik XLSX",
            icon: TableExport,
            color: theme.colors.primary,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.ExportXlsx),
            permission: checkPermission(PERMISSIONS.requestForm.read),
          },
          {
            label: "Edytuj na podstawie notatki",
            icon: Notes,
            color: theme.colors.primary,
            onClick: () => setIsEditBasedOnNoteDialogOpen(true),
            permission:
              checkPermission(
                PERMISSIONS.controlling.updateRequestFormBasedOnNote
              ) &&
              !data?.requestForm?.isEditable &&
              hasAnyAcceptedNote,
          },
          {
            label: "Anuluj dokument",
            icon: FileX,
            color: theme.colors.negative,
            onClick: () => setOpenedCommonModal("document-cancel"),
            permission:
              checkPermission(PERMISSIONS.document.cancel) &&
              data?.requestForm?.isEditable &&
              (data?.requestForm?.state === DOCUMENT_STATES.New ||
                data?.requestForm?.currentStatus?.documentFlowStep?.isInitial),
          },
          {
            label: "Cofnij anulowanie wniosku",
            icon: FileArrowLeft,
            color: theme.colors.positive,
            onClick: () => setOpenedCommonModal("document-uncancel"),
            permission:
              checkPermission(PERMISSIONS.document.cancel) &&
              data?.requestForm?.state === DOCUMENT_STATES.Canceled,
          },
          {
            label: "Zamknij wniosek",
            icon: FileX,
            color: theme.colors.negative,
            onClick: () => setOpenedCommonModal("document-close"),
            permission:
              checkPermission(PERMISSIONS.requestForm.close) &&
              data?.requestForm?.state === DOCUMENT_STATES.Accepted,
          },
          {
            label: "Unieważnij wniosek",
            icon: DentalOff,
            color: theme.colors.negative,
            onClick: () => setIsVoidDocumentDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.requestForm.void) &&
              data?.requestForm?.state === DOCUMENT_STATES.Accepted,
          },
          {
            label: "Pokaż logi dokumentu",
            icon: ClipboardList,
            color: theme.colors.black,
            onClick: () => setOpenedCommonModal("activity-logs"),
            permission: checkPermission(PERMISSIONS.document.read),
          },
          {
            label: "Pobierz plik ZIP",
            icon: FileZip,
            color: theme.colors.black,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.ExportZip),
          },
        ]}
      >
        <DocumentBarcode
          documentId={documentId}
          internalNumber={data?.requestForm?.internalNumber}
        />
      </Header>

      <Content>
        <Grid>
          <Cell span={12}>
            <>
              {hasFlowSteps &&
                availableActionsData &&
                (isFetching ? (
                  <Skeleton rows={0} height="150px" width="100%" animation />
                ) : (
                  <FlowProgress
                    currentStep={
                      data?.requestForm?.currentStatus?.documentFlowStep
                    }
                    originalOrganizationalUnitName={
                      data?.requestForm?.organizationalUnit?.name
                    }
                    statuses={statusesForProgressBar}
                    actions={availableActionsData.documentFlowAvailableActions}
                  />
                ))}
            </>
          </Cell>
          {REQUEST_FORM_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  {group.label}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell
                  span={item.span || 6}
                  skip={item.skip || 0}
                  key={group.id + `-field` + index}
                >
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "case"
                            ? data?.requestForm?.caseId
                            : item.id === "organizationalUnit"
                            ? data?.requestForm?.organizationalUnit?.id
                            : item.id === "createdBy" ||
                              item.id === "responsible"
                            ? data?.requestForm?.[item.id]?.id
                            : item?.show.accessor
                            ? data?.requestForm?.[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]
                            : data?.requestForm[item.id]
                        }
                        additionalData={
                          data?.requestForm?.case?.[item.additionalData]
                        }
                      >
                        {item?.show.accessor
                          ? data?.requestForm?.[item.show.accessor[0]]?.[
                              item.show.accessor[1]
                            ]
                          : item.dataType === "model:cases" &&
                            data?.requestForm?.case
                          ? `[${data?.requestForm?.case?.number}] ${data?.requestForm?.case?.name}`
                          : item.id === "documentType"
                          ? findValue(data?.requestForm?.documentType)
                          : item.id === "legalAct"
                          ? findValue(data?.requestForm?.legalAct)
                          : item.id === "createdBy" || item.id === "responsible"
                          ? `${data?.requestForm?.[item.id]?.firstName} ${
                              data?.requestForm?.[item.id]?.lastName
                            }`
                          : data?.requestForm[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Pozycje wniosku
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <RequestFormItemsTable
                    requestFormItems={data?.requestForm?.requestFormItems}
                    reloadPage={refetch}
                  />
                </Block>
              )
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Wartość wniosku
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="100px" width="100%" animation />
            ) : (
              data && (
                <RequestFormValueTable
                  requestFormItems={data?.requestForm?.requestFormItems}
                  reloadPage={refetch}
                />
              )
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Pozostałe informacje
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={6}>
            <FormControl
              label="Dodatkowa kontrola prawna dokumentów"
              disabled={true}
            >
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType="boolean">
                  {data?.requestForm?.legalDocumentsVerification}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={6}>
            <FormControl label="Obowiązek zawarcia umowy" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType="boolean">
                  {data?.requestForm?.isContractAgreementRequired}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty nadrzędne {!parentDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!parentDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={documentId}
                    data={parentDocuments}
                    assignmentId={data.requestForm.currentAssignment?.id}
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty podrzędne {!childrenDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!childrenDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={data?.requestForm?.id}
                    data={childrenDocuments}
                    assignmentId={data.requestForm.currentAssignment?.id}
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>
          <Cell span={12}>
            <>
              <LabelMedium marginBottom="scale200" marginTop="scale600">
                Dokumenty powiązane {!assignedDocuments?.length && "(0)"}
              </LabelMedium>

              {(!!assignedDocuments?.length || hasFlowSteps) && (
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              )}
            </>
          </Cell>

          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!assignedDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={documentId}
                    data={assignedDocuments}
                    assignmentId={data.requestForm.currentAssignment?.id}
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>
          {hasFlowSteps && (
            <>
              <Cell span={12}>
                <>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    Historia obiegu dokumentu{" "}
                    {!flowStepsHistory?.length && "(brak)"}
                  </LabelMedium>

                  {!!flowStepsHistory?.length && (
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  )}
                </>
              </Cell>
              <Cell span={12}>
                {isFetching ? (
                  <Skeleton rows={0} height="200px" width="100%" animation />
                ) : flowStepsHistory?.length ? (
                  <Block paddingTop="scale200" paddingBottom="scale600">
                    <HistoryTable
                      documentStatuses={data?.requestForm?.statuses}
                      flowStepsHistory={flowStepsHistory}
                    />
                  </Block>
                ) : (
                  <></>
                )}
              </Cell>
            </>
          )}
          {targetFlowAction && (
            <FlowActionModal
              documentId={documentId}
              action={targetFlowAction}
              hasAnySignaturesBefore={hasAnySignaturesBefore}
              isOpen={isFlowActionModalOpen}
              label={`Wniosek o wydatkowanie środków publicznych (wniosek wszczęcia) ${data?.requestForm?.documentNumber}`}
              lastStatusWithSignature={lastStatusWithSignature}
              reloadPage={() => {
                refetch();
                availableActionsRefetch();
              }}
              setIsOpen={setIsFlowActionModalOpen}
            />
          )}

          <RequestFormVoidModal
            isOpen={isVoidDocumentDialogOpen}
            setIsOpen={setIsVoidDocumentDialogOpen}
            requestFormId={id}
            requestFormNumber={data?.requestForm?.documentNumber}
            reloadPage={refetch}
          />

          <EditBasedOnNoteModal
            isOpen={isEditBasedOnNoteDialogOpen}
            setIsOpen={setIsEditBasedOnNoteDialogOpen}
            requestFormId={id}
          />

          <DocumentCommonModalsRenderer
            openedCommonModal={openedCommonModal}
            close={() => setOpenedCommonModal(undefined)}
            documentId={documentId}
            document={data?.requestForm}
            refetch={refetch}
            onDocumentMutationSubmit={onDocumentMutationSubmit}
          />
        </Grid>
      </Content>
    </article>
  );
}
