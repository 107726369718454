import { FIELDS, FieldsGroup } from "../../fields.d";
import { OrganizationalUnit } from "../OrganizationalUnits/organizational-units";
import { Document } from "./documents";
import { AssignmentAssessmentResult } from "./documents.d";

export type DocumentInputs = {
  documentNumber: string;
  description: string;
  title?: string;
  programName?: string;
  documentType: { id: string; label?: string }[];
  documentDate: Date | null;
  parentIds?: Document[];
  registeredAt: string;
  postStampAt: Date | null;
  inflowDate: Date | null;
  senderWhenAddress?: { id: number; label?: string; typename?: string }[];
  senderWhenUser?: { id: number; label?: string }[];
  addressName?: string;
  addressAddress?: string;
  addressStreetName?: string;
  addressBuildingNumber?: string;
  addressApartmentNumber?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressPhoneNumber?: string;
  addressMobileNumber?: string;
  addressEmail?: string;
  addressNip?: string;
  addressOverwrite?: boolean;
  addressIsSensitive?: boolean;
  recipientWhenUser?: { id: number; label?: string }[];
  recipientWhenAddress?: { id: number; label?: string; typename?: string }[];
  caseName: { id: number; label?: string }[];
  organizationalUnit: { id: number; label?: string }[];
  addressCountryCode: { id: number | string; label?: string }[];
  handDelivered: boolean;
  files?: FileList;
  documentId?: number;
  width?: number;
  height?: number;
  length?: number;
  weight?: number;
  sendingLists?: { id: number; label: string }[];
  isCountryService?: boolean;
  countryCode?: string;
  countryCodeSelect?: { id: string; label?: string }[];
  isFullyDigitallyReproduced?: string;
  isElectronical?: string;
  name?: string;
  additionalCode?: string;
  raksDiaryNumber?: string;
};

type ShareAndDispatchVariant = "users" | "organizationalUnit";

export type DispatchFormInputs = {
  dispatchees?: { id: number; label?: string }[];
  organizationalUnits?: { id: number; label?: string }[];
  comment?: string;
  isAssessmentRequested?: boolean;
  variant?: ShareAndDispatchVariant;
};

export type AssessmentFormInputs = {
  documentId?: number;
  assignmentId?: number;
  assessmentResult?: AssignmentAssessmentResult;
  comment?: string;
};

export type LinkFormInputs = {
  parentId: Document[];
};

export type ShareFormInputs = {
  users?: { id: number; label: string }[];
  organizationalUnits?: { id: number; label: string }[];
  accessLevel?: "Read" | "Update";
  comment?: string;
  variant?: ShareAndDispatchVariant;
};

export type FlowStartFormInputs = {
  organizationalUnitId: OrganizationalUnit[];
};

export const DOCUMENTS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "documentType",
        label: "Typ dokumentu",
        type: FIELDS.DocumentTypesSelect,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: false },
        placeholder: "Wybierz",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "documentKind",
        label: "Rodzaj dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "document-kind",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "name",
        label: "Tytuł dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: false },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "documentNumber",
        label: "Numer dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: false },
        edit: { visible: true },
        caption: "Np. numer faktury, umowy, itp.",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "state",
        label: "Stan dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "document-state",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "createdAt",
        label: "Data rejestracji w systemie",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "createdBy",
        label: "Autor",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "responsible",
        label: "Odpowiedzialny",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "documentDate",
        label: "Data wystawienia dokumentu",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
        caption: "Np. data wystawienia faktury",
      },
      {
        id: "documentDate",
        label: "Data wystawienia dokumentu",
        type: FIELDS.Input,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },

      {
        id: "inflowDate",
        label: "Data wpływu",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
      },
      {
        id: "postStampAt",
        label: "Data stempla pocztowego",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
      },
      {
        id: "handDelivered",
        label: "Dostarczony osobiście",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
      },
      {
        id: "handDelivered",
        label: "Dostarczony osobiście",
        type: FIELDS.Input,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Incoming"],
      },
      {
        id: "documentPickupId",
        label: "Status wydania z recepcji",
        type: FIELDS.Checkbox,
        span: 3,
        dataType: "document-pickup-state",
        show: { visible: true, accessor: ["documentPickup", "state"] },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Incoming"],
      },
      {
        id: "isElectronical",
        label: "Typ przesyłki",
        type: FIELDS.RadioCheckbox,
        dataType: "electronical-boolean",
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        typeName: ["Incoming"],
      },
      {
        id: "isFullyDigitallyReproduced",
        label: "W pełni odwzorowany cyfrowo",
        type: FIELDS.RadioCheckbox,
        dataType: "boolean",
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        typeName: ["Incoming"],
      },
      {
        id: "isElectronical",
        label: "Typ przesyłki",
        type: FIELDS.RadioCheckbox,
        dataType: "electronical-boolean",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Incoming"],
      },
      {
        id: "isFullyDigitallyReproduced",
        label: "W pełni odwzorowany cyfrowo",
        type: FIELDS.RadioCheckbox,
        dataType: "boolean",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Incoming"],
      },
    ],
  },
  {
    id: "sender",
    label: "Nadawca",
    fields: [
      {
        id: "senderWhenAddress",
        label: "Wybór z bazy adresowej",
        type: FIELDS.AddressesSelect,
        span: 8,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        placeholder: "Wpisz lub wybierz",
        typeName: ["Incoming"],
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        mask: "9999999999",
        placeholder: "9999999999",
        dataType: "nip",
        typeName: ["Incoming"],
        additionalData: "sensitive",
      },
      {
        id: "addressName",
        label: "Nazwa nadawcy",
        type: FIELDS.Input,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
      },
      {
        id: "addressName",
        label: "Nazwa nadawcy",
        type: FIELDS.Input,
        span: 9,
        show: { accessor: ["sender", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Incoming"],
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "nip"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "nip",
        typeName: ["Incoming"],
      },
      {
        id: "addressIsSensitive",
        label: "Dane osobowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        info: "notElectronicalRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        dataType: "countryCode",
        typeName: ["Incoming"],
        additionalData: "sensitive",
      },
      {
        id: "addressStreetName",
        label: "Ulica",
        type: FIELDS.Input,
        span: 3,
        info: "notElectronicalRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
        additionalData: "sensitive",
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Incoming"],
      },
      {
        id: "addressBuildingNumber",
        label: "Numer budynku",
        type: FIELDS.Input,
        span: 2,
        info: "notElectronicalRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
        additionalData: "sensitive",
      },
      {
        id: "addressApartmentNumber",
        label: "Numer lokalu",
        type: FIELDS.Input,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
        additionalData: "sensitive",
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 2,
        info: "notElectronicalRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
        additionalData: "sensitive",
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "postalCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Incoming"],
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        info: "notElectronicalRequired",
        show: { accessor: ["sender", "city"], visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
        additionalData: "sensitive",
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        info: "notElectronicalRequired",
        show: { accessor: ["sender", "countryCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "countryCode",
        typeName: ["Incoming"],
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "phoneNumber"], visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
        mask: "99 999-99-99",
        placeholder: "99 999-99-99",
        additionalData: "sensitive",
      },
      {
        id: "addressMobileNumber",
        label: "Numer telefonu kom.",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "mobileNumber"], visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
        mask: "+99 999-999-999",
        placeholder: "+99 999-999-999",
        additionalData: "sensitive",
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        dataType: "email",
        typeName: ["Incoming"],
        info: "electronicalRequired",
        additionalData: "sensitive",
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 6,
        show: { accessor: ["sender", "email"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "email",
        typeName: ["Incoming"],
      },
      {
        id: "addressOverwrite",
        label: "Nadpisz dane adresowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming"],
      },
    ],
  },
  {
    id: "case",
    label: "Znak sprawy",
    fields: [
      {
        id: "case",
        label: "",
        type: FIELDS.Input,
        span: 12,
        show: { accessor: ["case", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:cases",
        additionalData: "isConductedElectronically",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
    ],
  },
  {
    id: "description",
    label: "Pozostałe",
    fields: [
      {
        id: "title",
        label: "Temat",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "programName",
        label: "Program",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "additionalCode",
        label: "Kod E+/ACC/QL/Inne",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "raksDiaryNumber",
        label: "Numer dziennika RAKS",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
    ],
  },
  {
    id: "parent",
    label: "Dokumenty nadrzędne",
    fields: [
      {
        id: "parentIds",
        label: "",
        type: FIELDS.DocumentsSelect,
        dataType: "model:documents",
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
    ],
  },
  {
    id: "files",
    label: "Załączniki",
    fields: [
      {
        id: "filesList",
        label: "",
        type: FIELDS.FilesList,
        span: 12,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
        caption:
          "Kliknięcie ikony kosza spowoduje usunięcia załącznika po zapisaniu dokumentu",
      },
      {
        id: "files",
        label: "Dodaj nowy",
        type: FIELDS.FilesPicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        caption: "Maksymalny rozmiar pliku to 128 MB.",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
    ],
  },
];

export const DOCUMENT_ADDRESS_FIELDS: FieldsGroup[] = [
  {
    id: "recipient",
    label: "Odbiorca",
    fields: [
      {
        id: "recipientWhenAddress",
        label: "Odbiorca z bazy adresowej",
        type: FIELDS.AddressesSelect,
        span: 8,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        placeholder: "Wybierz",
        typeName: ["Outgoing"],
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        mask: "9999999999",
        placeholder: "9999999999",
        typeName: ["Outgoing"],
      },
      {
        id: "addressName",
        label: "Nazwa odbiorcy",
        type: FIELDS.Input,
        span: 6,
        info: "possiblyRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
      },
      {
        id: "addressName",
        label: "Nazwa odbiorcy",
        type: FIELDS.Input,
        span: 9,
        show: { accessor: ["recipient", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Outgoing"],
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "nip"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "nip",
        typeName: ["Outgoing"],
      },
      {
        id: "addressIsSensitive",
        label: "Dane osobowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        info: "possiblyRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        dataType: "countryCode",
        typeName: ["Outgoing"],
      },
      {
        id: "addressStreetName",
        label: "Ulica",
        type: FIELDS.Input,
        span: 3,
        info: "possiblyRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
      },
      {
        id: "addressBuildingNumber",
        label: "Numer budynku",
        type: FIELDS.Input,
        span: 2,
        info: "possiblyRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
      },
      {
        id: "addressApartmentNumber",
        label: "Numer lokalu",
        type: FIELDS.Input,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Outgoing"],
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 2,
        info: "possiblyRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "postalCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Outgoing"],
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        info: "possiblyRequired",
        show: { accessor: ["recipient", "city"], visible: true },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        info: "possiblyRequired",
        show: { accessor: ["recipient", "countryCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "countryCode",
        typeName: ["Outgoing"],
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
        mask: "99 999-99-99",
        placeholder: "99 999-99-99",
      },
      {
        id: "addressMobileNumber",
        label: "Numer telefonu kom.",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
        mask: "+99 999-999-999",
        placeholder: "+99 999-999-999",
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "phoneNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Outgoing"],
      },
      {
        id: "addressMobileNumber",
        label: "Numer telefonu kom.",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "mobileNumber"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Outgoing"],
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["recipient", "email"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "email",
        typeName: ["Outgoing"],
      },
      {
        id: "addressOverwrite",
        label: "Nadpisz dane adresowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        typeName: ["Outgoing"],
      },
    ],
  },
];
