import { ActivityLog } from "../ActivityLogs/activity-logs";
import { Case } from "../Cases/cases";
import { Currency } from "../Currencies/currencies";
import { HistoryFlowStepDocumentFlowRecipients } from "../Flow/document-flow";
import { OrganizationalUnit } from "../OrganizationalUnits/organizational-units";
import { User } from "../Users/users";

export enum AssignmentAssessmentResult {
  Accept = "Accept",
  Reject = "Reject",
}

export type DocumentStatus = {
  id: number;
  documentId: number;
  documentFlowStepId: number;
  documentFlowStep: {
    id: number;
    name: string;
    isInitial: boolean;
    hiddenDocumentFields: string[];
    disabledDocumentFields: string[];
    isDeletable: boolean;
    organizationalUnit: OrganizationalUnit;
    organizationalUnitId: number;
    documentFlow: DocumentFlow;
    documentFlowId: number;
    users: User[];
    positions: Position[];
    isProcessedByDocumentFlowStarter: boolean;
    isLegalSectionEnabled: boolean;
  };
  documentFlowAction: DocumentFlowAction;
  comment: string;
  signatureFilePath: string;
  signatureFileMimetype: string;
  signatureDownloadUrl: string;
  createdAt: string;
  user: User;
  userPosition: string;
  substituter: User;
  userId: number;
  userOrganizationalUnits: JSON;
  documentFlowRecipients: HistoryFlowStepDocumentFlowRecipients;
};

export type Document = {
  __typename?: string;
  id: number;
  internalNumber?: string;
  documentKind?: string;
  documentNumber?: string;
  documentDate?: Date;
  documentType?: string;
  registeredAt?: string;
  createdAt?: string;
  createdBy?: User;
  createdById?: number;
  postStampAt?: string;
  sender?: AddressableUnion;
  recipient?: AddressableUnion;
  case?: Case;
  organizationalUnit?: OrganizationalUnit;
  organizationalUnitId?: number;
  currentStatus?: DocumentStatus;
  organizationalUnit?: OrganizationalUnit;
  isEditable?: boolean;
  isDispatchable?: boolean;
  isShareable?: boolean;
  type?: string;
  title?: string;
  description?: string;
  isEditable?: boolean;
  grossValue?: number;
  totalValue?: number;
  grossValueInPLN?: number;
  state?: string;
  name?: string;
  isElectronical?: boolean;
  additionalCode?: string;
  hasDocumentFlowEnded?: boolean;
  responsible?: User;
  responsibleId?: number;
  isResponsibleSettable?: boolean;
  isEditCompleted?: boolean;
  documentFlowId?: number;
  isDocumentUsedInFlow?: boolean;
  currentAssignment?: {
    id: number;
    parentId?: number;
    type: AssignmentType;
    assigner: User;
    assignerId: number;
    assignee: User;
    assigneeId: number;
    assignerComment?: string;
    assigneeComment?: string;
    substituter?: User;
    primaryAssignable: AssignableUnion;
    primaryAssignableId: number;
    status: AssignmentStatus;
    assessmentResult?: AssignmentAssessmentResult;
    createdAt: string;
    finishedAt?: string;
    isAssessmentRequested?: boolean;
  };
  activityLogs?: ActivityLog[];
};

export type DocumentLink = {
  id: number;
  type?: string;
  linkerId: number;
  linker: Document;
  linkedId: number;
  linked: Document;
};

export type BudgetItem = {
  id: number;
  budgetValue: number;
  budgetCategory: BudgetCategory;
  budgetCategoryId: number;
  budget: Budget;
  budgetId: number;
  organizationalUnit: OrganizationalUnit;
  organizationalUnitId: number;
  currentValue: number;
  isActive: boolean;
};

export type RequestFormItem = {
  id: number | string;
  requestedExpenditure: number;
  realExpenditure: number;
  orderValue: number;
  budgetItemId: number;
  budgetItem: BudgetItem;
  requestForm: Document;
  requestFormId: number;
  originalId?: number;
  financialAccountingDocumentItems?: FinancialAccountingDocumentItem[];
  organizationalUnit?: OrganizationalUnit;
  year: string;
  isProjectBudget: boolean;
  budgetSymbol: string;
  categoryPath: string;
  categoryName: string;
  amount: number;
  budgetName: string;
};

export type RequestFormValue = {
  grossValue: number;
  netValue: number;
  grossValueEur?: number;
  netValueEur?: number;
};

export type ExpensePosition = {
  id: number;
  bazaId: number;
  budgetId: number;
  bazaBudgetId: number;
  budgetCategoryId: number;
  bazaCategoryId: number;
  name: string;
  price: number;
  amount: number;
  sum: number;
  description: string;
};

export type Budget = {
  id: number;
  name: string;
  financingName: number;
  expensePositions: ExpensePosition[];
  bazaId: number;
};

export type BudgetCategory = {
  id: number;
  name: string;
  uniquePath?: string;
  path?: string;
};

type CurrencyExchangeRate = {
  id: number;
  effectiveOn: Date;
  value: number;
  currency: Currency;
  currencyId: number;
};

export type FinancialAccountingDocument = {
  paymentAt: Date;
  netValue: number;
  grossValue: number;
  bankAccount: BankAccount;
  bankAccountId: number;
  financialAccountingDocumentItems: FinancialAccountingDocumentItem[];
  invoiceType?: InvoiceType;
  currency: Currency;
  amountInCurrency?: number;
  currencyExchangeRate: CurrencyExchangeRate;
} & Document;

export type FinancialAccountingDocumentItem = {
  id: number;
  description: string;
  name: string;
  totalAmount: number;
  totalAmountWithTax: number;
  financialAccountingDocumentId: number;
  originalId?: number;
  financialAccountingDocument?: {
    internalNumber: string;
    currency: {
      code?: string;
    };
    currencyExchangeRate: CurrencyExchangeRate;
  };
  requestFormItemId?: number;
  requestFormItem?: {
    id: number;
    budgetSymbol: string;
    categoryPath: string;
    categoryName: string;
    budgetName: string;
    requestForm: { id: number };
    year: string;
  };
  tableId?: string;
};

export type RequestForm = {
  orderSubject: string;
  orderType: string;
  estimatedValueBy: string;
  estimatedValueAt: Date;
  netEstimatedValue: number;
  grossEstimatedValue: number;
  totalValue: number;
  legalAct: string;
  legalDocumentsVerification: boolean;
  beginAt: Date;
  endAt: Date;
  preferedExecutionBeginAt: Date;
  preferedExecutionEndAt: Date;
  requestingUserId: number;
  requestingUser: User;
  signedAt: Date;
  requestFormItems: RequestFormItem[];
  isContractAgreementRequired: boolean;
  nrRejestr: string;
} & Document;

export type BillIssuanceRequest = {
  remarks: string;
  contractorAccountNumber: string;
  grossValueInPLN: number;
  workedHoursCount: number;
} & Document;

export type ContractPreparationRequest = {
  serviceType: string;
  beginAt: Date;
  endAt: Date;
  grossValueInPLN: number;
  contractorAddress: AddressableUnion;
  contractorAddressId: number;
  paymentForms: JSON;
  isOrganizationCoveringTravelExpenses: boolean;
  isOrganizationCoveringMaterialCosts: boolean;
  isRequiringDataProcessingAuthorization: boolean;
  isGrossValueIncreasedBySocialInsurance: boolean;
} & Document;

export enum DocumentMutationAction {
  Accept = "accept",
  CancelDispatch = "cancelDispatch",
  CompleteEdition = "completeEdition",
  DeletePayment = "deletePayment",
  ExportXlsx = "exportXlsx",
  ExportZip = "exportZip",
  ReleaseResponsible = "releaseResponsible",
  RevealAddress = "revealAddress",
  RevealFiles = "revealFiles",
  SetResponsible = "setResponsible",
  StartEdition = "startEdition",
  TrackOpen = "trackOpen",
}
