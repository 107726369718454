import { gql } from "@apollo/client";

export const PAYMENTS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: PaymentFilter
    $sorting: [PaymentSort!]
  ) {
    payments(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        internalNumber
        financialAccountingDocument {
          id
          internalNumber
          documentType
          documentNumber
          documentKind
          currency {
            id
            code
          }
        }
        requestForm {
          id
          internalNumber
          documentType
        }
        organizationalUnit {
          id
          name
          symbol
        }
        amount
        createdBy {
          id
          firstName
          lastName
        }
        createdAt
        payoutAcceptedById

        currentStatus {
          documentFlowAction {
            resultingDocumentState
          }
          documentFlowStepId
          documentFlowStep {
            id
            name
            documentFlow {
              steps {
                id
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;

export const PAYMENTS_SHOW = gql`
  query($id: Int!) {
    payment(id: $id) {
      id
      internalNumber
      paymentType
      issuedOn
      paymentDeadline
      isEditable
      nrRejestr
      description
      state
      additionalDescription
      currentAssignment {
        id
        isAssessmentRequested
        assignerComment
        createdAt
        type
        status
        assigner {
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
      }
      documentRoot {
        ... on RequestForm {
          internalNumber
          legalAct
          id
        }
        ... on ContractPreparationRequest {
          id
          internalNumber
        }
        ... on ContractAgreement {
          id
          internalNumber
        }
        ... on FinancialAccountingDocument {
          id
          internalNumber
          description
          title
          programName
          currency {
            id
            code
          }
        }
        ... on Payment {
          id
          internalNumber
        }
        ... on Document {
          id
          internalNumber
        }
      }
      case {
        id
        number
        name
        isConductedElectronically
      }
      currentStatus {
        documentFlowStepId
        documentFlowStep {
          id
          name
          isProcessedByDocumentFlowStarter
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
          }
          users {
            firstName
            lastName
          }
        }
        createdAt
      }
      documentFlow {
        id
        name
        steps {
          id
          name
        }
      }
      statuses {
        id
        comment
        signatureFilePath
        signatureFileMimetype
        signatureDownloadUrl
        user {
          id
          firstName
          lastName
          position
        }
        userPosition
        userOrganizationalUnits
        documentFlowRecipients
        substituter {
          id
          firstName
          lastName
          position
        }
        documentFlowStep {
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
            symbol
          }
          users {
            id
            firstName
            lastName
          }
          positions {
            id
            name
          }
          id
          name
          isProcessedByDocumentFlowStarter
        }
        documentFlowAction {
          label
          style
          resultingDocumentState
          isEndingDocumentFlow
        }
        createdAt
      }
      documentFlowId
      documentFlow {
        id
        name
        steps {
          id
          name
        }
      }
      financialAccountingDocument {
        id
        internalNumber
        documentType
        organizationalUnit {
          id
          name
        }
        documentDate
        documentKind
        grossValue
        paymentAt
        invoiceType
        description
        title
        programName
        currency {
          id
          code
        }
        bankAccount {
          id
          payeeName
          currency
          accountNumber
          bankName
          accountType
          bankCode
        }
        sender {
          __typename
          ... on Address {
            id
            address
            streetName
            buildingNumber
            apartmentNumber
            mobileNumber
            name
            city
            postalCode
            nip
            phoneNumber
            name
            countryCode
            email
            isSensitive
            bankAccounts {
              id
              payeeName
              currency
              accountNumber
              bankName
              accountType
              bankCode
              addressId
            }
          }
          ... on HiddenAddress {
            id
            name
          }
        }
      }
      requestFormNumber
      requestForm {
        id
        internalNumber
        documentType
      }
      organizationalUnit {
        id
        name
        symbol
      }
      amount
      createdBy {
        id
        firstName
        lastName
      }
      substantivelyAcceptedBy {
        id
        firstName
        lastName
      }
      controllingAcceptedBy {
        id
        firstName
        lastName
      }
      accountingAcceptedBy {
        id
        firstName
        lastName
      }
      payoutAcceptedBy {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
      paymentItems {
        internalAccount {
          name
          id
          fundingSource
          accountNumber
        }
        id
        fundingSource
        description
        amount
        bazaPositionId
        tableId
        budgetInfo {
          positionName
          categoryName
          categoryPath
          positionSum
          bazaId
          budgetName
        }
      }
    }
  }
`;

export const PAYMENTS_CREATE = gql`
  mutation(
    $paymentCreateInput: PaymentCreateInput!
    $paymentItemInputs: [PaymentItemInput!]!
  ) {
    paymentCreate(
      paymentCreateInput: $paymentCreateInput
      paymentItemInputs: $paymentItemInputs
    ) {
      id
    }
  }
`;

export const PAYMENTS_EDIT = gql`
  mutation(
    $paymentUpdateInput: PaymentUpdateInput!
    $paymentItemUpdateInputs: [PaymentItemUpdateInput!]!
  ) {
    paymentUpdate(
      paymentUpdateInput: $paymentUpdateInput
      paymentItemUpdateInputs: $paymentItemUpdateInputs
    ) {
      id
    }
  }
`;

export const PAYMENTS_DELETE = gql`
  mutation($documentId: Int!) {
    paymentDelete(paymentDeleteInput: { id: $documentId }) {
      id
    }
  }
`;

export const ALL_BUDGETS = gql`
  query AllBudgets {
    budgetInfoAll {
      positionName
      categoryName
      categoryPath
      positionSum
      bazaId
      budgetName
    }
  }
`;
