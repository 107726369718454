import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  AlertOctagon,
  DeviceFloppy,
  Download,
  FileOff,
  Note,
} from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import FilesList, { SingleFile } from "../../../../components/files-list";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import GusDownloadModal from "../../../../components/gus-download-modal";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledAddressesSelect,
  ControlledCasesSelect,
  ControlledContractStatusSelect,
  ControlledContractTypeSelect,
  ControlledDictionaryValuesSelect,
  ControlledDocumentsSelect,
  ControlledOrganizationalUnitsSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import Tooltip from "../../../../components/tooltip";
import { HIDDEN_ADDRESS, HIDDEN_FILE } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { Address, DefaultAddress } from "../../../Addresses/addresses";
import {
  ADDRESS_SUGGESTION,
  ADDRESS_SUGGESTIONS,
} from "../../../Addresses/addresses.gql";
import { DocumentLink } from "../../documents";
import { DOCUMENTS_SHOW, FILES_REVEAL } from "../../documents.gql";
import {
  CONTRACT_AGREEMENT_FIELDS,
  ContractAgreementInputs,
} from "../contract-agreement.form";
import { CONTRACT_AGREEMENT_CREATE_FROM_DOCUMENT } from "../contract-agreement.gql";

export default function DocumentsCreateContractAgreement(): React.ReactElement {
  const [
    isFilesRevealConfirmDialogOpen,
    setIsFilesRevealConfirmDialogOpen,
  ] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([]);
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(true);
  const [defaultAddress, setDefaultAddress] = useState<DefaultAddress>();
  const [addressFromGus, setAddressFromGus] = useState<Address>();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [isGusDownloadOpen, setIsGusDownloadOpen] = useState(false);
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, isLoading, setIsLoading } = useLoading();
  const { user } = useAuth();
  const location = useLocation();
  const locationState = location.state as {
    documentId: number;
    assignmentId: number;
  };

  const { assignment } = useAssignment();

  const time = new Date();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    unregister,
    watch,
  } = useForm<ContractAgreementInputs>({
    defaultValues: {
      documentNumber: "",
      budgetCategory: "",
      description: "",
      contractor: undefined,
      addressName: "",
      addressStreetName: "",
      addressBuildingNumber: "",
      addressApartmentNumber: "",
      addressMobileNumber: "",
      addressCity: "",
      addressPostalCode: "",
      addressPhoneNumber: "",
      addressNip: "",
      addressCountryCode: [{ id: "PL" }],
      addressEmail: "",
      addressOverwrite: false,
      addressIsSensitive: false,
      legalAct: undefined,
      legalDocumentsVerification: false,
      parentIds: undefined,
    },
  });

  const watchAddress = watch("contractor");
  const watchNip = watch("addressNip");
  const watchCountryCode = watch("addressCountryCode");
  const watchBeginAtDate = watch("beginAt");
  const watchEndAtDate = watch("endAt");

  const { refetch: fetchAddress } = useQuery(ADDRESS_SUGGESTIONS, {
    skip: true,
  });

  const { data, refetch } = useQuery(DOCUMENTS_SHOW, {
    variables: {
      id: locationState?.documentId || null,
    },
    ...((locationState?.assignmentId || assignment?.id) &&
      locationState?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: locationState?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const hasHiddenFiles =
    locationState &&
    !!data?.document?.files?.length &&
    data?.document?.files?.[0]?.__typename === HIDDEN_FILE;

  const [
    createContractFromDocument,
    { error: mutationError, loading },
  ] = useMutation(CONTRACT_AGREEMENT_CREATE_FROM_DOCUMENT, {
    ...((locationState?.assignmentId || assignment?.id) &&
      locationState?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: locationState?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    documentNumber,
    documentDate,
    description,
    caseName,
    parentIds,
    contractNumber,
    organizationalUnit,
    status,
    budgetCategory,
    beginAt,
    endAt,
    contractType,
    legalDocumentsVerification,
    collectionPeriodEndAt,
    legalAct,
    grossValueInPLN,
    contractor,
    addressName,
    addressNip,
    addressStreetName,
    addressBuildingNumber,
    addressApartmentNumber,
    addressMobileNumber,
    addressCity,
    addressCountryCode,
    addressPostalCode,
    addressPhoneNumber,
    addressEmail,
    addressOverwrite,
    addressIsSensitive,
    files,
  }: ContractAgreementInputs): Promise<void> => {
    setIsLoading(true);

    const enteredAddress = {
      addressName,
      addressNip,
      addressStreetName,
      addressBuildingNumber,
      addressApartmentNumber,
      addressMobileNumber,
      addressCity,
      addressCountryCode,
      addressPostalCode,
      addressPhoneNumber,
      addressEmail,
      addressIsSensitive,
    };

    const shouldSendAddress =
      !!addressName &&
      !!addressStreetName &&
      !!addressBuildingNumber &&
      !!addressCity &&
      !!addressPostalCode &&
      !!addressCountryCode &&
      JSON.stringify(enteredAddress) !== JSON.stringify(defaultAddress);

    try {
      await createContractFromDocument({
        variables: {
          documentId: locationState?.documentId,
          contractAgreementCreateInput: {
            documentNumber,
            description,
            parentIds: parentIds && parentIds.map(({ id }) => id),
            documentType: "contract",
            documentKind: "Incoming",
            documentDate,
            caseId: caseName ? caseName[0]?.id : null,
            organizationalUnitId: organizationalUnit
              ? organizationalUnit[0]?.id
              : user?.organizationalUnitToUsers?.[0]?.organizationalUnit?.id,
            status: status && status[0]?.label,
            contractNumber,
            budgetCategory,
            contractType: contractType && contractType[0]?.label,
            beginAt,
            endAt,
            grossValueInPLN,
            collectionPeriodEndAt,
            legalDocumentsVerification: !!legalDocumentsVerification,
            legalAct: legalAct?.length ? legalAct[0]?.id : null,
            contractorAddressId: contractor && contractor[0]?.id,
            senderType: "Address",
          },
          files,
          ...(shouldSendAddress && {
            addressInput: {
              name: addressName,
              nip: addressNip && addressNip?.replaceAll("-", "").toString(),
              city: addressCity,
              streetName: addressStreetName,
              buildingNumber: addressBuildingNumber,
              apartmentNumber: addressApartmentNumber,
              mobileNumber: addressMobileNumber,
              postalCode: addressPostalCode,
              countryCode: addressCountryCode && addressCountryCode[0]?.id,
              email: addressEmail ? addressEmail : null,
              phoneNumber: addressPhoneNumber ? addressPhoneNumber : null,
              overwrite: !!addressOverwrite,
              isSensitive: !!addressIsSensitive,
            },
          }),
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      history.push(
        `/documents/incoming/ContractAgreement/${locationState?.documentId}`
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { refetch: revealAddress, loading: revealAddressLoading } = useQuery(
    ADDRESS_SUGGESTION,
    {
      skip: true,
    }
  );

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await revealAddress({
        id: watchAddress && watchAddress[0] && watchAddress[0].id,
      });

      const choosedAddress = response?.data?.addressSuggestion;
      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.name?.isSensitive)
        setValue("addressIsSensitive", choosedAddress?.name?.isSensitive);

      if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
      if (choosedAddress?.streetName)
        setValue("addressStreetName", choosedAddress?.streetName);
      if (choosedAddress?.buildingNumber)
        setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
      if (choosedAddress?.apartmentNumber)
        setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
      if (choosedAddress?.mobileNumber)
        setValue("addressMobileNumber", choosedAddress?.mobileNumber);
      if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
      if (choosedAddress?.postalCode)
        setValue("addressPostalCode", choosedAddress?.postalCode);
      if (choosedAddress?.countryCode)
        setValue("addressCountryCode", [
          {
            id: choosedAddress?.countryCode,
          },
        ]);
      if (choosedAddress?.phoneNumber)
        setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
      if (choosedAddress?.email)
        setValue("addressEmail", choosedAddress?.email);

      setIsAnyAddressRevealed(true);
      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      setIsAddressRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL
  );

  const onFilesRevealSubmit = async () => {
    setIsLoading(true);

    try {
      await revealFiles({
        variables: {
          documentFilesRevealInput: {
            documentId: locationState?.documentId,
          },
        },
      });

      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      refetch();
      setIsFilesRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsFilesRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errors) scrollOnError(errors, CONTRACT_AGREEMENT_FIELDS, "create");
  }, [errorTimeStamp]);

  useEffect(() => {
    watchEndAtDate &&
      setValue(
        "collectionPeriodEndAt",
        new Date(+watchEndAtDate + 14 * 86400000)
      );
  }, [watchEndAtDate]);

  useEffect(() => {
    locationState && refetch();
  }, []);

  useEffect(() => {
    setAddressFromGus(undefined);
    async function getAddressData() {
      setValue("addressName", "");
      setValue("addressNip", "");
      setValue("addressStreetName", "");
      setValue("addressBuildingNumber", "");
      setValue("addressApartmentNumber", "");
      setValue("addressMobileNumber", "");
      setValue("addressCity", "");
      setValue("addressPostalCode", "");
      setValue("addressPhoneNumber", "");
      setValue("addressEmail", "");
      setValue("addressIsSensitive", false);
      if (watchAddress && watchAddress[0] && watchAddress[0].id) {
        const response = await fetchAddress({
          filter: {
            id: {
              eq: watchAddress[0].id as number,
            },
          },
        });
        const choosedAddress = response?.data?.addressSuggestions?.nodes[0];
        if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
        if (watchAddress[0].typename === "HiddenAddress")
          setValue("addressIsSensitive", true);

        if (watchAddress[0].typename === "Address") {
          if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
          if (choosedAddress?.streetName)
            setValue("addressStreetName", choosedAddress?.streetName);
          if (choosedAddress?.buildingNumber)
            setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
          if (choosedAddress?.apartmentNumber)
            setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
          if (choosedAddress?.mobileNumber)
            setValue("addressMobileNumber", choosedAddress?.mobileNumber);
          if (choosedAddress?.city)
            setValue("addressCity", choosedAddress?.city);
          if (choosedAddress?.postalCode)
            setValue("addressPostalCode", choosedAddress?.postalCode);
          if (choosedAddress?.countryCode)
            setValue("addressCountryCode", [
              {
                id: choosedAddress?.countryCode,
              },
            ]);
          if (choosedAddress?.phoneNumber)
            setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
          if (choosedAddress?.email)
            setValue("addressEmail", choosedAddress?.email);
        }
        setDefaultAddress({
          addressName: choosedAddress?.name || "",
          addressNip: choosedAddress?.nip || "",
          addressStreetName: choosedAddress?.streetName || "",
          addressBuildingNumber: choosedAddress?.buildingNumber || "",
          addressApartmentNumber: choosedAddress?.apartmentNumber || "",
          addressMobileNumber: choosedAddress?.mobileNumber || "",
          addressCity: choosedAddress?.city || "",
          addressCountryCode: [
            {
              id: choosedAddress?.countryCode,
            },
          ],
          addressPostalCode: choosedAddress?.postalCode || "",
          addressPhoneNumber: choosedAddress?.phoneNumber || "",
          addressEmail: choosedAddress?.email || "",
          addressIsSensitive: choosedAddress?.__typename === HIDDEN_ADDRESS,
        });
        unregister("addressName");
        unregister("addressStreetName");
        unregister("addressBuildingNumber");
        unregister("addressApartmentNumber");
        unregister("addressMobileNumber");
        unregister("addressPostalCode");
        unregister("addressCity");
        unregister("addressCountryCode");
      }
    }
    !addressFromGus && getAddressData();

    watchAddress?.[0]?.typename === "Address"
      ? setIsAnyAddressRevealed(true)
      : setIsAnyAddressRevealed(false);
  }, [watchAddress]);

  useEffect(() => {
    if (addressFromGus) setValue("contractor", undefined);
    if (addressFromGus?.nip) setValue("addressNip", addressFromGus?.nip);
    if (addressFromGus?.name) setValue("addressName", addressFromGus?.name);
    if (addressFromGus?.streetName)
      setValue("addressStreetName", addressFromGus?.streetName);
    if (addressFromGus?.buildingNumber)
      setValue("addressBuildingNumber", addressFromGus?.buildingNumber);
    if (addressFromGus?.apartmentNumber)
      setValue("addressApartmentNumber", addressFromGus?.apartmentNumber);
    if (addressFromGus?.mobileNumber)
      setValue("addressMobileNumber", addressFromGus?.mobileNumber);
    if (addressFromGus?.postalCode)
      setValue("addressPostalCode", addressFromGus?.postalCode);
    if (addressFromGus?.city) setValue("addressCity", addressFromGus?.city);
    if (addressFromGus?.countryCode)
      setValue("addressCountryCode", [{ id: addressFromGus?.countryCode }]);
    if (addressFromGus?.phoneNumber)
      setValue("addressPhoneNumber", addressFromGus?.phoneNumber);
    if (addressFromGus?.email) setValue("addressEmail", addressFromGus?.email);
  }, [addressFromGus]);

  useEffect(() => {
    if (data?.document) {
      setValue("documentNumber", data?.document?.documentNumber);
      data?.document?.documentDate &&
        setValue(
          "documentDate",
          new Date(data?.document?.documentDate as Date)
        );
      setValue("description", data?.document?.description);
      setValue("caseName", [{ id: data?.document?.caseId }]);
      setValue("contractor", [
        {
          id: data?.document?.sender?.id,
          label: `${
            data?.document?.sender?.name ? data?.document?.sender?.name : ""
          } ${
            data?.document?.sender?.address
              ? ", " + data?.document?.sender?.address
              : ""
          } ${
            data?.document?.sender?.city
              ? ", " + data?.document?.sender?.city
              : ""
          }`,
          typename: data?.document?.sender?.__typename,
        },
      ]);
      setValue("addressIsSensitive", data?.document?.sender?.isSensitive);
      if (data?.document?.organizationalUnit?.id)
        setValue("organizationalUnit", [
          {
            id: data?.document?.organizationalUnit?.id,
            ...data?.document?.organizationalUnit,
          },
        ]);

      if (data?.document?.documentLinks?.length)
        setValue(
          "parentIds",
          data?.document?.documentLinks
            ?.filter(
              ({ type, linkerId }: DocumentLink) =>
                type === "Parent" && parseInt(data.document.id) === linkerId
            )
            .map(({ linked, linker }: DocumentLink) =>
              parseInt(data.document.id) === linker.id ? linked : linker
            )
        );
    }
  }, [data]);

  if (!checkPermission(PERMISSIONS.contractAgreement.create))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Nowa umowa"
        labels={["Dokumenty", "Tworzenie umowy"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty
                ? setIsCancelConfirmDialogOpen(true)
                : history.push("/documents"),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "createContractAgreement",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            <FormControl label="Data rejestracji w systemie" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>{time.toLocaleDateString()}</FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form id="createContractAgreement" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {CONTRACT_AGREEMENT_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create?.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <>
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginBottom="scale200"
                      marginTop="scale600"
                      height="20px"
                    >
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <LabelMedium>{group.label}</LabelMedium>
                        {watchAddress?.[0]?.typename === "HiddenAddress" &&
                          !isAnyAddressRevealed &&
                          group.id === "contractor" && (
                            <Button
                              size={SIZE.mini}
                              kind={KIND.secondary}
                              $style={{ marginLeft: "10px" }}
                              onClick={() =>
                                setIsAddressRevealConfirmDialogOpen(true)
                              }
                              isLoading={revealAddressLoading}
                              type="button"
                            >
                              Pokaż dane
                            </Button>
                          )}
                        {hasHiddenFiles && group.id === "files" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsFilesRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealFilesLoading || isFetching}
                            type="button"
                          >
                            Pokaż pliki
                          </Button>
                        )}
                      </Block>
                      {watchAddress?.[0]?.typename === "HiddenAddress" &&
                        !isAnyAddressRevealed &&
                        group.id === "contractor" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "12px" }}
                          >
                            Dane osobowe - podgląd zablokowany
                          </FormattedValue>
                        )}
                      {locationState &&
                        !hasHiddenFiles &&
                        !!data?.document?.files?.length &&
                        group.id === "files" && (
                          <FormattedValue
                            dataType="pre"
                            $style={{ fontSize: "13px" }}
                          >
                            Dostęp jest aktywny do końca trwania sesji
                          </FormattedValue>
                        )}
                    </Block>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </>
                </Cell>
              ),
              group.fields
                .filter((f) => f.create?.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.type === FIELDS.Checkbox ? "" : item.label}
                      caption={
                        (locationState &&
                          item.id === "filesList" &&
                          hasHiddenFiles) ||
                        (locationState &&
                          !data?.document?.files?.length &&
                          item.id === "filesList") ||
                        (!locationState && item.id === "filesList")
                          ? ""
                          : item.caption
                      }
                      required={
                        item.info === "possiblyRequired"
                          ? isAnyAddressRevealed ||
                            !watchAddress?.length ||
                            watchAddress?.[0]?.typename === "Address"
                          : item.create?.required
                      }
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {item.type === FIELDS.AddressesSelect ? (
                        <ControlledAddressesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.CasesSelect ? (
                        <ControlledCasesSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.ContractStatusSelect ? (
                        <ControlledContractStatusSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.ContractTypeSelect ? (
                        <ControlledContractTypeSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DocumentsSelect ? (
                        <ControlledDocumentsSelect
                          control={control}
                          id={item.id}
                          name={item.id}
                          placeholder="Wybierz"
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                        <ControlledOrganizationalUnitsSelect
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={item.placeholder}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DictionaryValuesSelect ? (
                        <ControlledDictionaryValuesSelect
                          dictionarySystemName={
                            item.id === "legalAct"
                              ? "legal.basis"
                              : item.id === "addressCountryCode"
                              ? "countryCodes.alpha2.pl"
                              : undefined
                          }
                          control={control}
                          name={item.id}
                          id={item.id}
                          disabled={
                            item.id === "addressCountryCode" &&
                            !isAnyAddressRevealed &&
                            !!watchAddress?.length
                          }
                          placeholder={
                            watchAddress?.[0]?.typename === "HiddenAddress" &&
                            item.additionalData === "sensitive"
                              ? "**********"
                              : "Wybierz"
                          }
                          {...((item.create.required ||
                            (item.info === "possiblyRequired" &&
                              isAnyAddressRevealed &&
                              item.id.includes("address") &&
                              item.id !== "addressName") ||
                            (item.info === "possiblyRequired" &&
                              item.id.includes("address") &&
                              item.id !== "addressName")) && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DatePicker ? (
                        <ControlledDatePicker
                          name={item.id}
                          control={control}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          {...(item.id === "endAt" && {
                            minDate: watchBeginAtDate as Date,
                          })}
                          {...(item.id === "beginAt" && {
                            maxDate: watchEndAtDate as Date,
                          })}
                        />
                      ) : item.type === FIELDS.FilesPicker ? (
                        <ControlledFilesPicker
                          control={control}
                          name={item.id}
                          maxSize={134217728}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.FilesList &&
                        locationState &&
                        data?.document?.files ? (
                        <FilesList
                          files={
                            (data?.document?.files as unknown) as SingleFile[]
                          }
                          hasHiddenFiles={hasHiddenFiles}
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                        />
                      ) : item.type === FIELDS.Checkbox ? (
                        <div className={css({ marginTop: "40px" })}>
                          {item.id === "addressOverwrite" ? (
                            <Tooltip
                              placement="bottomLeft"
                              content={
                                <p>
                                  Zaznaczenie tej opcji spowoduje aktualizację
                                  danych <br /> wybranego adresata w bazie, ale
                                  nie wpłynie to na <br /> wcześniejsze
                                  dokumenty powiązane z tym adresatem.
                                </p>
                              }
                            >
                              <span>
                                <ControlledCheckbox
                                  control={control}
                                  name={item.id}
                                  disabled={
                                    loading ||
                                    (!isAnyAddressRevealed &&
                                      !!watchAddress?.length &&
                                      item.id.includes("address") &&
                                      !item.id.includes("Name"))
                                  }
                                  {...(item.create.required && {
                                    rules: {
                                      required:
                                        formValidation.messages.required,
                                    },
                                  })}
                                >
                                  {item.label}
                                </ControlledCheckbox>
                              </span>
                            </Tooltip>
                          ) : (
                            <ControlledCheckbox
                              control={control}
                              name={item.id}
                              disabled={
                                loading ||
                                (!isAnyAddressRevealed &&
                                  !!watchAddress?.length &&
                                  item.id.includes("address") &&
                                  !item.id.includes("Name"))
                              }
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            >
                              {item.label}
                            </ControlledCheckbox>
                          )}
                        </div>
                      ) : item.type === FIELDS.TextArea ? (
                        <ControlledTextArea
                          control={control}
                          name={item.id}
                          {...(item.create.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          id={item.id}
                          placeholder={
                            !isAnyAddressRevealed &&
                            !!watchAddress?.length &&
                            item.additionalData === "sensitive"
                              ? "**********"
                              : item.placeholder
                          }
                          rules={{
                            ...(item.create.required && {
                              required: formValidation.messages.required,
                            }),
                            ...(item.id === "addressEmail" && {
                              pattern: {
                                value: formValidation.patterns.email,
                                message: formValidation.messages.incorrectEmail,
                              },
                            }),
                          }}
                          masked={
                            item.id === "addressNip"
                              ? watchCountryCode?.[0]?.id === "PL"
                                ? item.mask !== undefined
                                : false
                              : item.mask !== undefined
                          }
                          mask={item.mask}
                          maskChar="_"
                          disabled={
                            !isAnyAddressRevealed &&
                            !!watchAddress?.length &&
                            item.id.includes("address")
                          }
                          endEnhancer={
                            item.id === "addressNip" ? (
                              <Tooltip content="Pobierz z GUS">
                                <span
                                  onClick={() => setIsGusDownloadOpen(true)}
                                  className={css({ cursor: "pointer" })}
                                >
                                  <Download
                                    size={16}
                                    className={css({
                                      verticalAlign: "middle",
                                      marginLeft: "6px",
                                      display: "inline",
                                    })}
                                  />
                                </span>
                              </Tooltip>
                            ) : (
                              item.endEnhancer
                            )
                          }
                          {...(item.type === FIELDS.NumberInput && {
                            type: "number",
                          })}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie tworzenia umowy"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.push("/documents")}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
            <ConfirmDialog
              isOpen={isFilesRevealConfirmDialogOpen}
              type={ConfirmDialogType.FilesReveal}
              close={() => setIsFilesRevealConfirmDialogOpen(false)}
              confirm={() => onFilesRevealSubmit()}
            />
            <GusDownloadModal
              isOpen={isGusDownloadOpen}
              close={() => setIsGusDownloadOpen(false)}
              confirm={() => null}
              currentNip={watchNip}
              setAddressFromGus={setAddressFromGus}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
