import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Cell from "../../../components/cell";
import ConfirmDialog from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import { ControlledDatePicker } from "../../../components/date-picker";
import DocumentBarcode from "../../../components/document-barcode";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import {
  ControlledCasesSelect,
  ControlledPaymentTypesSelect,
} from "../../../components/select";
import {
  ControlledTransferTablesComposer,
  TransferTableItemData,
} from "../../../components/transfer-tables-composer";
import { useAssignment } from "../../../contexts/assignment-context";
import { BudgetsProvider } from "../../../contexts/budgets-context";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { FIELDS } from "../../../fields.d";
import { checkPermission } from "../../../utils/check-permission";
import { formValidation } from "../../../utils/formValidation";
import { PERMISSIONS } from "../../../utils/permissions";
import { AssignmentLocationState } from "../../Folders/folders";
import { PaymentItem } from "../payments";
import { FormInputs, PAYMENTS_FIELDS } from "../payments.form";
import { PAYMENTS_EDIT, PAYMENTS_SHOW } from "../payments.gql";

export default function PaymentsEdit(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [isSaveConfirmDialogOpen, setIsSaveConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { isLoading, setIsLoading, isFetching, setIsFetching } = useLoading();
  const { id } = useParams<{ id: string }>();
  const documentId = parseInt(id);
  const { findValue } = useDictionaries();

  const { state } = useLocation<AssignmentLocationState>();
  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<FormInputs>();

  const [focusedInput, setFocusedInput] = useState<keyof FormInputs>();

  const watchTable = watch("paymentItems");

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (focusedInput !== name && type) {
        setFocusedInput(name as keyof FormInputs);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, focusedInput]);

  const totalEnteredTablesAmount = watchTable
    ? parseFloat(
        watchTable
          ?.reduce((a, b) => a + (b.amount ? b.amount : 0), 0)
          ?.toFixed(2)
      )
    : 0;

  const { refetch, data, error } = useQuery(PAYMENTS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const invoiceAmount = data?.payment?.financialAccountingDocument?.grossValue;
  const invoiceBalance = invoiceAmount - totalEnteredTablesAmount;
  const shouldShowAlertBeforeSave = invoiceBalance !== 0;
  const legalAct = data?.payment?.documentRoot?.legalAct
    ? findValue(data?.payment?.documentRoot?.legalAct)
    : "";

  const [editPayment, { error: mutationError }] = useMutation(PAYMENTS_EDIT, {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const onSubmit = async ({
    paymentTypes,
    nrRejestr,
    paymentItems,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await editPayment({
        variables: {
          paymentUpdateInput: {
            id: id ? parseInt(id) : null,
            paymentType: paymentTypes && paymentTypes[0]?.id,
            nrRejestr: nrRejestr || null,
          },
          paymentItemUpdateInputs: paymentItems,
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });

      history.push("/payments/" + response?.data?.paymentUpdate?.id);
    } catch (error: unknown) {
      enqueueSnackbar({
        message:
          (error as ApolloError).graphQLErrors?.map(
            ({ message }) => message
          )[0] || (error as ApolloError).message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.payment) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data?.payment?.currentAssignment) {
      setAssignment(data?.payment?.currentAssignment);
      setIsAssignmentActive(true);
    }

    data?.payment && setIsFetching(false);
    data?.payment?.paymentType &&
      setValue("paymentTypes", [{ id: data?.payment?.paymentType }]);
    setValue("nrRejestr", data?.payment?.nrRejestr);

    if (data?.payment?.paymentItems) {
      setValue(
        "paymentItems",
        data?.payment?.paymentItems.map(
          (item: PaymentItem) =>
            ({
              id: String(item.id),
              table: item.tableId,
              description: item.description,
              amount: item.amount,
              ...(item.bazaPositionId && {
                budget: { id: item.bazaPositionId },
              }),
              internalAccount: { id: item.internalAccount.id },
              percent: parseFloat(
                new Intl.NumberFormat("en-US", {
                  style: "percent",
                  maximumFractionDigits: 2,
                })
                  .format(
                    item.amount /
                      data?.payment?.paymentItems
                        ?.filter(
                          (paymentItem: PaymentItem) =>
                            paymentItem.tableId === item.tableId
                        )
                        .reduce(
                          (a: number, b: PaymentItem) =>
                            a + (b.amount ? b.amount : 0),
                          0
                        )
                        .toFixed(2)
                  )
                  .replace(/%/g, "")
              ),
            } as TransferTableItemData)
        )
      );
    }
  }, [data]);

  if (
    data &&
    data?.payment &&
    (!checkPermission(PERMISSIONS.payments.update) ||
      !data?.payment?.isEditable)
  )
    <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.payment?.internalNumber}
        labels={[
          <FormattedValue
            key="label_1"
            dataType={"model:cases"}
            data={data?.payment?.case?.id}
            additionalData={data?.payment?.case?.isConductedElectronically}
            showBlankWhenEmpty
          >
            {`${data?.payment?.case?.number}`}
          </FormattedValue>,
          "Polecenie płatności",
        ]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: shouldShowAlertBeforeSave
              ? (event: MouseEvent) => {
                  event.preventDefault();
                  setIsSaveConfirmDialogOpen(true);
                }
              : handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: shouldShowAlertBeforeSave ? "button" : "submit",
            ...(shouldShowAlertBeforeSave && {
              formId: "createPayment",
            }),
          },
        ]}
      >
        <DocumentBarcode
          documentId={documentId}
          internalNumber={data?.payment?.internalNumber as string}
        />
      </Header>

      <Content>
        <form
          id="editPayment"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          onFocus={() => {
            const inputName =
              typeof window !== "undefined" &&
              (window?.document?.activeElement?.id as keyof FormInputs);

            inputName &&
              inputName !== focusedInput &&
              !inputName.includes("bui") &&
              setFocusedInput(inputName);
          }}
        >
          <Grid>
            {data?.payment?.financialAccountingDocument && (
              <>
                <Cell span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    Dokument
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
                <Cell span={3}>
                  <FormControl label="Numer" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType="model:documents"
                        data={`incoming/FinancialAccountingDocument/${data?.payment?.financialAccountingDocument?.id}`}
                      >
                        {
                          data?.payment?.financialAccountingDocument
                            ?.internalNumber
                        }
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Jednostka organizacyjna" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType="model:organizational-units"
                        data={data?.payment?.financialAccountingDocument?.organizationalUnit?.id?.toString()}
                      >
                        {
                          data?.payment?.financialAccountingDocument
                            ?.organizationalUnit?.name
                        }
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Data wystawienia" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue dataType="date">
                        {
                          data?.payment?.financialAccountingDocument
                            ?.documentDate
                        }
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Termin płatności" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue dataType="date">
                        {data?.payment?.financialAccountingDocument?.paymentAt}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Kwota" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType="quota"
                        currency={
                          data?.payment?.financialAccountingDocument?.currency
                            ?.code
                        }
                      >
                        {data?.payment?.financialAccountingDocument?.grossValue}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                {/* <Cell span={3}>
                  <FormControl label="Typ faktury" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue>{invoiceType || ""}</FormattedValue>
                    )}
                  </FormControl>
                </Cell> */}
                <Cell span={9}>
                  <FormControl
                    label="Podstawa prawna z wniosku"
                    disabled={true}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue>{legalAct || ""}</FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Temat" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue>
                        {data?.payment?.financialAccountingDocument?.title}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={3}>
                  <FormControl label="Program" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue>
                        {
                          data?.payment?.financialAccountingDocument
                            ?.programName
                        }
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
                <Cell span={6}>
                  <FormControl label="Opis" disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue>
                        {
                          data?.payment?.financialAccountingDocument
                            ?.description
                        }
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              </>
            )}
            {PAYMENTS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.edit.visible).length > 0
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <Block
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <LabelMedium marginBottom="scale200" marginTop="scale600">
                      {group.label}
                    </LabelMedium>
                    {isFetching ? (
                      <Skeleton
                        rows={0}
                        height="20px"
                        width="200px"
                        animation
                      />
                    ) : (
                      <Block display="flex" justifyContent="flex-end">
                        {group.id === "bankAccount" && (
                          <LabelMedium
                            marginBottom="scale200"
                            marginTop="scale600"
                            $style={{
                              color:
                                invoiceBalance !== 0
                                  ? theme.colors.negative
                                  : theme.colors.positive,
                            }}
                          >
                            {`Kwota: ${invoiceAmount
                              ?.toFixed(2)
                              .replace(/\./g, ",")} ${
                              data?.payment?.financialAccountingDocument
                                ?.currency?.code || "PLN"
                            },  ${
                              invoiceBalance === 0
                                ? "wykorzystano całą kwotę"
                                : invoiceBalance > 0
                                ? "pozostało " +
                                  invoiceBalance
                                    ?.toFixed(2)
                                    .replace(/\./g, ",") +
                                  ` ${
                                    data?.payment?.financialAccountingDocument
                                      ?.currency?.code || "PLN"
                                  }`
                                : invoiceBalance < 0
                                ? "przekroczono o " +
                                  Math.abs(
                                    invoiceAmount - totalEnteredTablesAmount
                                  )?.toFixed(2) +
                                  ` ${
                                    data?.payment?.financialAccountingDocument
                                      ?.currency?.code || "PLN"
                                  }`
                                : ""
                            }`}
                          </LabelMedium>
                        )}
                      </Block>
                    )}
                  </Block>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.edit.visible)
                .map((item, index) => (
                  <Cell span={item.span || 6} key={group.id + `-field` + index}>
                    <FormControl
                      label={item.label}
                      required={item.edit.required}
                      caption={item?.caption}
                      error={
                        ((errors as any)[item.id] &&
                          (errors as any)[item.id].message) ||
                        (mutationError &&
                          mutationError.graphQLErrors[0]?.extensions?.code ===
                            "INPUT_VALIDATION_ERROR" &&
                          mutationError.graphQLErrors[0]?.extensions?.validationErrors
                            ?.find(
                              (vE: InputValidationError) =>
                                vE?.property === item.id
                            )
                            ?.errors.map((message: string) => (
                              <div
                                key="error"
                                className={css({
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                })}
                              >
                                {message}
                                <span
                                  className={css({
                                    color: "#999",
                                    marginLeft: "auto",
                                    marginRight: "5px",
                                  })}
                                >
                                  Walidacja serwera
                                </span>
                                <AlertOctagon color="#999" size={12} />
                              </div>
                            ))[0])
                      }
                      disabled={isLoading}
                    >
                      {isFetching ? (
                        <Skeleton
                          rows={0}
                          height="36px"
                          width="100%"
                          animation
                        />
                      ) : item.type === FIELDS.PaymentTypesSelect ? (
                        <ControlledPaymentTypesSelect
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.CasesSelect ? (
                        <ControlledCasesSelect
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.DatePicker ? (
                        <ControlledDatePicker
                          name={item.id}
                          control={control}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                        />
                      ) : item.type === FIELDS.TransferTable ? (
                        <BudgetsProvider>
                          <ControlledTransferTablesComposer
                            control={control}
                            name={item.id}
                            id={item.id}
                            totalAmount={data?.payment?.amount}
                            organizationalUnitId={
                              data?.payment?.financialAccountingDocument
                                ?.organizationalUnit?.id
                            }
                            currency={
                              data?.payment?.financialAccountingDocument
                                ?.currency
                            }
                            hasFocus={focusedInput === item.id}
                          />
                        </BudgetsProvider>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={item.id}
                          placeholder={item.placeholder}
                          {...(item.edit.required && {
                            rules: {
                              required: formValidation.messages.required,
                            },
                          })}
                          endEnhancer={item?.endEnhancer}
                        />
                      )}
                    </FormControl>
                  </Cell>
                )),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
            <ConfirmDialog
              isOpen={isSaveConfirmDialogOpen}
              label="Suma przelewów nie jest równa kwocie z faktury. Czy na pewno chcesz zapisać polecenie płatności?"
              close={() => setIsSaveConfirmDialogOpen(false)}
              confirm={handleSubmit(onSubmit)}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
