import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { SIZE } from "baseui/input";
import { StatefulPopover } from "baseui/popover";
import { LabelSmall } from "baseui/typography";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Row } from "react-table";
import { CirclePlus, Refresh } from "tabler-icons-react";

import { RequestForm } from "../containers/Documents/documents";
import { useLoading } from "../contexts/loading-context";
import { generateUUID, localeParseFloat } from "../utils/misc";
import Button, { ForwardedButton } from "./button";
import FormControl from "./form-control";
import FormattedValue from "./formatted-value";
import { IsolatedInput, NumericInput } from "./input";
import { RequestFormItemsSelect, RequestFormsSelect } from "./select";
import Table from "./table";

type SingleTable = {
  id: string;
  requestFormId?: number;
  requestFormInternalNumber?: string;
  requestFormDocumentNumber?: string;
};

type InvoiceTableProps = {
  value?: InvoiceTableItem[];
  onChange?: (items: InvoiceTableItem[]) => void;
  error?: boolean;
  disabled?: boolean;
  id?: string;
  currency?: string;
  hasFocus?: boolean;
  requestForms?: RequestForm[];
};

export type InvoiceTableItem = {
  id: string;
  edit?: boolean;
  originalId?: number;
  name?: string;
  totalAmount?: number;
  totalAmountWithTax?: number;
  requestFormId?: number;
  requestFormInternalNumber?: string;
  requestFormDocumentNumber?: string;
  requestFormItem?: { id: number; label?: string };
  table: string;
};

export default function InvoiceTable({
  value,
  onChange,
  error,
  id,
  hasFocus = false,
  currency = "PLN",
  requestForms,
}: InvoiceTableProps): React.ReactElement {
  const [items, setItems] = useState<InvoiceTableItem[]>([]);
  const [tables, setTables] = useState<SingleTable[]>([
    {
      id: "autoGeneratedFirstTable",
    },
  ]);
  const [currentField, setCurrentField] = useState<string | undefined>();
  const [changes, setChanges] = useState(0);
  const [editingRow, setEditingRow] = useState("");
  const [editingTable, setEditingTable] = useState("autoGeneratedFirstTable");
  const [css, theme] = useStyletron();
  const { isLoading } = useLoading();

  useEffect(() => {
    if (changes === 0 && !!value?.length) {
      const tables = value
        .map((item: InvoiceTableItem) => ({
          id: item?.table,
          requestFormId: item?.requestFormId,
        }))
        .filter(
          (value, index, self) =>
            self.findIndex(({ id }) => id === value?.id) === index
        )
        .map((item: { id: string; requestFormId?: number }) => ({
          id: item?.id,
          requestFormId: item?.requestFormId,
        }));

      setTables([...tables]);
      setItems(value);
      setChanges((changes) => changes + 1);
    }
  }, [value]);

  useEffect(() => {
    function updateState() {
      (window.document.activeElement as HTMLElement).blur();

      setTimeout(() => setChanges((changes) => changes + 1), 200);
    }

    const header = window.document.getElementsByTagName("header")?.[0];

    if (header) {
      header.addEventListener("mouseenter", updateState);
    }

    return () => {
      if (header) {
        header.removeEventListener("mouseenter", updateState);
      }
    };
  }, []);

  useEffect(() => {
    onChange &&
      onChange(
        items?.map((item: any) => {
          if (item)
            return {
              ...(typeof item.id === "number" && {
                id: parseInt(item.id),
              }),
              ...(item.name && {
                name: item.name,
              }),
              ...(item.totalAmount && {
                totalAmount: item.totalAmount,
              }),
              ...(item.totalAmountWithTax && {
                totalAmountWithTax: item.totalAmountWithTax,
              }),
              ...(item.requestFormItem && {
                requestFormItemId: item.requestFormItem.id,
              }),
              ...(item.table && {
                tableId: item.table,
              }),
            };
        })
      );
  }, [changes]);

  const columns = useMemo(
    () => [
      {
        accessor: "lp",
        Header: "Lp.",
        id: "ordinalNumber",
        Cell: ({ row }: { row: Row<InvoiceTableItem> }) => (
          <Block width="30px">{row.index + 1}</Block>
        ),
      },
      {
        accessor: "name",
        Header: (
          <Block
            display="flex"
            overrides={{
              Block: { style: { whiteSpace: "nowrap", minWidth: "200px" } },
            }}
          >
            Nazwa pozycji
          </Block>
        ),
        Cell: ({ row }: { row: Row<InvoiceTableItem> }) => {
          return (
            <Block display="flex" justifyContent="flex-end">
              <IsolatedInput
                size={SIZE.compact}
                value={row?.original?.name}
                disabled={isLoading}
                onValueChange={(value) => {
                  setItems((items) => {
                    const editedItemIndex = items?.findIndex(
                      (item) => item.id === row.original.id
                    );
                    items[editedItemIndex].name = String(value);

                    return items;
                  });
                }}
              />
            </Block>
          );
        },
      },
      {
        id: "totalAmount",
        Header: (
          <Block
            display="flex"
            overrides={{
              Block: { style: { whiteSpace: "nowrap", minWidth: "150px" } },
            }}
          >
            Kwota netto
          </Block>
        ),
        Cell: ({ row }: { row: Row<InvoiceTableItem> }) => {
          return (
            <Block display="flex" justifyContent="flex-end" width="150px">
              <NumericInput
                size={SIZE.compact}
                value={row?.original?.totalAmount}
                endEnhancer={currency}
                disabled={isLoading}
                type="number"
                step={0.01}
                onValueChange={(value) => {
                  setItems((items) => {
                    const editedItemIndex = items?.findIndex(
                      (item) => item.id === row.original.id
                    );

                    items[editedItemIndex].totalAmount =
                      localeParseFloat(value) || undefined;

                    return items;
                  });
                }}
              />
            </Block>
          );
        },
      },
      {
        id: "totalAmountWithTax",
        Header: (
          <Block
            display="flex"
            overrides={{
              Block: { style: { whiteSpace: "nowrap", minWidth: "150px" } },
            }}
          >
            Kwota brutto
          </Block>
        ),
        Cell: ({ row }: { row: Row<InvoiceTableItem> }) => {
          return (
            <Block display="flex" justifyContent="flex-end" width="150px">
              <NumericInput
                size={SIZE.compact}
                value={row?.original?.totalAmountWithTax}
                endEnhancer={currency}
                disabled={isLoading}
                type="number"
                step={0.01}
                onValueChange={(value: string) => {
                  setItems((items) => {
                    const editedItemIndex = items?.findIndex(
                      (item) => item.id === row.original.id
                    );

                    items[editedItemIndex].totalAmountWithTax =
                      localeParseFloat(value) || undefined;

                    return items;
                  });
                }}
              />
            </Block>
          );
        },
      },
      {
        id: "requestFormItemId",
        Header: "Pozycja wniosku",
        Cell: ({ row }: { row: Row<InvoiceTableItem> }) => {
          return (
            <RequestFormItemsSelect
              size={SIZE.compact}
              value={
                row?.original?.requestFormItem
                  ? [row?.original?.requestFormItem]
                  : []
              }
              disabled={isLoading}
              onChange={(params) => {
                setItems((items) => {
                  items[
                    items.findIndex((item) => item.id === row.original.id)
                  ].requestFormItem = params.option as {
                    id: number;
                    label: string;
                  };
                  return items;
                });

                setChanges((changes) => changes + 1);
              }}
              requestFormId={row?.original?.requestFormId}
            />
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<InvoiceTableItem> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {row.original.totalAmount ? (
              <StatefulPopover
                overrides={{
                  Inner: {
                    style: {
                      borderTopLeftRadius: theme.borders.radius200,
                      borderTopRightRadius: theme.borders.radius200,
                      borderBottomRightRadius: theme.borders.radius200,
                      borderBottomLeftRadius: theme.borders.radius200,
                      paddingTop: theme.sizing.scale200,
                      paddingBottom: theme.sizing.scale200,
                      paddingRight: theme.sizing.scale300,
                      paddingLeft: theme.sizing.scale300,
                      backgroundColor: "white",
                    },
                  },
                  Body: {
                    style: {
                      borderTopLeftRadius: theme.borders.radius200,
                      borderTopRightRadius: theme.borders.radius200,
                      borderBottomRightRadius: theme.borders.radius200,
                      borderBottomLeftRadius: theme.borders.radius200,
                      marginRight: "10px",
                    },
                  },
                  Arrow: {
                    style: {
                      backgroundColor: "white",
                    },
                  },
                }}
                content={({ close }) => (
                  <Block
                    padding="scale200"
                    backgroundColor="white"
                    display="flex"
                    alignItems="center"
                  >
                    <LabelSmall marginRight="8px">Czy na pewno?</LabelSmall>
                    <Button
                      kind="secondary"
                      size={SIZE.mini}
                      onClick={() => {
                        setItems((items) =>
                          items?.filter((item) => item.id !== row.original.id)
                        );

                        setChanges((changes) => changes + 1);
                        close();
                      }}
                      $style={{
                        backgroundColor: theme.colors.negative400,
                        color: "white",
                        ...(!isLoading && {
                          ":hover": {
                            backgroundColor: theme.colors.negative500,
                          },
                        }),
                      }}
                    >
                      Tak, usuń
                    </Button>
                    <Button
                      kind="secondary"
                      size={SIZE.mini}
                      onClick={() => close()}
                      $style={{
                        marginLeft: "5px",
                      }}
                    >
                      Anuluj
                    </Button>
                  </Block>
                )}
                showArrow
              >
                <ForwardedButton
                  type="reset"
                  kind="secondary"
                  size={SIZE.mini}
                  disabled={isLoading}
                >
                  Usuń
                </ForwardedButton>
              </StatefulPopover>
            ) : (
              <Button
                disabled={isLoading}
                kind="secondary"
                size={SIZE.mini}
                onClick={() => {
                  setItems((items) =>
                    items?.filter((item) => item.id !== row.original.id)
                  );
                  setChanges((changes) => changes + 1);
                }}
              >
                Usuń
              </Button>
            )}
          </div>
        ),
      },
    ],
    [hasFocus, currency]
  );

  return (
    <>
      {tables.map((table) => {
        const tableItems = items?.filter(
          (item: InvoiceTableItem) => item?.table === table?.id
        );

        const totalEnteredInvoiceAmount = tableItems
          ?.reduce(
            (a, b) => a + (b ? (b.totalAmount ? b.totalAmount : 0) : 0),
            0
          )
          ?.toFixed(2);

        const totalEnteredInvoiceAmountWithTax = tableItems
          ?.reduce(
            (a, b) =>
              a + (b ? (b.totalAmountWithTax ? b.totalAmountWithTax : 0) : 0),
            0
          )
          ?.toFixed(2);

        return (
          <div key={table.id}>
            <Block display="flex" justifyContent="space-between">
              <Block display="flex">
                <Block>
                  <FormControl label="Wniosek o wydatkowanie">
                    <RequestFormsSelect
                      size={SIZE.mini}
                      disabled={isLoading}
                      onFocus={() => {
                        setEditingTable(table.id);
                        setEditingRow("");
                      }}
                      value={
                        table?.requestFormId
                          ? [
                              (requestForms?.find(
                                (item) => item?.id === table?.requestFormId
                              ) as any) || {
                                id: table?.requestFormId,
                                internalNumber:
                                  table?.requestFormInternalNumber,
                                documentNumber:
                                  table?.requestFormDocumentNumber,
                              },
                            ]
                          : []
                      }
                      onChange={(params) => {
                        const editingTableIndex = tables.findIndex(
                          (table) => table.id === editingTable
                        );
                        const requestForm = params.option as {
                          id: number;
                          label: string;
                          internalNumber?: string;
                          documentNumber?: string;
                        };

                        setTables((tables) => {
                          tables[editingTableIndex].requestFormId =
                            requestForm?.id;

                          tables[editingTableIndex].requestFormInternalNumber =
                            requestForm?.internalNumber;

                          tables[editingTableIndex].requestFormDocumentNumber =
                            requestForm?.documentNumber;

                          setChanges((changes) => changes + 1);

                          return tables;
                        });

                        setItems((items) => {
                          items?.map((item: InvoiceTableItem) => {
                            if (
                              item?.table === tables?.[editingTableIndex]?.id
                            ) {
                              item.requestFormId = requestForm?.id;
                              item.requestFormItem = undefined;
                            }
                          });
                          return items;
                        });
                      }}
                    />
                  </FormControl>
                </Block>
              </Block>
            </Block>

            <Table<InvoiceTableItem>
              id={id}
              compact
              columns={columns}
              data={items?.filter((item) => item && item?.table === table?.id)}
              expandedNumber={0}
              scrollable
              editable
              {...(!!error && {
                $style: {
                  backgroundColor: theme.colors.inputFillError,
                  borderColor: theme.colors.inputBorderError,
                  borderWidth: "2px",
                  borderStyle: "solid",
                },
              })}
              $footer={() => {
                return (
                  <>
                    <td
                      className={css({
                        paddingTop: theme.sizing.scale100,
                        paddingBottom: theme.sizing.scale100,
                        paddingLeft: theme.sizing.scale500,
                        paddingRight: theme.sizing.scale500,
                        position: "sticky",
                        left: 0,
                      })}
                      colSpan={2}
                    >
                      <Block
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Button
                          kind="tertiary"
                          size="compact"
                          type="button"
                          key="button"
                          disabled={isLoading}
                          onClick={() => {
                            const newId = `temp ${generateUUID()}`;

                            setItems([
                              ...items,
                              {
                                id: newId,
                                edit: true,
                                table: table.id,
                                requestFormId: table.requestFormId,
                              },
                            ]);
                            setEditingRow(newId);
                            setCurrentField("name");
                          }}
                          startEnhancer={<CirclePlus size={16} />}
                        >
                          Dodaj pozycję
                        </Button>
                      </Block>
                    </td>
                    <td
                      className={css({
                        paddingTop: theme.sizing.scale100,
                        paddingBottom: theme.sizing.scale100,
                        paddingLeft: theme.sizing.scale500,
                        paddingRight: theme.sizing.scale500,
                        position: "sticky",
                      })}
                      colSpan={1}
                    >
                      <div
                        className={css({
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "default",
                        })}
                      >
                        <LabelSmall marginRight="6px">
                          Suma:{" "}
                          <strong>
                            <FormattedValue
                              dataType="quota"
                              currency={currency}
                            >
                              {totalEnteredInvoiceAmount}
                            </FormattedValue>
                          </strong>
                        </LabelSmall>

                        <Refresh
                          tabIndex={0}
                          size={14}
                          className={css({
                            cursor: isLoading ? "not-allowed" : "pointer",
                          })}
                          {...(!isLoading && {
                            onClick: () => {
                              setChanges((changes) => changes + 1);
                            },
                          })}
                        />
                      </div>
                    </td>
                    <td
                      className={css({
                        paddingTop: theme.sizing.scale100,
                        paddingBottom: theme.sizing.scale100,
                        paddingLeft: theme.sizing.scale500,
                        paddingRight: theme.sizing.scale500,
                        position: "sticky",
                      })}
                      colSpan={1}
                    >
                      <div
                        className={css({
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "default",
                        })}
                      >
                        <LabelSmall marginRight="6px">
                          Suma:{" "}
                          <strong>
                            <FormattedValue
                              dataType="quota"
                              currency={currency}
                            >
                              {totalEnteredInvoiceAmountWithTax}
                            </FormattedValue>
                          </strong>
                        </LabelSmall>

                        <Refresh
                          tabIndex={0}
                          size={14}
                          className={css({
                            cursor: isLoading ? "not-allowed" : "pointer",
                          })}
                          {...(!isLoading && {
                            onClick: () => {
                              setChanges((changes) => changes + 1);
                            },
                          })}
                        />
                      </div>
                    </td>
                    <td colSpan={2}></td>
                  </>
                );
              }}
            />
            <Block display="flex" justifyContent="flex-end" marginTop="10px">
              {items?.some(
                (item: InvoiceTableItem) => item && item.table === table.id
              ) ? (
                <StatefulPopover
                  overrides={{
                    Inner: {
                      style: {
                        borderTopLeftRadius: theme.borders.radius200,
                        borderTopRightRadius: theme.borders.radius200,
                        borderBottomRightRadius: theme.borders.radius200,
                        borderBottomLeftRadius: theme.borders.radius200,
                        paddingTop: theme.sizing.scale200,
                        paddingBottom: theme.sizing.scale200,
                        paddingRight: theme.sizing.scale300,
                        paddingLeft: theme.sizing.scale300,
                        backgroundColor: "white",
                      },
                    },
                    Body: {
                      style: {
                        borderTopLeftRadius: theme.borders.radius200,
                        borderTopRightRadius: theme.borders.radius200,
                        borderBottomRightRadius: theme.borders.radius200,
                        borderBottomLeftRadius: theme.borders.radius200,
                        marginRight: "10px",
                      },
                    },
                    Arrow: {
                      style: {
                        backgroundColor: "white",
                      },
                    },
                  }}
                  content={({ close }) => (
                    <Block
                      padding="scale200"
                      backgroundColor="white"
                      display="flex"
                      alignItems="center"
                    >
                      <LabelSmall marginRight="8px">Czy na pewno?</LabelSmall>
                      <Button
                        size={SIZE.mini}
                        $style={{ backgroundColor: theme.colors.negative }}
                        type="button"
                        onClick={() => {
                          setTables(
                            tables?.filter(
                              (tableFromState) =>
                                tableFromState?.id !== table?.id
                            )
                          );
                          setItems(
                            items?.filter(
                              (item: InvoiceTableItem) =>
                                item?.table !== table?.id
                            )
                          );
                          setChanges((changes) => changes + 1);
                        }}
                      >
                        Tak, usuń
                      </Button>
                      <Button
                        size={SIZE.mini}
                        kind={KIND.secondary}
                        $style={{ marginLeft: "5px" }}
                        type="button"
                        onClick={() => close()}
                      >
                        Anuluj
                      </Button>
                    </Block>
                  )}
                  showArrow
                >
                  <ForwardedButton
                    size={SIZE.mini}
                    $style={{ backgroundColor: theme.colors.negative }}
                    type="button"
                    disabled={isLoading}
                  >
                    Usuń
                  </ForwardedButton>
                </StatefulPopover>
              ) : (
                <Button
                  size={SIZE.mini}
                  $style={{ backgroundColor: theme.colors.negative }}
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    setTables(
                      tables?.filter(
                        (tableFromState) => tableFromState?.id !== table?.id
                      )
                    );
                    setItems(
                      items?.filter(
                        (item: InvoiceTableItem) => item?.table !== table?.id
                      )
                    );
                  }}
                >
                  Usuń
                </Button>
              )}
            </Block>
          </div>
        );
      })}
      <Block display="flex" justifyContent="space-between" alignItems="center">
        <Button
          kind="secondary"
          size="compact"
          type="button"
          key="button"
          disabled={isLoading}
          onClick={() => {
            const newItemId = generateUUID();
            const newTableId = generateUUID();

            setItems([
              ...items,
              {
                id: newItemId,
                table: newTableId,
              },
            ]);

            setTables([
              ...tables,
              {
                id: newTableId,
              },
            ]);
          }}
          startEnhancer={<CirclePlus size={16} />}
          $style={{ fontWeight: 500 }}
        >
          Dodaj tabelę
        </Button>
      </Block>
    </>
  );
}

export function ControlledInvoiceTable({
  control,
  name,
  id,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & InvoiceTableProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          return value?.filter(
            (item: InvoiceTableItem) =>
              item &&
              (!item?.name || !item?.totalAmount || !item?.totalAmountWithTax)
          )?.length
            ? "Należy uzupełnić nazwy i kwoty wszystkich dodanych pozycji"
            : true;
        },
      }}
      render={({ field: { onChange, value } }) => {
        return (
          <InvoiceTable id={id} onChange={onChange} value={value} {...rest} />
        );
      }}
    />
  );
}
