import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  AlertOctagon,
  DeviceFloppy,
  Download,
  FileOff,
  Note,
} from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import { ControlledFilesPicker } from "../../../../components/files-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import GusDownloadModal from "../../../../components/gus-download-modal";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledAddressesSelect,
  ControlledCasesSelect,
  ControlledDictionaryValuesSelect,
  ControlledDocumentsSelect,
  ControlledDocumentTypesSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledOrganizationalUnitUsersSelect,
  ControlledUsersOrganizationalUnitsSelect,
  ControlledUsersSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import Tooltip from "../../../../components/tooltip";
import { LOCAL_STORAGE_VARIABLES } from "../../../../constants";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { Address } from "../../../Addresses/addresses";
import {
  ADDRESS_SUGGESTION,
  ADDRESS_SUGGESTIONS,
} from "../../../Addresses/addresses.gql";
import { DocumentInputs, DOCUMENTS_FIELDS } from "../../documents.form";
import { DOCUMENTS_CREATE } from "../../documents.gql";

export default function DocumentsCreateOutgoing(): React.ReactElement {
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(true);
  const [addressFromGus, setAddressFromGus] = useState<Address>();
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [isGusDownloadOpen, setIsGusDownloadOpen] = useState(false);
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, isLoading, setIsLoading } = useLoading();
  const { user } = useAuth();

  const time = new Date();

  const defaultCase = location.state as {
    id: number;
    name: string;
    number: string;
    beneficiaryAddress: Address;
  };

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    setValue,
    unregister,
    watch,
  } = useForm<DocumentInputs>({
    defaultValues: {
      documentType: undefined,
      documentNumber: "",
      description: "",
      caseName: undefined,
      senderWhenUser: undefined,
      organizationalUnit: undefined,
      recipientWhenAddress: undefined,
      addressName: "",
      addressStreetName: "",
      addressBuildingNumber: "",
      addressApartmentNumber: "",
      addressMobileNumber: "",
      addressCity: "",
      addressPostalCode: "",
      addressPhoneNumber: "",
      addressNip: "",
      addressCountryCode: undefined,
      addressEmail: "",
      addressOverwrite: false,
      addressIsSensitive: false,
    },
  });

  const watchAddress = watch("recipientWhenAddress");
  const watchOrganizationalUnit = watch("organizationalUnit");
  const watchSenderWhenUser = watch("senderWhenUser");
  const watchNip = watch("addressNip");

  const { refetch: fetchAddress } = useQuery(ADDRESS_SUGGESTIONS, {
    skip: true,
  });

  const [createDocument, { error, loading }] = useMutation(DOCUMENTS_CREATE);

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  const onSubmit = async ({
    description,
    documentType,
    documentDate,
    parentIds,
    senderWhenUser,
    caseName,
    organizationalUnit,
    documentNumber,
    files,
    name,
    additionalCode,
    programName,
    title,
    raksDiaryNumber,
  }: DocumentInputs): Promise<void> => {
    setIsLoading(true);

    localStorage.setItem(
      LOCAL_STORAGE_VARIABLES.LastRegisteredOutgoingDocument,
      documentType && documentType[0]?.id.toString()
    );

    try {
      const response = await createDocument({
        variables: {
          documentCreateInput: {
            parentIds: parentIds && parentIds.map(({ id }) => id),
            documentNumber,
            documentKind: "Outgoing",
            documentDate,
            description,
            name,
            programName,
            title,
            additionalCode,
            raksDiaryNumber,
            documentType: documentType && documentType[0]?.id,
            senderType: "User",
            senderId: senderWhenUser && senderWhenUser[0]?.id,
            caseId: caseName ? caseName[0]?.id : null,
            organizationalUnitId:
              organizationalUnit && organizationalUnit[0]?.id,
          },
          files,
        },
      });

      await enqueueSnackbar({
        message: "Zarejestrowano pomyślnie",
        variant: "success",
      });
      defaultCase
        ? history.push(`/cases/${defaultCase.id}`)
        : history.push(
            `/documents/${response?.data?.documentCreate?.documentKind?.toLowerCase()}/Document/${
              response?.data?.documentCreate?.id
            }`
          );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { refetch: revealAddress, loading: revealAddressLoading } = useQuery(
    ADDRESS_SUGGESTION,
    {
      skip: true,
    }
  );

  const onAddressRevealSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await revealAddress({
        id: watchAddress && watchAddress[0] && watchAddress[0].id,
      });

      const choosedAddress = response?.data?.addressSuggestion;
      if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
      if (choosedAddress?.name?.isSensitive)
        setValue("addressIsSensitive", choosedAddress?.name?.isSensitive);

      if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
      if (choosedAddress?.streetName)
        setValue("addressStreetName", choosedAddress?.streetName);
      if (choosedAddress?.buildingNumber)
        setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
      if (choosedAddress?.apartmentNumber)
        setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
      if (choosedAddress?.mobileNumber)
        setValue("addressMobileNumber", choosedAddress?.mobileNumber);
      if (choosedAddress?.city) setValue("addressCity", choosedAddress?.city);
      if (choosedAddress?.postalCode)
        setValue("addressPostalCode", choosedAddress?.postalCode);
      if (choosedAddress?.countryCode)
        setValue("addressCountryCode", [
          {
            id: choosedAddress?.countryCode,
          },
        ]);
      if (choosedAddress?.phoneNumber)
        setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
      if (choosedAddress?.email)
        setValue("addressEmail", choosedAddress?.email);

      setIsAnyAddressRevealed(true);
      enqueueSnackbar({
        message: "Odkryto pomyślnie",
        variant: "success",
      });

      setIsAddressRevealConfirmDialogOpen(false);
    } catch (error: unknown) {
      setIsAddressRevealConfirmDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("countryCodeSelect", [{ id: "PL" }]);

    const lastRegisteredDocumentType = localStorage.getItem(
      LOCAL_STORAGE_VARIABLES.LastRegisteredOutgoingDocument
    );
    lastRegisteredDocumentType &&
      setValue("documentType", [{ id: lastRegisteredDocumentType }]);
  }, []);

  useEffect(() => {
    if (errors) scrollOnError(errors, DOCUMENTS_FIELDS, "create");
  }, [errorTimeStamp]);

  useEffect(() => {
    setAddressFromGus(undefined);
    async function getAddressData() {
      setValue("addressName", "");
      setValue("addressNip", "");
      setValue("addressStreetName", "");
      setValue("addressBuildingNumber", "");
      setValue("addressApartmentNumber", "");
      setValue("addressMobileNumber", "");
      setValue("addressCity", "");
      setValue("addressPostalCode", "");
      setValue("addressPhoneNumber", "");
      setValue("addressEmail", "");
      setValue("addressIsSensitive", false);
      if (watchAddress && watchAddress[0] && watchAddress[0].id) {
        const response = await fetchAddress({
          filter: {
            id: {
              eq: watchAddress[0].id as number,
            },
          },
        });
        const choosedAddress = response?.data?.addressSuggestions?.nodes[0];
        if (choosedAddress?.name) setValue("addressName", choosedAddress?.name);
        if (watchAddress[0].typename === "HiddenAddress")
          setValue("addressIsSensitive", true);

        if (watchAddress[0].typename === "Address") {
          if (choosedAddress?.nip) setValue("addressNip", choosedAddress?.nip);
          if (choosedAddress?.streetName)
            setValue("addressStreetName", choosedAddress?.streetName);
          if (choosedAddress?.buildingNumber)
            setValue("addressBuildingNumber", choosedAddress?.buildingNumber);
          if (choosedAddress?.apartmentNumber)
            setValue("addressApartmentNumber", choosedAddress?.apartmentNumber);
          if (choosedAddress?.mobileNumber)
            setValue("addressMobileNumber", choosedAddress?.mobileNumber);
          if (choosedAddress?.city)
            setValue("addressCity", choosedAddress?.city);
          if (choosedAddress?.postalCode)
            setValue("addressPostalCode", choosedAddress?.postalCode);
          if (choosedAddress?.countryCode)
            setValue("addressCountryCode", [
              {
                id: choosedAddress?.countryCode,
              },
            ]);
          if (choosedAddress?.phoneNumber)
            setValue("addressPhoneNumber", choosedAddress?.phoneNumber);
          if (choosedAddress?.email)
            setValue("addressEmail", choosedAddress?.email);
        }

        unregister("addressName");
        unregister("addressStreetName");
        unregister("addressBuildingNumber");
        unregister("addressApartmentNumber");
        unregister("addressMobileNumber");
        unregister("addressPostalCode");
        unregister("addressCity");
        unregister("addressCountryCode");
      }
    }
    !addressFromGus && getAddressData();
    watchAddress?.[0]?.typename === "Address"
      ? setIsAnyAddressRevealed(true)
      : setIsAnyAddressRevealed(false);
  }, [watchAddress]);

  useEffect(() => {
    if (addressFromGus) setValue("recipientWhenAddress", undefined);
    if (addressFromGus?.nip) setValue("addressNip", addressFromGus?.nip);
    if (addressFromGus?.name) setValue("addressName", addressFromGus?.name);
    if (addressFromGus?.streetName)
      setValue("addressStreetName", addressFromGus?.streetName);
    if (addressFromGus?.buildingNumber)
      setValue("addressBuildingNumber", addressFromGus?.buildingNumber);
    if (addressFromGus?.apartmentNumber)
      setValue("addressApartmentNumber", addressFromGus?.apartmentNumber);
    if (addressFromGus?.mobileNumber)
      setValue("addressMobileNumber", addressFromGus?.mobileNumber);
    if (addressFromGus?.postalCode)
      setValue("addressPostalCode", addressFromGus?.postalCode);
    if (addressFromGus?.city) setValue("addressCity", addressFromGus?.city);
    if (addressFromGus?.countryCode)
      setValue("addressCountryCode", [{ id: addressFromGus?.countryCode }]);
    if (addressFromGus?.phoneNumber)
      setValue("addressPhoneNumber", addressFromGus?.phoneNumber);
    if (addressFromGus?.email) setValue("addressEmail", addressFromGus?.email);
  }, [addressFromGus]);

  useEffect(() => {
    if (defaultCase?.beneficiaryAddress)
      setValue(
        "recipientWhenAddress",
        defaultCase && [
          {
            ...defaultCase?.beneficiaryAddress,
            id: defaultCase?.beneficiaryAddress?.id,
            label: `${defaultCase?.beneficiaryAddress?.name}, ${defaultCase?.beneficiaryAddress?.address}, ${defaultCase?.beneficiaryAddress?.city}`,
          },
        ]
      );
    setValue(
      "caseName",
      defaultCase && [
        {
          ...defaultCase,
          label: `${defaultCase?.number} (${defaultCase?.name})`,
        },
      ]
    );
  }, [defaultCase]);

  useEffect(() => {
    if (user) {
      setValue("senderWhenUser", [
        {
          id: user.id,
          label: `${user.firstName} ${user.lastName}`,
        },
      ]);
    }
  }, []);

  if (!checkPermission(PERMISSIONS.document.create)) return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Rejestracja dokumentu wychodzącego"
        labels={["Dokumenty", "Rejestracja"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              !(Object.keys(dirtyFields).length <= 1)
                ? setIsCancelConfirmDialogOpen(true)
                : history.push("/documents"),
          },
          {
            label: "Zarejestruj",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "createOutgoingDocument",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            <FormControl label="Data rejestracji w systemie" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>{time.toLocaleDateString()}</FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form id="createOutgoingDocument" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {DOCUMENTS_FIELDS.filter(
              (g) => g.fields.filter((f) => f.create?.visible).length > 0
            )
              .filter(
                (g) =>
                  g.fields.filter((f) => f?.typeName?.includes("Outgoing"))
                    .length > 0
              )
              .map((group) => [
                group.label && (
                  <Cell key={group.id + `-group`} span={12}>
                    <>
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom="scale200"
                        marginTop="scale600"
                        height="20px"
                      >
                        <Block
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <LabelMedium>{group.label}</LabelMedium>
                          {watchAddress?.[0]?.typename === "HiddenAddress" &&
                            !isAnyAddressRevealed &&
                            group.id === "recipient" && (
                              <Button
                                size={SIZE.mini}
                                kind={KIND.secondary}
                                $style={{ marginLeft: "10px" }}
                                onClick={() =>
                                  setIsAddressRevealConfirmDialogOpen(true)
                                }
                                isLoading={revealAddressLoading}
                                type="button"
                              >
                                Pokaż dane
                              </Button>
                            )}
                        </Block>
                        {watchAddress?.[0]?.typename === "HiddenAddress" &&
                          !isAnyAddressRevealed &&
                          group.id === "recipient" && (
                            <FormattedValue
                              dataType="pre"
                              $style={{ fontSize: "12px" }}
                            >
                              Dane osobowe - podgląd zablokowany
                            </FormattedValue>
                          )}
                      </Block>
                      <hr
                        className={css({
                          borderWidth: "0px",
                          height: "1px",
                          backgroundColor: "#eee",
                        })}
                      />
                    </>
                  </Cell>
                ),
                group.fields
                  .filter((f) => f.create?.visible)
                  .filter((f) => f.typeName?.includes("Outgoing"))
                  .map((item, index) => (
                    <Cell
                      span={item.span || 6}
                      key={group.id + `-field` + index}
                    >
                      <FormControl
                        label={item.type === FIELDS.Checkbox ? "" : item.label}
                        caption={item.caption}
                        required={
                          item.info === "possiblyRequired"
                            ? isAnyAddressRevealed ||
                              !watchAddress?.length ||
                              watchAddress?.[0]?.typename === "Address"
                            : item.create.required
                        }
                        error={
                          ((errors as any)[item.id] &&
                            (errors as any)[item.id].message) ||
                          (error &&
                            error.graphQLErrors[0]?.extensions?.code ===
                              "INPUT_VALIDATION_ERROR" &&
                            error.graphQLErrors[0]?.extensions?.validationErrors
                              ?.find((vE: InputValidationError) =>
                                item.id.includes("address")
                                  ? vE?.property ===
                                    item.id.slice(7).toLowerCase()
                                  : vE?.property === item.id
                              )
                              ?.errors.map((message: string) => (
                                <div
                                  key="error"
                                  className={css({
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  })}
                                >
                                  {message}
                                  <AlertOctagon color="#999" size={12} />
                                </div>
                              ))[0])
                        }
                        disabled={isLoading}
                      >
                        {item.type === FIELDS.AddressesSelect ? (
                          <ControlledAddressesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.UsersSelect ? (
                          <ControlledUsersSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.CasesSelect ? (
                          <ControlledCasesSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={item.placeholder}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                          <ControlledOrganizationalUnitsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DocumentsSelect ? (
                          <ControlledDocumentsSelect
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder="Wybierz"
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type ===
                          FIELDS.UsersOrganizationalUnitsSelect ? (
                          watchSenderWhenUser &&
                          watchSenderWhenUser.length > 0 ? (
                            <ControlledUsersOrganizationalUnitsSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              objectId={watchSenderWhenUser[0].id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : (
                            <ControlledOrganizationalUnitsSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          )
                        ) : item.type ===
                          FIELDS.OrganizationalUnitUsersSelect ? (
                          watchOrganizationalUnit &&
                          watchOrganizationalUnit.length > 0 ? (
                            <ControlledOrganizationalUnitUsersSelect
                              objectId={watchOrganizationalUnit[0].id}
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : (
                            <ControlledUsersSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          )
                        ) : item.type === FIELDS.FilesPicker ? (
                          <ControlledFilesPicker
                            control={control}
                            name={item.id}
                            maxSize={134217728}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DictionaryValuesSelect ? (
                          <ControlledDictionaryValuesSelect
                            dictionarySystemName={
                              item.id === "addressCountryCode" ||
                              item.id === "countryCodeSelect"
                                ? "countryCodes.alpha2.pl"
                                : undefined
                            }
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={
                              watchAddress?.[0]?.typename === "HiddenAddress" &&
                              item.id.includes("address")
                                ? "**********"
                                : "Wybierz"
                            }
                            disabled={
                              item.id === "addressCountryCode" &&
                              !isAnyAddressRevealed &&
                              !!watchAddress?.length
                            }
                            {...((item.create.required ||
                              (item.info === "possiblyRequired" &&
                                isAnyAddressRevealed &&
                                item.id.includes("address") &&
                                item.id !== "addressName") ||
                              (item.info === "possiblyRequired" &&
                                item.id.includes("address") &&
                                item.id !== "addressName")) && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DocumentTypesSelect ? (
                          <ControlledDocumentTypesSelect
                            documentKind={"Outgoing"}
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={"Wybierz"}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.DatePicker ? (
                          <ControlledDatePicker
                            name={item.id}
                            control={control}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : item.type === FIELDS.Checkbox ? (
                          <div className={css({ marginTop: "40px" })}>
                            {item.id === "addressOverwrite" ? (
                              <Tooltip
                                placement="bottomLeft"
                                content={
                                  <p>
                                    Zaznaczenie tej opcji spowoduje aktualizację
                                    danych <br /> wybranego adresata w bazie,
                                    ale nie wpłynie to na <br /> wcześniejsze
                                    dokumenty powiązane z tym adresatem.
                                  </p>
                                }
                              >
                                <span>
                                  <ControlledCheckbox
                                    control={control}
                                    name={item.id}
                                    disabled={
                                      loading ||
                                      (!isAnyAddressRevealed &&
                                        !!watchAddress?.length &&
                                        item.id.includes("address") &&
                                        !item.id.includes("Name"))
                                    }
                                    {...(item.create.required && {
                                      rules: {
                                        required:
                                          formValidation.messages.required,
                                      },
                                    })}
                                  >
                                    {item.label}
                                  </ControlledCheckbox>
                                </span>
                              </Tooltip>
                            ) : (
                              <ControlledCheckbox
                                control={control}
                                name={item.id}
                                disabled={
                                  loading ||
                                  (!isAnyAddressRevealed &&
                                    !!watchAddress?.length &&
                                    item.id.includes("address") &&
                                    !item.id.includes("Name"))
                                }
                                {...(item.create.required && {
                                  rules: {
                                    required: formValidation.messages.required,
                                  },
                                })}
                              >
                                {item.label}
                              </ControlledCheckbox>
                            )}
                          </div>
                        ) : item.info === "possiblyRequired" &&
                          (!watchAddress?.length ||
                            watchAddress?.[0]?.typename === "Address" ||
                            isAnyAddressRevealed) ? (
                          <ControlledInput
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={
                              !isAnyAddressRevealed &&
                              !!watchAddress?.length &&
                              item.id.includes("address") &&
                              !item.id.includes("Name")
                                ? "**********"
                                : item.placeholder
                            }
                            rules={{
                              required: formValidation.messages.required,
                            }}
                            {...(item.id === "addressEmail" && {
                              rules: {
                                pattern: {
                                  value: formValidation.patterns.email,
                                  message:
                                    formValidation.messages.incorrectEmail,
                                },
                              },
                            })}
                            masked={item.mask !== undefined}
                            mask={item.mask}
                            maskChar="_"
                            disabled={
                              !isAnyAddressRevealed &&
                              !!watchAddress?.length &&
                              item.id.includes("address")
                            }
                            endEnhancer={
                              item.id === "addressNip" ? (
                                <Tooltip content="Pobierz z GUS">
                                  <span
                                    onClick={() => setIsGusDownloadOpen(true)}
                                    className={css({ cursor: "pointer" })}
                                  >
                                    <Download
                                      size={16}
                                      className={css({
                                        verticalAlign: "middle",
                                        marginLeft: "6px",
                                        display: "inline",
                                      })}
                                    />
                                  </span>
                                </Tooltip>
                              ) : (
                                item.endEnhancer
                              )
                            }
                          />
                        ) : item.type === FIELDS.TextArea ? (
                          <ControlledTextArea
                            control={control}
                            name={item.id}
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                          />
                        ) : (
                          <ControlledInput
                            control={control}
                            name={item.id}
                            id={item.id}
                            placeholder={
                              !isAnyAddressRevealed &&
                              !!watchAddress?.length &&
                              item.id.includes("address") &&
                              !item.id.includes("Name")
                                ? "**********"
                                : item.placeholder
                            }
                            {...(item.create.required && {
                              rules: {
                                required: formValidation.messages.required,
                              },
                            })}
                            {...(item.id === "addressEmail" && {
                              rules: {
                                pattern: {
                                  value: formValidation.patterns.email,
                                  message:
                                    formValidation.messages.incorrectEmail,
                                },
                              },
                            })}
                            masked={item.mask !== undefined}
                            mask={item.mask}
                            maskChar="_"
                            disabled={
                              !isAnyAddressRevealed &&
                              !!watchAddress?.length &&
                              item.id.includes("address")
                            }
                            endEnhancer={
                              item.id === "addressNip" ? (
                                <Tooltip content="Pobierz z GUS">
                                  <span
                                    onClick={() => setIsGusDownloadOpen(true)}
                                    className={css({ cursor: "pointer" })}
                                  >
                                    <Download
                                      size={16}
                                      className={css({
                                        verticalAlign: "middle",
                                        marginLeft: "6px",
                                        display: "inline",
                                      })}
                                    />
                                  </span>
                                </Tooltip>
                              ) : (
                                item.endEnhancer
                              )
                            }
                            {...(item.type === FIELDS.NumberInput && {
                              type: "number",
                              min: 0,
                            })}
                          />
                        )}
                      </FormControl>
                    </Cell>
                  )),
              ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie nadania dokumentu wychodzącego"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => {
                defaultCase
                  ? history.push(`/cases/${defaultCase.id}`)
                  : history.push("/documents");
              }}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setIsClearConfirmDialogOpen(false);
              }}
            />
            <ConfirmDialog
              isOpen={isAddressRevealConfirmDialogOpen}
              type={ConfirmDialogType.AddressReveal}
              close={() => setIsAddressRevealConfirmDialogOpen(false)}
              confirm={() => onAddressRevealSubmit()}
            />
            <GusDownloadModal
              isOpen={isGusDownloadOpen}
              close={() => setIsGusDownloadOpen(false)}
              confirm={() => null}
              currentNip={watchNip}
              setAddressFromGus={setAddressFromGus}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
