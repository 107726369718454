import { DOCUMENT_TYPES } from "../../../constants";
import { Field, FIELDS, FieldsGroup } from "../../../fields.d";
import { BankAccount, BankAccountType } from "../../Addresses/addresses";
import { Document, FinancialAccountingDocumentItem } from "../documents";

export type FinancialAccountingDocumentInputs = {
  documentNumber: string;
  name: string;
  dateFromDocument: string;
  description: string;
  title?: string;
  programName?: string;
  documentDate: Date | null;
  inflowDate: Date | null;
  parentIds?: Document[];
  postStampAt: Date | null;
  handDelivered: boolean;
  senderWhenAddress?: { id: number; label?: string; typename?: string }[];
  recipientWhenUser?: { id: number; label?: string }[];
  caseName: { id: number; label?: string }[];
  organizationalUnit: { id: number; label?: string }[];
  paymentAt: Date | null;
  netValue: number;
  grossValue: number;
  files?: FileList;
  addressName?: string;
  addressAddress?: string;
  addressStreetName?: string;
  addressBuildingNumber?: string;
  addressApartmentNumber?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressPhoneNumber?: string;
  addressMobileNumber?: string;
  addressEmail?: string;
  addressNip?: string;
  addressOverwrite?: boolean;
  addressIsSensitive?: boolean;
  addressCountryCode: { id: string; label?: string }[];
  addressBankAccount: ({ id: number; label?: string } & BankAccount)[];
  payeeName?: string;
  currencies?: {
    id: string;
    label?: string;
    code?: string;
    currencyId?: string;
  }[];
  currencyId?: number;
  exchangeRates?: { id: string; label?: string; exchangeRate?: number }[];
  amountInCurrency?: number;
  accountNumber?: string;
  bankName?: string;
  accountType?: { id: BankAccountType; label?: string }[];
  bankCode?: string;
  financialAccountingDocumentItems: FinancialAccountingDocumentItem[];
  invoiceTypes?: { id: string; label?: string }[];
  invoiceType?: string;
  paymentTypes?: { id: string; label?: string }[];
  paymentType?: string;
  raksDiaryNumber?: string;
  accountCurrency?: { id?: string; label?: string; code?: string }[];
};

export type AssignInvoiceFormInputs = {
  requestFormId?: number;
  requestForm?: { id: number; label?: string }[];
  requestFormItemId?: number;
  requestFormItem?: { id: number; label?: string }[];
  financialAccountingDocumentItemId?: number;
  financialAccountingDocumentItem?: { id: number; label?: string }[];
  financialAccountingDocumentId: number;
};

export type LinkFormInputs = {
  parentIds: Document[];
};

export const FINANCIAL_ACCOUNTING_DOCUMENTS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "documentNumber",
        label: "Numer dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "name",
        label: "Tytuł dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: true },
      },
      {
        id: "documentType",
        label: "Typ dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "invoiceType",
        label: "Typ faktury",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        documentType: [DOCUMENT_TYPES.Invoice],
      },
      {
        id: "invoiceTypes",
        label: "Typ faktury",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        documentType: [DOCUMENT_TYPES.Invoice],
      },
      {
        id: "state",
        label: "Stan dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "document-state",
      },
      {
        id: "createdAt",
        label: "Data rejestracji w systemie",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdBy",
        label: "Autor",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "responsible",
        label: "Odpowiedzialny",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "documentDate",
        label: "Data wystawienia dokumentu",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },

      {
        id: "inflowDate",
        label: "Data wpływu",
        type: FIELDS.DatePicker,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "postStampAt",
        label: "Data stempla pocztowego",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "handDelivered",
        label: "Dostarczony osobiście",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "handDelivered",
        label: "Dostarczony osobiście",
        type: FIELDS.Input,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "paymentAt",
        label: "Termin płatności",
        type: FIELDS.DatePicker,
        dataType: "date",
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "raksDiaryNumber",
        label: "Numer dziennika RAKS",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "rejestrId",
        label: "Numer dokumentu w systemie Rejestr",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "sender",
    label: "Nadawca",
    fields: [
      {
        id: "senderWhenAddress",
        label: "Wybór z bazy adresowej",
        type: FIELDS.AddressesSelect,
        span: 8,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        placeholder: "Wybierz",
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        mask: "9999999999",
        placeholder: "9999999999",
        dataType: "nip",
        additionalData: "sensitive",
      },
      {
        id: "addressName",
        label: "Nazwa nadawcy",
        type: FIELDS.Input,
        span: 9,
        show: { accessor: ["sender", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "addressName",
        label: "Nazwa nadawcy",
        type: FIELDS.Input,
        span: 6,
        info: "possiblyRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        additionalData: "sensitive",
      },
      {
        id: "addressNip",
        label: "NIP",
        type: FIELDS.Input,
        span: 3,
        info: "possiblyRequired",
        show: { accessor: ["sender", "nip"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "nip",
        additionalData: "sensitive",
      },
      {
        id: "addressIsSensitive",
        label: "Dane osobowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        info: "possiblyRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        dataType: "countryCode",
        additionalData: "sensitive",
      },
      {
        id: "addressStreetName",
        label: "Ulica",
        type: FIELDS.Input,
        span: 3,
        info: "possiblyRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        additionalData: "sensitive",
      },
      {
        id: "addressBuildingNumber",
        label: "Numer budynku",
        type: FIELDS.Input,
        span: 2,
        info: "possiblyRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        additionalData: "sensitive",
      },
      {
        id: "addressApartmentNumber",
        label: "Numer mieszkania",
        type: FIELDS.Input,
        span: 2,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        additionalData: "sensitive",
      },
      {
        id: "addressAddress",
        label: "Ulica i numer",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        additionalData: "sensitive",
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 2,
        info: "possiblyRequired",
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        additionalData: "sensitive",
      },
      {
        id: "addressPostalCode",
        label: "Kod pocztowy",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "postalCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        additionalData: "sensitive",
      },
      {
        id: "addressCity",
        label: "Miejscowość",
        type: FIELDS.Input,
        span: 3,
        info: "possiblyRequired",
        show: { accessor: ["sender", "city"], visible: true },
        create: { visible: true },
        edit: { visible: true },
        additionalData: "sensitive",
      },
      {
        id: "addressCountryCode",
        label: "Kraj",
        type: FIELDS.DictionaryValuesSelect,
        span: 3,
        show: { accessor: ["sender", "countryCode"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "countryCode",
        additionalData: "sensitive",
      },
      {
        id: "addressPhoneNumber",
        label: "Numer telefonu",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "phoneNumber"], visible: true },
        create: { visible: true },
        edit: { visible: true },
        mask: "99 999-99-99",
        placeholder: "99 999-99-99",
        additionalData: "sensitive",
      },
      {
        id: "addressMobileNumber",
        label: "Numer telefonu kom.",
        type: FIELDS.Input,
        span: 3,
        show: { accessor: ["sender", "mobileNumber"], visible: true },
        create: { visible: true },
        edit: { visible: true },
        mask: "+99 999-999-999",
        placeholder: "+99 999-999-999",
        additionalData: "sensitive",
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        dataType: "email",
        additionalData: "sensitive",
      },
      {
        id: "addressEmail",
        label: "Adres e-mail",
        type: FIELDS.Input,
        span: 6,
        show: { accessor: ["sender", "email"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "email",
        additionalData: "sensitive",
      },
      {
        id: "addressOverwrite",
        label: "Nadpisz dane adresowe",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "addressBankAccount",
        label: "Konto bankowe",
        type: FIELDS.BankAccountsSelect,
        span: 12,
        show: { visible: false },
        edit: { visible: true },
        create: { visible: true },
        placeholder: "Wybierz",
      },
      {
        id: "payeeName",
        label: "Właściciel rachunku",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true, accessor: ["bankAccount", "payeeName"] },
        edit: { visible: true, required: true },
        create: { visible: true, required: true },
      },
      {
        id: "accountNumber",
        label: "Numer rachunku",
        type: FIELDS.Input,
        span: 6,
        dataType: "bank-accounts",
        show: { visible: true, accessor: ["bankAccount", "accountNumber"] },
        edit: { visible: true, required: true },
        create: { visible: true, required: true },
        placeholder: "XX 99 9999 9999 9999 9999 9999 9999 9999",
        caption: "Wskazówka: XX — kod kraju",
      },
      {
        id: "bankName",
        label: "Nazwa banku",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true, accessor: ["bankAccount", "bankName"] },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "bankCode",
        label: "Kod SWIFT/BIC",
        caption: `Link pomocniczny: <a target="_blank" href="https://www2.swift.com/bsl/">Wyszukiwarka kodów</a>`,
        type: FIELDS.Input,
        span: 3,
        show: { visible: true, accessor: ["bankAccount", "bankCode"] },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "accountType",
        label: "Typ konta",
        dataType: "account-type",
        caption: `Link pomocniczny: <a target="_blank" href="https://pl.wikipedia.org/wiki/Mi%C4%99dzynarodowy_numer_rachunku_bankowego">IBAN</a>`,
        type: FIELDS.BankAccountTypesSelect,
        span: 3,
        show: { visible: true, accessor: ["bankAccount", "accountType"] },
        edit: { visible: true },
        create: { visible: true },
      },
      {
        id: "accountCurrency",
        label: "Waluta",
        type: FIELDS.CurrencySelect,
        span: 3,
        show: { visible: true, accessor: ["bankAccount", "currency"] },
        edit: { visible: true },
        create: { visible: true },
      },
    ],
  },
  {
    id: "recipient",
    label: "Odbiorca",
    fields: [
      {
        id: "organizationalUnit",
        label: "Jednostka organizacyjna",
        type: FIELDS.UsersOrganizationalUnitsSelect,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        placeholder: "Wybierz",
      },
      {
        id: "organizationalUnit",
        label: "Jednostka organizacyjna",
        type: FIELDS.Input,
        span: 6,
        show: {
          accessor: ["organizationalUnit", "name"],
          visible: true,
        },
        create: { visible: false },
        edit: { visible: false },
        placeholder: "Wybierz",
        dataType: "model:organizational-units",
      },
      // {
      //   id: "recipientWhenUser",
      //   label: "Pracownik",
      //   type: FIELDS.OrganizationalUnitUsersSelect,
      //   span: 4,
      //   show: { visible: false },
      //   create: { visible: true },
      //   edit: { visible: true },
      //   typeName: ["Incoming"],
      // },
      // {
      //   id: "recipientWhenUser",
      //   label: "Pracownik",
      //   type: FIELDS.Input,
      //   span: 4,
      //   show: { accessor: ["recipient", "firstName"], visible: true },
      //   create: { visible: false },
      //   edit: { visible: false },
      //   dataType: "model:users",
      //   typeName: ["Incoming"],
      // },
    ],
  },
  {
    id: "case",
    label: "Znak sprawy",
    fields: [
      // {
      //   id: "caseName",
      //   label: "",
      //   type: FIELDS.CasesSelect,
      //   span: 12,
      //   show: { visible: false },
      //   create: { visible: true },
      //   edit: { visible: true },
      //   placeholder: "Wybierz",
      //   typeName: ["Incoming"],
      //   caption:
      //     "W tym polu należy wybrać odpowiedni znak sprawy z jaką zostanie powiązany dokument. Nie jest to obowiązkowe na etapie rejestracji dokumentu w recepcji",
      // },
      {
        id: "case",
        label: "",
        type: FIELDS.Input,
        span: 12,
        show: { accessor: ["case", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:cases",
        additionalData: "isConductedElectronically",
      },
    ],
  },
  {
    id: "other",
    label: "Pozostałe informacje",
    fields: [
      {
        id: "title",
        label: "Temat",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "programName",
        label: "Program",
        type: FIELDS.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "description",
        label: "Opis",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "legalAct",
        label: "Podstawa prawna",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "parent",
    label: "Dokumenty nadrzędne",
    fields: [
      {
        id: "parentIds",
        label: "Dokumenty nadrzędne",
        type: FIELDS.DocumentsSelect,
        dataType: "model:documents",
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "financeFields",
    label: "Pola finansowe",
    fields: [
      {
        id: "grossValue",
        label: "Kwota brutto",
        type: FIELDS.NumberInput,
        dataType: "quota",
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        endEnhancer: "PLN",
      },
      {
        id: "paymentType",
        label: "Typ płatności",
        type: FIELDS.Input,
        placeholder: "Wybierz",
        dataType: "payment-type",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "paymentTypes",
        label: "Typ płatności",
        type: FIELDS.PaymentTypesSelect,
        placeholder: "Wybierz",
        span: 3,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "exchangeRate",
        label: "Kurs",
        type: FIELDS.Input,
        dataType: "exchange-rate",
        span: 3,
        show: { visible: true, accessor: ["currencyExchangeRate", "value"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "currencies",
        label: "Waluta",
        type: FIELDS.CurrencySelect,
        placeholder: "PLN",
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "exchangeRates",
        label: "Kurs",
        type: FIELDS.CurrencyExchangeRatesSelect,
        span: 3,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "financialAccountingDocumentItems",
        label: "Pozycje dokumentu",
        type: FIELDS.InvoiceTable,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "files",
    label: "Załączniki",
    fields: [
      {
        id: "filesList",
        label: "",
        type: FIELDS.FilesList,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        caption:
          "Kliknięcie ikony kosza spowoduje usunięcia załącznika po zapisaniu dokumentu",
      },
      {
        id: "files",
        label: "",
        type: FIELDS.FilesPicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        caption: "Maksymalny rozmiar pliku to 128 MB.",
      },
    ],
  },
];

export const ASSIGN_FINANCIAL_ACCOUNTING_DOCUMENTS_FIELDS: Field[] = [
  {
    id: "requestForm",
    label: "Wniosek",
    type: FIELDS.DocumentsSelect,
    placeholder: "Wybierz",
    span: 6,
    show: { visible: false },
    edit: { visible: false },
    create: { visible: false },
  },
  {
    id: "requestFormItem",
    label: "Pozycja wniosku",
    type: FIELDS.RequestFormItemsSelect,
    placeholder: "Wybierz",
    span: 6,
    show: { visible: false },
    edit: { visible: false },
    create: { visible: false },
  },
];
