import { Option } from "baseui/select";
import { format } from "date-fns/esm";

import { FILTERS, FiltersState, SingleFilter } from "../filters.d";

const TEXT_FILTERS = [FILTERS.Text, FILTERS.FullName, FILTERS.LoginAd];

function translateFilterToObject(
  instance: SingleFilter
): Record<string, unknown> {
  const valueInPLNFields = ["grossValue", "grossValueInPLN", "totalValue"];

  let nameFilters;

  switch (instance.filter.type) {
    case FILTERS.Date:
      switch (instance.state.kind) {
        case "between":
          return {
            [instance.filter.id]: {
              [!instance.state.not ? "between" : "notBetween"]: {
                lower: new Date(instance.state.from),
                upper: new Date(+new Date(instance.state.to) + 86400000),
              },
            },
          };

        default:
        case "single":
          return {
            [instance.filter.id]: {
              [!instance.state.not ? "between" : "notBetween"]: {
                lower: new Date(instance.state.in),
                upper: new Date(+new Date(instance.state.in) + 86400000),
              },
            },
          };
      }

    case FILTERS.Boolean:
    case FILTERS.CaseConduct:
      return {
        [instance.filter.id]: {
          [!instance.state.not ? "is" : "isNot"]: instance.state.is,
        },
      };

    case FILTERS.Text:
      return {
        [instance.filter.id]: {
          iLike: `%${instance.state.iLike}%`,
        },
      };

    case FILTERS.DictionaryValues:
      return {
        [instance.filter.id]: {
          iLike: `%${instance.state.value}%`,
        },
      };

    case FILTERS.AssignmentStatus:
    case FILTERS.DocumentTypes:
      return {
        or: instance.state.value.map((value: Option) => ({
          [instance.filter.id]: {
            eq: `${value.id}`,
          },
        })),
      };

    case FILTERS.AssignmentType:
    case FILTERS.DocumentKinds:
      return {
        [instance.filter.id]: {
          eq: `${instance.state.value}`,
        },
      };

    case FILTERS.Document:
      return {
        [instance.filter.id]: {
          eq: instance.state.value,
        },
      };

    case FILTERS.PrimaryAssignable:
      return instance.state.value
        ? {
            and: [
              {
                primaryAssignableType: {
                  eq: `${instance.state.type}`,
                },
              },
              {
                primaryAssignableId: {
                  eq: instance.state.value,
                },
              },
            ],
          }
        : {
            primaryAssignableType: {
              eq: `${instance.state.type}`,
            },
          };

    case FILTERS.DocumentPickupState:
      return {
        [instance.filter.id]: {
          eq: `${instance.state.value}`,
        },
      };

    case FILTERS.DocumentStates:
      return {
        [instance.filter.id]: {
          eq: `${instance.state.value}`,
        },
      };

    case FILTERS.OrganizationalUnit:
      return {
        organizationalUnitId: {
          eq: parseInt(instance.state.value),
        },
      };

    case FILTERS.User:
      return {
        [instance.filter.id]: {
          eq: parseInt(instance.state.value),
        },
      };

    case FILTERS.Substitutee:
      return {
        substituteeId: {
          eq: parseInt(instance.state.value),
        },
      };

    case FILTERS.Substituter:
      return {
        substituterId: {
          eq: parseInt(instance.state.value),
        },
      };

    case FILTERS.Case:
      return {
        caseId: {
          eq: parseInt(instance.state.value),
        },
      };

    case FILTERS.CaseStatus:
      return {
        closedAt: {
          [instance.state.is ? "is" : "isNot"]: null,
        },
      };

    case FILTERS.HasCaseAssigned:
      return {
        caseId: {
          [instance.state.is ? "isNot" : "is"]: null,
        },
      };

    case FILTERS.Role:
      return {
        roles: {
          id: {
            eq: parseInt(instance.state.value),
          },
        },
      };

    case FILTERS.Amount:
      switch (instance.state.kind) {
        case "between":
          return {
            and: [
              ...(instance.state.from
                ? [
                    {
                      [instance.filter.id]: {
                        [!instance.state.not
                          ? instance.state.gtOrEq
                            ? "gte"
                            : "gt"
                          : instance.state.gtOrEq
                          ? "lt"
                          : "lte"]: parseFloat(instance.state.from),
                      },
                    },
                  ]
                : []),
              ...(instance.state.to
                ? [
                    {
                      [instance.filter.id]: {
                        [!instance.state.not
                          ? instance.state.ltOrEq
                            ? "lte"
                            : "lt"
                          : instance.state.ltOrEq
                          ? "gt"
                          : "gte"]: parseFloat(instance.state.to),
                      },
                    },
                  ]
                : []),
            ],
          };

        default:
        case "single":
          return {
            [instance.filter.id]: {
              [!instance.state.not ? "eq" : "neq"]: parseFloat(
                instance.state.eq
              ),
            },
          };
      }

    case FILTERS.DocumentValueInPLN:
      switch (instance.state.kind) {
        case "between":
          return {
            or: valueInPLNFields.map((value) => ({
              and: [
                ...(instance.state.from
                  ? [
                      {
                        [value]: {
                          [!instance.state.not
                            ? instance.state.gtOrEq
                              ? "gte"
                              : "gt"
                            : instance.state.gtOrEq
                            ? "lt"
                            : "lte"]: parseFloat(instance.state.from),
                        },
                      },
                    ]
                  : []),
                ...(instance.state.to
                  ? [
                      {
                        [value]: {
                          [!instance.state.not
                            ? instance.state.ltOrEq
                              ? "lte"
                              : "lt"
                            : instance.state.ltOrEq
                            ? "gt"
                            : "gte"]: parseFloat(instance.state.to),
                        },
                      },
                    ]
                  : []),
                {
                  currencyId: {
                    is: null,
                  },
                },
              ],
            })),
          };

        default:
        case "single":
          return {
            and: [
              {
                or: valueInPLNFields.map((value) => ({
                  [value]: {
                    [!instance.state.not ? "eq" : "neq"]: parseFloat(
                      instance.state.eq
                    ),
                  },
                })),
              },
              {
                currencyId: {
                  is: null,
                },
              },
            ],
          };
      }

    case FILTERS.Number:
      return {
        [instance.filter.id]: {
          eq: parseInt(instance.state.eq),
        },
      };

    case FILTERS.Activity:
    case FILTERS.ActivityLogsSubjectType:
      return {
        [instance.filter.id]: {
          like: instance.state.value,
        },
      };

    case FILTERS.DocumentValueInCurrency:
      switch (instance.state.kind) {
        case "between":
          return {
            and: [
              ...(instance.state.from
                ? [
                    {
                      grossValue: {
                        [!instance.state.not
                          ? instance.state.gtOrEq
                            ? "gte"
                            : "gt"
                          : instance.state.gtOrEq
                          ? "lt"
                          : "lte"]: parseFloat(instance.state.from),
                      },
                    },
                  ]
                : []),
              ...(instance.state.to
                ? [
                    {
                      grossValue: {
                        [!instance.state.not
                          ? instance.state.ltOrEq
                            ? "lte"
                            : "lt"
                          : instance.state.ltOrEq
                          ? "gt"
                          : "gte"]: parseFloat(instance.state.to),
                      },
                    },
                  ]
                : []),
              {
                currencyId: {
                  isNot: null,
                },
              },
            ],
          };

        default:
        case "single":
          return {
            and: [
              {
                grossValue: {
                  [!instance.state.not ? "eq" : "neq"]: parseFloat(
                    instance.state.eq
                  ),
                },
              },
              {
                currencyId: {
                  isNot: null,
                },
              },
            ],
          };
      }

    case FILTERS.FullName:
      if (instance.state.iLike.split(" ").length === 1) {
        nameFilters = {
          or: [
            {
              firstName: {
                iLike: `%${instance.state.iLike.split(" ")[0]}%`,
              },
            },
            {
              lastName: {
                iLike: `%${instance.state.iLike.split(" ")[0]}%`,
              },
            },
          ],
        };
      } else {
        let array: any = [];

        instance.state.iLike.split(" ").forEach((a: string) => {
          const rest = instance.state.iLike
            .split(" ")
            .filter((b: string) => b !== a);

          rest.forEach((b: string) => {
            array = [
              ...array,
              {
                and: [
                  {
                    firstName: {
                      iLike: `%${a}%`,
                    },
                  },
                  {
                    lastName: {
                      iLike: `%${b}%`,
                    },
                  },
                ],
              },
            ];
          });
        });

        nameFilters = { or: array };
      }

      return nameFilters;

    case FILTERS.LoginAd:
      return {
        users: {
          loginAD: {
            iLike: `%${instance.state.iLike}%`,
          },
        },
      };

    default:
      return {
        [instance.filter.id]: {},
      };
  }
}

export function translateFiltersState(state: FiltersState): unknown {
  let object = {};

  state
    .filter((instance) => !TEXT_FILTERS.includes(instance.filter.type))
    .forEach((instance) => {
      if (Object.keys(object).length === 0) {
        object = {
          ...translateFilterToObject(instance),
        };
      } else {
        object = {
          ...translateFilterToObject(instance),
          and: object,
        };
      }
    });

  const textFilters = state
    .filter((instance) => TEXT_FILTERS.includes(instance.filter.type))
    .map((instance) => translateFilterToObject(instance));

  if (textFilters.length) {
    object = {
      or: textFilters,
      ...(Object.keys(object).length !== 0 && { and: object }),
    };
  }

  return object;
}

export function translateFilterToString(instance: SingleFilter): string {
  let string = `${instance.filter.label}: `;

  switch (instance.filter.type) {
    case FILTERS.Date:
      switch (instance.state.kind) {
        case "between":
          string += `${format(new Date(instance.state.from), "dd.MM.yyyy")} 
          — ${format(new Date(instance.state.to), "dd.MM.yyyy")}`;
          break;

        default:
        case "single":
          string += `${format(new Date(instance.state.in), "dd.MM.yyyy")}`;
          break;
      }
      break;

    case FILTERS.Boolean:
      string += instance.state.is ? "Tak" : "Nie";
      break;

    case FILTERS.DictionaryValues:
    case FILTERS.AssignmentType:
    case FILTERS.ActivityLogsSubjectType:
    case FILTERS.Activity:
      string += instance.state.label;
      break;

    case FILTERS.AssignmentStatus:
    case FILTERS.DocumentTypes:
      string += instance.state.value
        .map((value: Option) => value.label)
        .join(", ");
      break;

    case FILTERS.DocumentKinds:
      string += instance.state.label;
      break;

    case FILTERS.DocumentPickupState:
      string += instance.state.label;
      break;

    case FILTERS.DocumentStates:
      string += instance.state.label;
      break;

    case FILTERS.OrganizationalUnit:
      string += instance.state.label;
      break;

    case FILTERS.CaseStatus:
      string += instance.state.is ? "Otwarta" : "Zamknięta";
      break;

    case FILTERS.HasCaseAssigned:
      string += instance.state.is ? "Tak" : "Nie";
      break;

    case FILTERS.CaseConduct:
      string += instance.state.is ? "Elektronicznie" : "Tradycyjnie";
      break;

    case FILTERS.Role:
      string += instance.state.label;
      break;

    case FILTERS.User:
      string += instance.state.firstName + " " + instance.state.lastName;
      break;

    case FILTERS.Substitutee:
      string += instance.state.firstName + " " + instance.state.lastName;
      break;

    case FILTERS.Substituter:
      string += instance.state.firstName + " " + instance.state.lastName;
      break;

    case FILTERS.Case:
      string += instance.state.number;
      break;

    case FILTERS.PrimaryAssignable:
      string +=
        (instance.state.type === "Case" ? "Sprawa" : "Dokument") +
        (instance.state.value
          ? ` (${
              instance.state.type === "Case"
                ? instance.state.number
                : instance.state.internalNumber || instance.state.number
            })`
          : "");
      break;

    case FILTERS.Document:
      string += instance.state.internalNumber;
      break;

    case FILTERS.DocumentValueInPLN:
    case FILTERS.Amount:
      switch (instance.state.kind) {
        case "between":
          string += `${
            instance.state.from ? (instance.state.gtOrEq ? "≥" : ">") : ""
          } 
          ${
            instance.state.from
              ? new Intl.NumberFormat("pl-PL", {
                  minimumFractionDigits: 2,
                }).format(parseFloat(instance.state.from))
              : ""
          } ${instance.state.to ? (instance.state.ltOrEq ? "≤" : "<") : ""}  ${
            instance.state.to
              ? new Intl.NumberFormat("pl-PL", {
                  minimumFractionDigits: 2,
                }).format(parseFloat(instance.state.to))
              : ""
          }`;
          break;

        default:
        case "single":
          string += `${
            instance.state.eq &&
            new Intl.NumberFormat("pl-PL", {
              minimumFractionDigits: 2,
            }).format(parseFloat(instance.state.eq))
          }`;
          break;
      }
      break;

    case FILTERS.Number:
      string += instance.state.eq;
      break;

    case FILTERS.DocumentValueInCurrency:
      switch (instance.state.kind) {
        case "between":
          string += `${
            instance.state.from ? (instance.state.gtOrEq ? "≥" : ">") : ""
          } 
          ${
            instance.state.from
              ? new Intl.NumberFormat("pl-PL", {
                  minimumFractionDigits: 2,
                }).format(parseFloat(instance.state.from))
              : ""
          } ${instance.state.to ? (instance.state.ltOrEq ? "≤" : "<") : ""}  ${
            instance.state.to
              ? new Intl.NumberFormat("pl-PL", {
                  minimumFractionDigits: 2,
                }).format(parseFloat(instance.state.to))
              : ""
          }`;
          break;

        default:
        case "single":
          string += `${
            instance.state.eq &&
            new Intl.NumberFormat("pl-PL", {
              minimumFractionDigits: 2,
            }).format(parseFloat(instance.state.eq))
          }`;
          break;
      }
      break;

    default:
      break;
  }

  return string;
}
