import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { SUBSTITUTION_TOKEN } from "../constants";
import {
  AVAILABLE_SUBSTITUTEES_INDEX,
  CURRENT_SUBSTITUTION,
} from "../containers/MySubstitutions/my-substitutions.gql";
import { Substitution } from "../containers/Substitutions/substitutions";
import { SUBSTITUTION_START } from "../containers/Substitutions/substitutions.gql";
import { User } from "../containers/Users/users";
import { useAuth } from "./auth-context";

type SubstitutionContextProps = {
  substitution?: Substitution;
  setSubstitution: (substitution: Substitution | undefined) => void;
  isSubstitutionActive: boolean;
  setIsSubstitutionActive: (isSubstitutionActive: boolean) => void;
  availableSubstitutees?: User[];
  startSubstitution: (substituteeId: number) => void;
  endSubstitution: () => void;
};

export const SubstitutionContext = createContext<SubstitutionContextProps>(
  {} as SubstitutionContextProps
);

export function SubstitutionProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [substitution, setSubstitution] = useState<Substitution>();
  const [isSubstitutionActive, setIsSubstitutionActive] = useState(
    !!localStorage.getItem(SUBSTITUTION_TOKEN)
  );
  const [availableSubstitutees, setAvailableSubstitutees] = useState<User[]>();

  const { refetchNewAssignments } = useAuth();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const { data, refetch } = useQuery(AVAILABLE_SUBSTITUTEES_INDEX, {
    variables: {
      pageSize: 500,
      offset: 0,
    },
    onCompleted({ availableSubstitutees }) {
      setAvailableSubstitutees(availableSubstitutees.nodes);
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: currentSubstitutionData,
    refetch: currentSubstitutionRefetch,
  } = useQuery(CURRENT_SUBSTITUTION, {
    onCompleted({ currentSubstitution }) {
      setSubstitution(currentSubstitution);
      if (currentSubstitution) setIsSubstitutionActive(true);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) refetch();
    if (currentSubstitutionData) currentSubstitutionRefetch();
  }, []);

  const [start] = useMutation(SUBSTITUTION_START);

  const startSubstitution = async (substituteeId: number): Promise<void> => {
    try {
      const { data } = await start({
        variables: {
          substitutionStartInput: {
            substituteeId: substituteeId,
          },
        },
      });

      currentSubstitutionRefetch();

      refetchNewAssignments();

      localStorage.setItem(
        SUBSTITUTION_TOKEN,
        data?.substitutionStart?.substitutionToken
      );

      history.push("/folders/my");
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    }
  };

  const endSubstitution = () => {
    setIsSubstitutionActive(false);
    setSubstitution(undefined);

    localStorage.removeItem(SUBSTITUTION_TOKEN);

    refetchNewAssignments();

    history.push("/folders/my");
  };

  return (
    <SubstitutionContext.Provider
      value={{
        substitution,
        setSubstitution,
        isSubstitutionActive,
        setIsSubstitutionActive,
        availableSubstitutees,
        startSubstitution,
        endSubstitution,
      }}
    >
      {children}
    </SubstitutionContext.Provider>
  );
}

export const useSubstitution = (): SubstitutionContextProps =>
  useContext(SubstitutionContext);
