import { ApolloError, gql, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Tag } from "baseui/tag";
import { HeadingLarge, LabelLarge, LabelSmall } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { AlertOctagon, ArrowRight, Mail } from "tabler-icons-react";

import { InputValidationError } from "../../../api";
import Button from "../../../components/button";
import Cell from "../../../components/cell";
import FormControl from "../../../components/form-control";
import Grid from "../../../components/grid";
import { ControlledInput } from "../../../components/input";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import {
  AUTH_TOKEN,
  REACT_APP_SYSTEM_VERSIONS,
  SUPPORT_MAIL,
} from "../../../constants";
import { useAuth } from "../../../contexts/auth-context";
import { useLoading } from "../../../contexts/loading-context";
import { useSupport } from "../../../contexts/support-context";
import { formValidation } from "../../../utils/formValidation";

type FormInputs = {
  emailOrLoginAD: string;
  password: string;
};

export default function Login(): React.ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [css, theme] = useStyletron();
  const { setIsAuthenticated, setUser } = useAuth();
  const { isLoading, setIsLoading } = useLoading();
  const { openSupportModal } = useSupport();

  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormInputs>({
    defaultValues: {
      emailOrLoginAD: "",
      password: "",
    },
  });

  const history = useHistory();

  type LocationState = {
    from: { pathname: string; search: string };
  };

  const { state } = useLocation<LocationState>();
  const statePathname = state?.from?.pathname;
  const stateSearch = state?.from?.search;

  const LOGIN_MUTATION = gql`
    mutation Login($emailOrLoginAD: String!, $password: String!) {
      login(
        loginInput: { emailOrLoginAD: $emailOrLoginAD, password: $password }
      ) {
        accessToken
        user {
          id
          firstName
          lastName
          email
          loginAD
          createdAt
          updatedAt
          lastActivityAt
          lastLoginAt
          isBlockedByAdmin
          isBlockedByAD
          permissions {
            id
            systemName
          }
          roles {
            id
            name
          }
          interfacePreferences
          unreadNotificationCount
          organizationalUnitToUsers {
            organizationalUnit {
              id
              name
              symbol
              budgetSymbol
            }
            position {
              id
              name
              role {
                id
                name
              }
            }
            user {
              id
            }
          }
        }
      }
    }
  `;

  const [loginUser, { error }] = useMutation(LOGIN_MUTATION);

  const onSubmit = async ({
    emailOrLoginAD,
    password,
  }: FormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await loginUser({
        variables: {
          emailOrLoginAD,
          password,
        },
      });

      localStorage.setItem(AUTH_TOKEN, response?.data?.login?.accessToken);
      setUser(response?.data?.login?.user);
      setIsAuthenticated(true);

      enqueueSnackbar({
        message: "Zalogowano pomyślnie",
        variant: "success",
      });

      statePathname
        ? history.push(`${statePathname}${stateSearch || ""}`)
        : history.push("/folders/my");
    } catch (error: unknown) {
      (error as ApolloError).graphQLErrors?.map(({ extensions, message }) => {
        if (extensions?.code === "USER_ROLES_MISSING") {
          reset();
          return setIsModalOpen(true);
        }
        return enqueueSnackbar({
          message,
          variant: "error",
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const systemVersion = process.env.REACT_APP_SYSTEM_VERSION;

  return (
    <main>
      <Loading />
      <Grid
        isFlex
        $style={{ height: "calc(100vh - 5px)" }}
        flexGridColumnGap={0}
      >
        <Cell isFlex>
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              paddingLeft: "50px",
              paddingRight: "50px",
            })}
          >
            <div
              className={css({
                width: "100%",
                maxWidth: "400px",
              })}
            >
              <HeadingLarge
                marginTop="scale0"
                marginBottom="scale1000"
                $style={{ textAlign: "center" }}
              >
                Logowanie
              </HeadingLarge>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  label="Login AD"
                  required
                  error={
                    (errors.emailOrLoginAD && errors.emailOrLoginAD.message) ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        "INPUT_VALIDATION_ERROR" &&
                      error.graphQLErrors[0]?.extensions?.validationErrors
                        ?.find(
                          (vE: InputValidationError) =>
                            vE?.property === "emailOrLoginAD"
                        )
                        ?.errors.map((message: string) => (
                          <div
                            key="error"
                            className={css({
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            })}
                          >
                            {message}
                            <span
                              className={css({
                                color: "#999",
                                marginLeft: "auto",
                                marginRight: "5px",
                              })}
                            >
                              Walidacja serwera
                            </span>
                            <AlertOctagon color="#999" size={12} />
                          </div>
                        ))[0])
                  }
                  disabled={isLoading}
                >
                  <ControlledInput
                    control={control}
                    name="emailOrLoginAD"
                    placeholder="Login AD"
                    rules={{
                      required: formValidation.messages.required,
                    }}
                  />
                </FormControl>
                <FormControl
                  label="Hasło"
                  required
                  error={
                    (errors.password && errors.password.message) ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        "INPUT_VALIDATION_ERROR" &&
                      error.graphQLErrors[0]?.extensions?.validationErrors
                        ?.find(
                          (vE: InputValidationError) =>
                            vE?.property === "password"
                        )
                        ?.errors.map((message: string) => <>{message}</>)[0])
                  }
                  disabled={isLoading}
                >
                  <ControlledInput
                    control={control}
                    type="password"
                    name="password"
                    placeholder="Hasło"
                    autoComplete="current-password"
                    rules={{
                      required: formValidation.messages.required,
                    }}
                  />
                </FormControl>
                <Button
                  type="submit"
                  size={SIZE.large}
                  kind={KIND.primary}
                  isSelected={isLoading}
                  isLoading={isLoading}
                  $style={{ width: "100%", marginTop: "15px" }}
                >
                  Zaloguj się
                </Button>
                <StyledLink
                  href=""
                  onClick={(event: MouseEvent) => {
                    event.preventDefault();
                    openSupportModal();
                  }}
                  $style={{ marginTop: "15px", display: "block" }}
                >
                  Przypomnienie hasła
                </StyledLink>
              </form>
            </div>
          </div>
        </Cell>
        <Cell
          isFlex
          $style={{
            backgroundColor: "#f9f9f9",
          }}
        >
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              height: "100%",
              padding: "50px",
              maxWidth: "650px",
            })}
          >
            <div>
              <div
                className={css({
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  marginBottom: "20px",
                })}
              >
                <img
                  alt="FRSE"
                  src="logo.svg"
                  height="60px"
                  className={css({ marginBottom: "10px" })}
                />
                <Block display="flex" alignItems="center">
                  <LabelLarge
                    marginTop="scale0"
                    $style={{ textAlign: "center" }}
                  >
                    Elektroniczne Zarządzanie Dokumentacją
                  </LabelLarge>
                  {process.env.REACT_APP_SYSTEM_VERSION !==
                    REACT_APP_SYSTEM_VERSIONS.PRODUCTION && (
                    <Tag
                      closeable={false}
                      {...(systemVersion === REACT_APP_SYSTEM_VERSIONS.TEST && {
                        kind: "negative",
                      })}
                      {...(systemVersion ===
                        REACT_APP_SYSTEM_VERSIONS.TRAINING && {
                        kind: "positive",
                      })}
                      {...(!systemVersion && {
                        kind: "warning",
                      })}
                      variant="solid"
                      overrides={{
                        Text: { style: { fontSize: "12px", fontWeight: 600 } },
                        Root: { style: { marginLeft: "8px" } },
                      }}
                    >
                      {systemVersion === REACT_APP_SYSTEM_VERSIONS.TEST &&
                        "TEST"}
                      {systemVersion === REACT_APP_SYSTEM_VERSIONS.TRAINING &&
                        "SZKOLENIE"}
                      {!systemVersion && "DEV"}
                    </Tag>
                  )}
                </Block>
                <LabelSmall
                  paddingTop={theme.sizing.scale200}
                  paddingRight={theme.sizing.scale600}
                  color="contentInverseTertiary"
                >
                  © 2023 • {process.env.REACT_APP_VERSION}
                </LabelSmall>
              </div>
              <Button
                type="submit"
                size={SIZE.compact}
                kind={KIND.secondary}
                $style={{ marginTop: "45px" }}
                endEnhancer={() => <ArrowRight size={18} />}
                $as="a"
                target="_blank"
                href="https://e-wnioski.frse.org.pl/"
              >
                eWnioski
              </Button>
              <Button
                type="submit"
                size={SIZE.compact}
                kind={KIND.secondary}
                $style={{ marginTop: "15px", marginLeft: "15px" }}
                endEnhancer={() => <ArrowRight size={18} />}
                $as="a"
                href="http://rejestr.frse.org.pl/"
              >
                Rejestr
              </Button>
            </div>
          </div>
        </Cell>
      </Grid>

      <Modal onClose={() => setIsModalOpen(false)} isOpen={isModalOpen}>
        <>
          <ModalHeader>Skontaktuj się z administratorem</ModalHeader>
          <ModalBody>
            <p>
              Brak uprawnień w systemie. Skontaktuj się z administratorem przez
              system magazyn.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              $as="a"
              target="_blank"
              href={`mailto:${
                process.env.REACT_APP_SYSTEM_VERSION === "TEST"
                  ? SUPPORT_MAIL
                  : process.env.REACT_APP_ADMIN_MAIL
              }`}
              startEnhancer={<Mail size={20} />}
            >
              Skontaktuj się
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </main>
  );
}
