export const PERMISSIONS = {
  activityLog: {
    read: "activityLog.read",
  },
  address: {
    read: "address.read",
    hide: "address.hide",
    update: "address.update",
  },
  budgetLedgerItems: {
    read: "budgetLedgerItem.read",
  },
  billIssuanceRequest: {
    read: "billIssuanceRequest.read",
    create: "billIssuanceRequest.create",
    update: "billIssuanceRequest.update",
    delete: "billIssuanceRequest.delete",
  },
  case: {
    read: "case.read",
    readAll: "case.readAll",
    update: "case.update",
    create: "case.create",
    delete: "case.delete",
    close: "case.close",
    changeSupervisor: "case.changeSupervisor",
  },
  contractAgreement: {
    read: "contractAgreement.read",
    create: "contractAgreement.create",
    update: "contractAgreement.update",
    close: "contractAgreement.close",
  },
  contractPreparationRequest: {
    read: "contractPreparationRequest.read",
    update: "contractPreparationRequest.update",
    create: "contractPreparationRequest.create",
    delete: "contractPreparationRequest.delete",
  },
  controlling: {
    updateRequestFormNote: "controlling.updateRequestFormNote",
    updateRequestFormNrRejestr: "controlling.updateRequestFormNrRejestr",
    updateRequestFormBasedOnNote: "controlling.updateRequestFormBasedOnNote",
  },
  currency: {
    read: "currency.read",
    update: "currency.update",
  },
  document: {
    cancel: "document.cancel",
    read: "document.read",
    readAll: "document.readAll",
    create: "document.create",
    update: "document.update",
  },
  documentsRegister: {
    read: "documentsRegister.read",
  },
  documentParcel: {
    read: "documentParcel.read",
    update: "documentParcel.update",
    create: "documentParcel.create",
    delete: "documentParcel.delete",
  },
  documentPickup: {
    read: "documentPickup.read",
    update: "documentPickup.update",
    create: "documentPickup.create",
    delete: "documentPickup.delete",
    manage: "documentPickup.manage",
  },
  dictionary: {
    read: "dictionary.read",
    valueUpdate: "dictionaryValue.update",
    valueCreate: "dictionaryValue.create",
    valueDelete: "dictionaryValue.delete",
  },
  divisorTemplates: {
    read: "divisorTemplate.read",
    update: "divisorTemplate.update",
    create: "divisorTemplate.create",
    delete: "divisorTemplate.delete",
  },
  divisorItems: {
    read: "divisorItem.read",
    update: "divisorItem.update",
    create: "divisorItem.create",
    delete: "divisorItem.delete",
  },
  financialAccountingDocument: {
    read: "financialAccountingDocument.read",
    create: "financialAccountingDocument.create",
    update: "financialAccountingDocument.update",
  },
  documentFlow: {
    read: "documentFlow.read",
    update: "documentFlow.update",
    create: "documentFlow.create",
    delete: "documentFlow.delete",
  },
  documentFlowStep: {
    read: "documentFlowStep.read",
    update: "documentFlowStep.update",
    create: "documentFlowStep.create",
    delete: "documentFlowStep.delete",
  },
  documentFlowAction: {
    read: "documentFlowAction.read",
    update: "documentFlowAction.update",
    create: "documentFlowAction.create",
    delete: "documentFlowAction.delete",
  },
  financialPlan: {
    read: "financialPlan.read",
    create: "financialPlan.create",
    update: "financialPlan.update",
    activate: "financialPlan.activate",
    deactivate: "financialPlan.deactivate",
  },
  financialPlanItem: {
    read: "financialPlanItem.read",
  },
  group: {
    read: "group.read",
    update: "group.update",
    create: "group.create",
    delete: "group.delete",
  },
  internalAccounts: {
    read: "internalAccount.read",
    update: "internalAccount.update",
    create: "internalAccount.create",
    delete: "internalAccount.delete",
  },
  jrwa: {
    read: "jrwaClassification.read",
    update: "jrwaClassification.update",
    create: "jrwaClassification.create",
    delete: "jrwaClassification.delete",
  },
  organizationalUnit: {
    read: "organizationalUnit.read",
    update: "organizationalUnit.update",
    create: "organizationalUnit.create",
    delete: "organizationalUnit.delete",
    move: "organizationalUnit.move",
    assignUser: "organizationalUnit.assignUser",
    unassignUser: "organizationalUnit.unassignUser",
  },
  payments: {
    read: "payment.read",
    update: "payment.update",
    create: "payment.create",
    delete: "payment.delete",
  },
  position: {
    read: "position.read",
    update: "position.update",
    create: "position.create",
    delete: "position.delete",
  },
  requestForm: {
    read: "requestForm.read",
    update: "requestForm.update",
    create: "requestForm.create",
    delete: "requestForm.delete",
    close: "requestForm.close",
    void: "requestForm.void",
  },
  requestFormItem: {
    read: "requestFormItem.read",
    update: "requestFormItem.update",
    create: "requestFormItem.create",
    delete: "requestFormItem.delete",
  },
  requestFormNote: {
    read: "requestFormNote.read",
    update: "requestFormNote.update",
    create: "requestFormNote.create",
    delete: "requestFormNote.delete",
  },
  role: {
    read: "role.read",
    update: "role.update",
    create: "role.create",
    delete: "role.delete",
  },
  sendingList: {
    update: "eNadawcaSendingList.update",
    read: "eNadawcaSendingList.read",
    create: "eNadawcaSendingList.create",
    delete: "eNadawcaSendingList.delete",
  },
  shipmentContract: {
    read: "shipmentContract.read",
    update: "shipmentContract.update",
    create: "shipmentContract.create",
    delete: "shipmentContract.delete",
  },
  shipmentRate: {
    read: "shipmentRate.read",
    update: "shipmentRate.update",
    create: "shipmentRate.create",
    delete: "shipmentRate.delete",
  },
  bankAccount: {
    read: "bankAccount.read",
    update: "bankAccount.update",
    create: "bankAccount.create",
    delete: "bankAccount.delete",
  },
  substitution: {
    read: "substitution.read",
    readAll: "substitution.readAll",
    update: "substitution.update",
    create: "substitution.create",
    delete: "substitution.delete",
  },
  user: {
    block: "user.block",
    read: "user.read",
    update: "user.update",
    create: "user.create",
    delete: "user.delete",
    requestSynchronization: "user.requestSynchronization",
  },
};
