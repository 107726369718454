import { DocumentMutationAction } from "../../containers/Documents/documents.d";

export const renderMutationSuccessLabel = (
  mutationAction: DocumentMutationAction
): string => {
  switch (mutationAction) {
    case DocumentMutationAction.Accept:
      return "Zaakceptowano pomyślnie";

    case DocumentMutationAction.CancelDispatch:
      return "Anulowano przekazanie dokumentu";

    case DocumentMutationAction.CompleteEdition:
      return "Zakończono edycję dokumentu";

    case DocumentMutationAction.DeletePayment:
      return "Usunięto pomyślnie";

    case DocumentMutationAction.ExportXlsx:
    case DocumentMutationAction.ExportZip:
      return "Rozpoczęto pobieranie pliku";

    case DocumentMutationAction.ReleaseResponsible:
      return "Zwolniono dokument";

    case DocumentMutationAction.RevealAddress:
    case DocumentMutationAction.RevealFiles:
      return "Odkryto pomyślnie";

    case DocumentMutationAction.SetResponsible:
      return "Przejęto dokument";

    case DocumentMutationAction.StartEdition:
      return "Rozpoczęto edycję dokumentu";

    case DocumentMutationAction.TrackOpen:
      return "Zaakceptowano pomyślnie";

    default:
      return "Akcja wykonana pomyślnie";
  }
};
