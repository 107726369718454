import { FIELDS, FieldsGroup } from "../../../fields.d";
import { Document } from "../documents";

export type BillIssuanceRequestInputs = {
  description: string;
  parentIds?: Document[];
  cases?: { id: number; label?: string }[];
  caseId?: number;
  organizationalUnits?: { id: number; label?: string }[];
  organizationalUnitId?: number;
  files?: FileList;
  remarks: string;
  contractorAccountNumber: string;
  grossValue: number | string;
  workedHoursCount: number | string;
  contractorName: string;
  currencies?: {
    id: string;
    label?: string;
    code?: string;
    currencyId?: string;
  }[];
  currencyId?: number;
};

export type LinkFormInputs = {
  parentIds: Document[];
};

export const BILL_ISSUANCE_REQUEST_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "documentType",
        label: "Typ dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        placeholder: "Wybierz",
      },
      {
        id: "parentIds",
        label: "Umowa",
        type: FIELDS.ContractAgreementSelect,
        span: 8,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "parentIds",
        label: "Umowa",
        type: FIELDS.Input,
        span: 3,
        dataType: "model:documents",
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "documentRoot",
        label: "Wniosek o wydatkowanie",
        type: FIELDS.Input,
        span: 3,
        dataType: "model:documents",
        show: { visible: true, accessor: ["documentRoot", "internalNumber"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "organizationalUnit",
        label: "Jednostka organizacyjna",
        type: FIELDS.UsersOrganizationalUnitsSelect,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "organizationalUnit",
        label: "Jednostka organizacyjna",
        type: FIELDS.Input,
        dataType: "model:organizational-units",
        span: 3,
        show: { visible: true, accessor: ["organizationalUnit", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "state",
        label: "Stan dokumentu",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "document-state",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        dataType: "datetime",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdBy",
        label: "Autor",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
      {
        id: "responsible",
        label: "Odpowiedzialny",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:users",
      },
    ],
  },
  {
    id: "contractorData",
    label: "Dane do rachunku",
    fields: [
      {
        id: "contractorName",
        label: "Imię i nazwisko wykonawcy",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "grossValue",
        label: "Kwota wynagrodzenia brutto",
        type: FIELDS.NumberInput,
        dataType: "quota",
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
        endEnhancer: "PLN",
      },
      {
        id: "workedHoursCount",
        label: "Liczba przepracowanych godzin",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "currencies",
        label: "Waluta",
        type: FIELDS.CurrencySelect,
        placeholder: "PLN",
        span: 3,
        show: { visible: true, accessor: ["currency", "code"] },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "contractorAccountNumber",
        label: "Numer rachunku do umowy",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "remarks",
        label: "Uwagi",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "case",
    label: "Znak sprawy",
    fields: [
      // {
      //   id: "cases",
      //   label: "",
      //   type: FIELDS.CasesSelect,
      //   span: 12,
      //   show: { visible: false },
      //   create: { visible: true },
      //   edit: { visible: true },
      //   placeholder: "Wybierz",
      //   caption:
      //     "W tym polu należy wybrać odpowiedni znak sprawy z jaką zostanie powiązany dokument. Nie jest to obowiązkowe na etapie rejestracji dokumentu w recepcji",
      //   typeName: ["Incoming", "Outgoing", "Internal"],
      // },
      {
        id: "case",
        label: "",
        type: FIELDS.Input,
        span: 12,
        show: { accessor: ["case", "name"], visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "model:cases",
        additionalData: "isConductedElectronically",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
    ],
  },
  {
    id: "files",
    label: "Załączniki",
    fields: [
      {
        id: "filesList",
        label: "",
        type: FIELDS.FilesList,
        span: 12,
        show: { visible: false },
        create: { visible: false },
        edit: { visible: true },
        typeName: ["Incoming", "Outgoing", "Internal"],
        caption:
          "Kliknięcie ikony kosza spowoduje usunięcia załącznika po zapisaniu dokumentu",
      },
      {
        id: "files",
        label: "Dodaj nowy",
        type: FIELDS.FilesPicker,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
        caption: "Maksymalny rozmiar pliku to 128 MB.",
        typeName: ["Incoming", "Outgoing", "Internal"],
      },
    ],
  },
];
