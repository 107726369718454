import { Case } from "../Cases/cases";
import { AssignmentAssessmentResult, Document } from "../Documents/documents";
import { User } from "../Users/users";

export type FolderableConnection = { __typename: string } & (Document | Case);
export type AssignableUnion = { __typename: string } & (Document | Case);

export type AssignmentLocationState = {
  assignmentId?: number;
};

export enum FolderKey {
  New = <any>"new",
  InProgress = <any>"in-progress",
  Transferred = <any>"transferred",
  Shared = <any>"shared",
  Finished = <any>"finished",
}

export enum AssignmentType {
  Share = "Share",
  Dispatch = "Dispatch",
  Initial = "Initial",
  Flow = "Flow",
}

export enum AssignmentStatus {
  New = "New",
  InProgress = "InProgress",
  Processed = "Processed",
  Finished = "Finished",
}

export type AssignmentFinishInputs = {
  assigneeComment?: string;
};

export enum AssignmentAssignablePermission {
  Read = "Read",
  Update = "Update",
}

export enum AssignmentAssignableScope {
  Full = "Full",
  Partial = "Partial",
}

export type Assignment = {
  id: number;
  parentId?: number;
  type: AssignmentType;
  assigner: User;
  assignerId: number;
  assignee: User;
  assigneeId: number;
  assignerComment?: string;
  assigneeComment?: string;
  substituter?: User;
  primaryAssignable: AssignableUnion;
  primaryAssignableId: number;
  status: AssignmentStatus;
  assessmentResult?: AssignmentAssessmentResult;
  createdAt: string;
  finishedAt?: string;
  isAssessmentRequested?: boolean;
};

export type AssignmentShorthand = Pick<
  Assignment,
  "id" | "primaryAssignableId"
>;

export const FOLDERS_NAME: Map<string, string> = new Map([
  ["my", "Moje"],
  ["common", "Wspólne"],
  ["shared", "Udostępnione"],
]);
