import { gql } from "@apollo/client";

export const REQUEST_FORM_NOTE_CREATE = gql`
  mutation CreateRequestFormNote(
    $requestFormNoteCreateInput: RequestFormNoteCreateInput!
    $requestFormNoteFiles: [Upload!]
  ) {
    requestFormNoteCreate(
      requestFormNoteCreateInput: $requestFormNoteCreateInput
      requestFormNoteFiles: $requestFormNoteFiles
    ) {
      id
    }
  }
`;

export const REQUEST_FORM_NOTE_UPDATE = gql`
  mutation EditRequestFormNote(
    $requestFormNoteUpdateInput: RequestFormNoteUpdateInput!
    $requestFormNoteFiles: [Upload!]
  ) {
    requestFormNoteUpdate(
      requestFormNoteUpdateInput: $requestFormNoteUpdateInput
      requestFormNoteFiles: $requestFormNoteFiles
    ) {
      id
    }
  }
`;

export const REQUEST_FORM_NOTE_DELETE = gql`
  mutation($requestFormNoteDeleteInput: RequestFormNoteDeleteInput!) {
    requestFormNoteDelete(
      requestFormNoteDeleteInput: $requestFormNoteDeleteInput
    ) {
      id
    }
  }
`;

export const REQUEST_FORM_NOTE_SHOW = gql`
  query($id: Int!) {
    requestFormNote(id: $id) {
      id
      isReleasable
      documentLinks {
        id
        linkerId
        linker {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        linkedId
        linked {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        type
        createdAt
      }
      requestFormId
      requestForm {
        internalNumber
        legalAct
        id
        documentType
        type
        documentNumber
        documentKind
        orderSubject
      }
      documentRoot {
        ... on RequestForm {
          internalNumber
          legalAct
          id
          documentType
          type
          documentNumber
          documentKind
        }
        ... on ContractAgreement {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on FinancialAccountingDocument {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on Payment {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on Document {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on BillIssuanceRequest {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on ContractPreparationRequest {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
      }
      children {
        id
        documentNumber
        internalNumber
        documentKind
        documentType
        type
        documentDate
        createdAt
        postStampAt
      }
      responsible {
        id
        firstName
        lastName
      }
      isResponsibleSettable
      isEditCompleted
      createdBy {
        id
        firstName
        lastName
      }
      documentNumber
      description
      title
      programName
      internalNumber
      documentKind
      isEditable
      documentDate
      documentType
      type
      createdAt
      state
      sender {
        __typename
        ... on User {
          id
          firstName
          lastName
          organizationalUnitToUsers {
            organizationalUnit {
              id
              name
            }
          }
        }
      }
      recipient {
        __typename
        ... on User {
          id
          firstName
          lastName
          organizationalUnitToUsers {
            organizationalUnit {
              id
              name
            }
          }
        }
      }
      case {
        id
        name
        number
        isConductedElectronically
      }
      caseId
      organizationalUnit {
        id
        symbol
        name
      }
      createdAt
      updatedAt
      currentStatus {
        documentFlowStepId
        documentFlowStep {
          id
          name
          isInitial
          isProcessedByDocumentFlowStarter
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
          }
          group {
            id
            name
          }
          users {
            firstName
            lastName
          }
        }
        createdAt
      }
      documentFlowId
      statuses {
        id
        comment
        signatureFilePath
        signatureFileMimetype
        signatureDownloadUrl
        user {
          id
          firstName
          lastName
          position
        }
        userPosition
        userOrganizationalUnits
        documentFlowRecipients
        substituter {
          id
          firstName
          lastName
          position
        }
        documentFlowStep {
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
            symbol
          }
          users {
            id
            firstName
            lastName
          }
          positions {
            id
            name
          }
          id
          name
          isProcessedByDocumentFlowStarter
        }
        documentFlowAction {
          label
          style
          resultingDocumentState
          isEndingDocumentFlow
        }
        createdAt
      }
      documentFlowId
      isDocumentUsedInFlow
      documentFlow {
        id
        name
        steps {
          id
          name
        }
      }
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
      activityLogs {
        id
        activity
        description
        user {
          firstName
          lastName
          id
        }
        substituter {
          id
          firstName
          lastName
        }
        assignmentUsers
        properties
        createdAt
        userOrganizationalUnits
      }
      isDispatchable
      isDispatchCancelable
      isShareable
      shippedAt
      currentAssignment {
        id
        primaryAssignableId
        isAssessmentRequested
        assignerComment
        createdAt
        type
        status
        assigner {
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const REQUEST_FORM_NOTE_FILES_SHOW = gql`
  query($id: Int!) {
    requestFormNote(id: $id) {
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
    }
  }
`;
