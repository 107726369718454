import { ApolloError, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff, Note } from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import { ControlledRequestFormTable } from "../../../../components/request-form-table";
import {
  ControlledCasesSelect,
  ControlledDictionaryValuesSelect,
  ControlledOrderTypeSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledUsersOrganizationalUnitsSelect,
  ControlledUsersSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import { useAuth } from "../../../../contexts/auth-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { REQUEST_FORM_FIELDS, RequestFormInputs } from "../request-form.form";
import { REQUEST_FORM_CREATE } from "../request-form.gql";

export default function DocumentsCreateRequestForm(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  const [css] = useStyletron();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, isLoading, setIsLoading } = useLoading();
  const { user } = useAuth();

  const time = new Date();
  const fullYear = time.getFullYear().toString();

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<RequestFormInputs>({
    defaultValues: {
      documentNumber: "",
      nrRejestr: "",
      orderSubject: "",
      orderType: undefined,
      organizationalUnit: [
        {
          id: user?.organizationalUnitToUsers[0]?.organizationalUnit?.id,
          label: `[${user?.organizationalUnitToUsers[0]?.organizationalUnit?.symbol}] ${user?.organizationalUnitToUsers[0]?.organizationalUnit?.name}`,
        },
      ],
    },
  });

  const watchFields = watch();

  const [focusedInput, setFocusedInput] = useState<keyof RequestFormInputs>();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (focusedInput !== name && type) {
        setFocusedInput(name as keyof RequestFormInputs);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, focusedInput]);

  const [createRequestFormRequest, { error: mutationError }] = useMutation(
    REQUEST_FORM_CREATE
  );

  const onError = (errors: Record<string, unknown>, e: any) => {
    setFocusedInput(undefined);
    setErrorTimeStamp(e.timeStamp);
  };

  const onSubmit = async ({
    documentNumber,
    nrRejestr,
    orderSubject,
    orderType,
    caseName,
    organizationalUnit,
    requestFormItems,
    preferedExecutionBeginAt,
    preferedExecutionEndAt,
    beginAt,
    endAt,
    legalActSelect,
    legalDocumentsVerification,
    isContractAgreementRequired,
  }: RequestFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await createRequestFormRequest({
        variables: {
          requestFormCreateInput: {
            documentNumber,
            nrRejestr,
            orderType: orderType && orderType[0]?.label,
            orderSubject,
            caseId: caseName && caseName[0]?.id,
            documentType: "requestForm",
            documentKind: "Internal",
            organizationalUnitId:
              organizationalUnit && organizationalUnit[0]?.id,
            estimatedValueBy: "User",
            preferedExecutionBeginAt,
            preferedExecutionEndAt,
            beginAt,
            endAt,
            legalAct: legalActSelect && legalActSelect[0]?.id,
            legalDocumentsVerification: !!legalDocumentsVerification,
            isContractAgreementRequired: !!isContractAgreementRequired,
          },
          requestFormItemCreateInputs: requestFormItems,
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      history.push(
        `/documents/internal/RequestForm/${response?.data?.requestFormCreate?.id}`
      );
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errors) scrollOnError(errors, REQUEST_FORM_FIELDS, "create");
  }, [errorTimeStamp]);

  if (!checkPermission(PERMISSIONS.requestForm.create))
    return <Redirect to="/" />;

  return (
    <article>
      <Header
        title="Nowy wniosek o wydatkowanie"
        labels={["Dokumenty", "Tworzenie wniosku"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              !(Object.keys(dirtyFields).length <= 1)
                ? setIsCancelConfirmDialogOpen(true)
                : history.push("/documents"),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "createRequestForm",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={3}>
            <FormControl label="Data rejestracji w systemie" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>{time.toLocaleDateString()}</FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={6}>
            <FormControl label="Numer wniosku" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>
                  {`${fullYear}-Symbol-Miesiąc-Kolejny numer w danej jednostce
                  i miesiącu`}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form
          id="createRequestForm"
          onSubmit={handleSubmit(onSubmit)}
          onFocus={() => {
            const inputName =
              typeof window !== "undefined" &&
              (window?.document?.activeElement?.id as keyof RequestFormInputs);

            inputName &&
              inputName !== focusedInput &&
              !inputName.includes("bui") &&
              setFocusedInput(inputName);
          }}
        >
          <Grid>
            {REQUEST_FORM_FIELDS.filter(
              (g) =>
                g.fields.filter((f) => f.create?.visible).length > 0 &&
                !["legalInformation", "otherInformations"].includes(g.id)
            ).map((group) => [
              group.label && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
              group.fields
                .filter((f) => f.create?.visible)
                .map(
                  (item, index) =>
                    item.id &&
                    ((item.id === "nrRejestr" &&
                      checkPermission(
                        PERMISSIONS.controlling.updateRequestFormNrRejestr
                      )) ||
                      item.id !== "nrRejestr") && (
                      <Cell
                        span={item.span || 6}
                        key={group.id + `-field` + index}
                      >
                        <FormControl
                          label={
                            item.type === FIELDS.Checkbox ? "" : item.label
                          }
                          caption={item.caption}
                          required={item.create?.required}
                          disabled={isLoading || item.id === ""}
                          error={
                            ((errors as any)[item.id] &&
                              (errors as any)[item.id].message) ||
                            (mutationError &&
                              mutationError.graphQLErrors[0]?.extensions
                                ?.code === "INPUT_VALIDATION_ERROR" &&
                              mutationError.graphQLErrors[0]?.extensions?.validationErrors
                                ?.find(
                                  (vE: InputValidationError) =>
                                    vE?.property === item.id
                                )
                                ?.errors.map((message: string) => (
                                  <div
                                    key="error"
                                    className={css({
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    })}
                                  >
                                    {message}
                                    <AlertOctagon color="#999" size={12} />
                                  </div>
                                ))[0])
                          }
                        >
                          {item.type === FIELDS.UsersSelect ? (
                            <ControlledUsersSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.CasesSelect ? (
                            <ControlledCasesSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                            <ControlledOrganizationalUnitsSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type ===
                            FIELDS.UsersOrganizationalUnitsSelect ? (
                            <ControlledUsersOrganizationalUnitsSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.OrderTypeSelect ? (
                            <ControlledOrderTypeSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder={item.placeholder}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.DictionaryValuesSelect ? (
                            <ControlledDictionaryValuesSelect
                              dictionarySystemName="legal.basis"
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder={item.placeholder}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.DatePicker ? (
                            <ControlledDatePicker
                              name={item.id}
                              control={control}
                              {...(item.id === "beginAt" &&
                                watchFields.endAt && {
                                  maxDate: watchFields.endAt,
                                })}
                              {...(item.id === "endAt" &&
                                watchFields.beginAt && {
                                  minDate: watchFields.beginAt,
                                })}
                              {...(item.id === "preferedExecutionBeginAt" &&
                                watchFields.preferedExecutionEndAt && {
                                  maxDate: watchFields.preferedExecutionEndAt,
                                })}
                              {...(item.id === "preferedExecutionEndAt" &&
                                watchFields.preferedExecutionBeginAt && {
                                  minDate: watchFields.preferedExecutionBeginAt,
                                })}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.Checkbox ? (
                            <ControlledCheckbox
                              control={control}
                              name={item.id}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            >
                              {item.label}
                            </ControlledCheckbox>
                          ) : item.type === FIELDS.NumberInput ? (
                            <ControlledInput
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder={item.placeholder}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                              type="number"
                              masked={item.mask !== undefined}
                              mask={item.mask}
                              maskChar="_"
                              min={0}
                            />
                          ) : item.type === FIELDS.RequestFormTable ? (
                            <ControlledRequestFormTable
                              formType="create"
                              control={control}
                              name={item.id}
                              id={item.id}
                              hasFocus={focusedInput === item.id}
                            />
                          ) : item.type === FIELDS.TextArea ? (
                            <ControlledTextArea
                              control={control}
                              name={item.id}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : (
                            <ControlledInput
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder={item.placeholder}
                              {...(item.create.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                              masked={item.mask !== undefined}
                              mask={item.mask}
                              maskChar="_"
                              endEnhancer={item.endEnhancer}
                              disabled={item.readonly}
                            />
                          )}
                        </FormControl>
                      </Cell>
                    )
                ),
            ])}
            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie tworzenia wniosku o wydatkowanie"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.push("/documents")}
            />
            <ConfirmDialog
              isOpen={isClearConfirmDialogOpen}
              label="Wyczyszczenie formularza"
              close={() => setIsClearConfirmDialogOpen(false)}
              confirm={() => {
                reset();
                setValue("organizationalUnit", []);
                setIsClearConfirmDialogOpen(false);
              }}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
