import { Block } from "baseui/block";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { CirclePlus } from "tabler-icons-react";

import { Currency } from "../containers/Currencies/currencies";
import { DivisorTemplate } from "../containers/Divisors/divisors";
import { generateUUID, localeParseFloat } from "../utils/misc";
import Button from "./button";
import { MemoizedTransferTable } from "./transfer-table";

export type TransferTableData = {
  id: string;
  availableAmount: string;
  balance: number;
  divisorTemplate?: DivisorTemplate;
  items: TransferTableItemData[];
};

export type TransferTableItemData = {
  id: string;
  edit?: boolean;
  originalId?: number;
  description?: string;
  budget?: { id: number; label?: string; category?: string };
  internalAccount?: { id: number; label?: string };
  percent?: number;
  amount?: number;
  table: string;
};

type TransferTablesComposerProps = {
  value?: TransferTableItemData[];
  onChange?: (items: TransferTableItemData[]) => void;
  error?: boolean;
  disabled?: boolean;
  id?: string;
  totalAmount: number;
  organizationalUnitId?: number;
  hasFocus?: boolean;
  currency?: Currency;
};

export default function TransferTablesComposer({
  value,
  onChange,
  error,
  disabled,
  id,
  totalAmount,
  organizationalUnitId,
  currency,
}: TransferTablesComposerProps): React.ReactElement {
  const [tables, setTables] = useState<TransferTableData[]>([
    {
      id: "autoGeneratedFirstTable",
      availableAmount: totalAmount?.toString(),
      balance: totalAmount,
      items: [
        {
          id: "autoGeneratedFirstItem",
          table: "autoGeneratedFirstTable",
        },
      ],
    },
  ]);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (value && !isInitialized) {
      if (value?.length) {
        const tables = value
          .map((item: TransferTableItemData) => item.table)
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((id: string) => ({
            id,
            balance: 0,
            items:
              value.filter(
                (item: TransferTableItemData) => item.table === id
              ) || [],
            availableAmount: localeParseFloat(
              new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
              })
                .format(
                  value
                    .filter((item: TransferTableItemData) => item.table === id)
                    .reduce((a, b) => a + (b.amount ? b.amount : 0), 0)
                )
                .replace(/,/g, "")
            ).toString(),
          }));

        setTables([...tables]);
      }

      setIsInitialized(true);
    }
  }, [value]);

  useEffect(() => {
    if (onChange && isInitialized) {
      onChange(
        tables.reduce(
          (acc, table) => [
            ...acc,
            ...table.items
              ?.filter((item) => item.internalAccount && item.amount)
              ?.map(
                (item: any) =>
                  item.amount && {
                    ...(typeof item.id === "number" && {
                      id: parseInt(item.id),
                    }),
                    amount: item.amount,
                    ...(item.internalAccount && {
                      internalAccountId: item.internalAccount.id,
                    }),
                    ...(item.budget && {
                      bazaPositionId: item.budget.id,
                    }),
                    ...(item.description && {
                      description: item.description,
                    }),
                    ...(item.amount && {
                      amount: item.amount,
                    }),
                    ...(item.amount && {
                      correctionAmount: item.amount,
                    }),
                    category: "1",
                    organizationalUnitId,
                    tableId: table.id,
                    fundingSource: "",
                  }
              ),
          ],
          [] as TransferTableItemData[]
        )
      );
    }
  }, [tables]);

  const currentTablesAmount = useMemo(
    () =>
      localeParseFloat(
        tables
          .reduce(
            (a, b) =>
              a +
              (localeParseFloat(b.availableAmount)
                ? localeParseFloat(b.availableAmount)
                : 0),
            0
          )
          .toFixed(2)
      ),
    [tables]
  );

  return (
    <div id={id}>
      {tables.map((table) => (
        <MemoizedTransferTable
          key={table.id}
          table={table}
          items={table.items}
          setItems={(items) => {
            setTables((tables) => {
              const index = tables.findIndex(({ id }) => id === table.id);

              return [
                ...tables.slice(0, index),
                { ...tables[index], items },
                ...tables.slice(index + 1),
              ];
            });
          }}
          setTables={setTables}
          currency={currency}
          disabled={disabled}
          error={error}
        />
      ))}

      <Block display="flex" justifyContent="space-between" alignItems="center">
        <Button
          kind="secondary"
          size="compact"
          type="button"
          key="button"
          onClick={() => {
            const newItemId = generateUUID();
            const newTableId = generateUUID();

            const remainingAmount = (totalAmount - currentTablesAmount).toFixed(
              2
            );

            setTables([
              ...tables,
              {
                id: newTableId,
                availableAmount: totalAmount ? remainingAmount : "0",
                balance: totalAmount ? parseFloat(remainingAmount) : 0,
                items: [
                  {
                    id: newItemId,
                    table: newTableId,
                  },
                ],
              },
            ]);
          }}
          startEnhancer={<CirclePlus size={16} />}
          $style={{ fontWeight: 500 }}
        >
          Dodaj tabelę
        </Button>
      </Block>
      <Block height="500px" />
    </div>
  );
}

export function ControlledTransferTablesComposer({
  control,
  name,
  id,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & TransferTablesComposerProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TransferTablesComposer
          id={id}
          onChange={onChange}
          value={value}
          {...rest}
        />
      )}
    />
  );
}
