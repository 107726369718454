import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { AlertOctagon, DeviceFloppy, FileOff } from "tabler-icons-react";

import { InputValidationError } from "../../../../api";
import Cell from "../../../../components/cell";
import { ControlledCheckbox } from "../../../../components/checkbox";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Content from "../../../../components/content";
import { ControlledDatePicker } from "../../../../components/date-picker";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import { ControlledInput } from "../../../../components/input";
import {
  ControlledRequestFormTable,
  RequestFormTableItem,
} from "../../../../components/request-form-table";
import RequestFormValueTable from "../../../../components/request-form-value-table";
import {
  ControlledCasesSelect,
  ControlledDictionaryValuesSelect,
  ControlledOrderTypeSelect,
  ControlledOrganizationalUnitsSelect,
  ControlledUsersOrganizationalUnitsSelect,
} from "../../../../components/select";
import { ControlledTextArea } from "../../../../components/text-area";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useLoading } from "../../../../contexts/loading-context";
import { FIELDS } from "../../../../fields.d";
import { checkPermission } from "../../../../utils/check-permission";
import { formValidation } from "../../../../utils/formValidation";
import { PERMISSIONS } from "../../../../utils/permissions";
import { scrollOnError } from "../../../../utils/scrollOnError";
import { AssignmentLocationState } from "../../../Folders/folders.d";
import { RequestFormItem } from "../../documents.d";
import { REQUEST_FORM_FIELDS, RequestFormInputs } from "../request-form.form";
import { REQUEST_FORM_SHOW, REQUEST_FORM_UPDATE } from "../request-form.gql";

export default function DocumentsEditRequestForm(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const [css] = useStyletron();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading, isFetching, setIsFetching } = useLoading();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    setValue,
    watch,
  } = useForm<RequestFormInputs>();

  const watchFields = watch();
  const locationState = location.state as {
    requestFormNoteId: number;
  };

  const [focusedInput, setFocusedInput] = useState<keyof RequestFormInputs>();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (focusedInput !== name && type) {
        setFocusedInput(name as keyof RequestFormInputs);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, focusedInput]);

  const { data, refetch, error: queryError } = useQuery(REQUEST_FORM_SHOW, {
    variables: { id: documentId },
    ...(((location.state as AssignmentLocationState)?.assignmentId ||
      assignment?.id) && {
      context: {
        headers: {
          Assignment:
            (location.state as AssignmentLocationState).assignmentId ||
            assignment?.id,
        },
      },
    }),
  });

  const [updateRequestForm, { error: mutationError }] = useMutation(
    REQUEST_FORM_UPDATE,
    {
      ...(((location.state as AssignmentLocationState)?.assignmentId ||
        assignment?.id) && {
        context: {
          headers: {
            Assignment: (location.state as AssignmentLocationState)
              .assignmentId,
          },
        },
      }),
    }
  );

  const onError = (errors: Record<string, unknown>, e: any) => {
    setFocusedInput(undefined);
    setErrorTimeStamp(e.timeStamp);
  };

  const onSubmit = async ({
    nrRejestr,
    caseName,
    organizationalUnit,
    documentNumber,
    orderType,
    orderSubject,
    requestFormItems,
    preferedExecutionBeginAt,
    preferedExecutionEndAt,
    beginAt,
    endAt,
    legalActSelect,
    legalDocumentsVerification,
    isContractAgreementRequired,
    controllingNote,
  }: RequestFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateRequestForm({
        variables: {
          requestFormUpdateInput: {
            id: parseInt(id),
            documentNumber,
            nrRejestr,
            orderType: orderType && orderType[0]?.label,
            orderSubject,
            caseId: caseName && caseName[0]?.id,
            documentType: "requestForm",
            documentKind: "Internal",
            organizationalUnitId:
              organizationalUnit && organizationalUnit[0]?.id,
            estimatedValueBy: "User",
            preferedExecutionBeginAt,
            preferedExecutionEndAt,
            beginAt,
            endAt,
            legalAct: legalActSelect?.length ? legalActSelect[0]?.id : null,
            legalDocumentsVerification: !!legalDocumentsVerification,
            isContractAgreementRequired: !!isContractAgreementRequired,
            controllingNote,
            ...(locationState?.requestFormNoteId && {
              requestFormNoteId: locationState?.requestFormNoteId,
            }),
          },
          requestFormItemUpdateInputs: requestFormItems.map(
            ({
              requestedExpenditure,
              realExpenditure,
              orderValue,
              isProjectBudget,
              year,
              budgetSymbol,
              categoryPath,
              categoryName,
              budgetName,
              amount,
              originalId,
            }) => ({
              requestedExpenditure,
              realExpenditure,
              orderValue,
              isProjectBudget,
              year,
              budgetSymbol,
              categoryPath,
              categoryName,
              budgetName,
              amount,
              ...(originalId && { id: originalId }),
            })
          ),
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.goBack();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => setIsFetching(true), []);

  const hasPermissionToUpdateRequestFormNrRejestr = checkPermission(
    PERMISSIONS.controlling.updateRequestFormNrRejestr
  );

  useEffect(() => {
    if (data?.requestForm?.currentAssignment) {
      setAssignment(data?.requestForm?.currentAssignment);
      setIsAssignmentActive(true);
    }
    if (data?.requestForm) setIsFetching(false);
    setValue("documentNumber", data?.requestForm?.documentNumber);
    hasPermissionToUpdateRequestFormNrRejestr &&
      setValue("nrRejestr", data?.requestForm?.nrRejestr);
    data?.requestForm?.beginAt &&
      setValue("beginAt", new Date(data?.requestForm?.beginAt));
    data?.requestForm?.endAt &&
      setValue("endAt", new Date(data?.requestForm?.endAt));
    data?.requestForm?.preferedExecutionBeginAt &&
      setValue(
        "preferedExecutionBeginAt",
        new Date(data?.requestForm?.preferedExecutionBeginAt)
      );
    data?.requestForm?.preferedExecutionEndAt &&
      setValue(
        "preferedExecutionEndAt",
        new Date(data?.requestForm?.preferedExecutionEndAt)
      );
    setValue("orderType", [
      {
        id: data?.requestForm?.orderType,
        label: data?.requestForm?.orderType,
      },
    ]);
    setValue("orderSubject", data?.requestForm?.orderSubject);
    setValue("controllingNote", data?.requestForm?.controllingNote);
    if (data?.requestForm?.case)
      setValue("caseName", [
        {
          id: data?.document?.case?.id,
          label: `[${data?.requestForm?.case?.number}] ${data?.requestForm?.case?.name}`,
          ...data?.requestForm?.case,
        },
      ]);
    setValue("organizationalUnit", [
      {
        id: data?.requestForm?.organizationalUnit.id,
        label: data?.requestForm?.organizationalUnit.name,
        ...data?.requestForm?.organizationalUnit,
      },
    ]);
    data?.requestForm?.legalDocumentsVerification &&
      setValue(
        "legalDocumentsVerification",
        data?.requestForm?.legalDocumentsVerification
      );
    data?.requestForm?.isContractAgreementRequired &&
      setValue(
        "isContractAgreementRequired",
        data?.requestForm?.isContractAgreementRequired
      );
    data?.requestForm?.legalAct &&
      setValue("legalActSelect", [
        {
          id: data?.requestForm?.legalAct,
        },
      ]);

    if (data?.requestForm?.requestFormItems) {
      setValue(
        "requestFormItems",
        data?.requestForm?.requestFormItems.map(
          (item: RequestFormItem) =>
            ({
              id: String(item.id),
              originalId: item.id,
              requestedExpenditure: item.requestedExpenditure,
              realExpenditure: item.realExpenditure,
              orderValue: item.orderValue,
              isProjectBudget: item.isProjectBudget,
              year: item.year,
              budgetSymbol: item.budgetSymbol,
              categoryPath: item.categoryPath,
              categoryName: item.categoryName,
              budgetName: item.budgetName,
              amount: item.amount,
              financialPlanName: item.budgetName,
            } as RequestFormTableItem)
        )
      );
    }
  }, [data]);

  useEffect(() => {
    if (errors) scrollOnError(errors, REQUEST_FORM_FIELDS, "edit");
  }, [errorTimeStamp]);

  if (
    data &&
    data?.requestForm &&
    (!checkPermission(PERMISSIONS.requestForm.update) ||
      !data?.requestForm?.isEditable)
  )
    <Redirect to="/" />;

  return (
    <article>
      <Header
        title={data?.requestForm?.documentNumber}
        labels={["Dokumenty", "Edytowanie"]}
        goBackOption
        hasActiveAssignmentBar
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              !(Object.keys(dirtyFields).length <= 1)
                ? setIsCancelConfirmDialogOpen(true)
                : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            disabled: isLoading,
            isLoading: isLoading,
            onClick: handleSubmit(onSubmit, onError),
            type: "submit",
            formId: "editRequestForm",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={4}>
            <FormControl label="Numer" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>
                  {data?.requestForm?.internalNumber}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={4}>
            <FormControl label="Data rejestracji" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType="datetime">
                  {data?.requestForm?.createdAt}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
          <Cell span={4}>
            <FormControl label="Numer wniosku" disabled={true}>
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue>
                  {data?.requestForm?.documentNumber}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <form
          id="editRequestForm"
          onSubmit={handleSubmit(onSubmit)}
          onFocus={() => {
            const inputName =
              typeof window !== "undefined" &&
              (window?.document?.activeElement?.id as keyof RequestFormInputs);

            inputName &&
              inputName !== focusedInput &&
              !inputName.includes("bui") &&
              setFocusedInput(inputName);
          }}
        >
          <Grid>
            {REQUEST_FORM_FIELDS.filter(
              (g) =>
                g.fields.filter((f) => f.edit?.visible).length > 0 &&
                (data?.requestForm?.currentStatus?.documentFlowStep
                  ?.isLegalSectionEnabled ||
                  (!data?.requestForm?.currentStatus?.documentFlowStep
                    ?.isLegalSectionEnabled &&
                    !["legalInformation", "otherInformations"].includes(g.id)))
            ).map((group) => [
              group.label &&
                ((group.id === "controllingNote" &&
                  checkPermission(
                    PERMISSIONS.controlling.updateRequestFormNote
                  )) ||
                  group.id !== "controllingNote") && (
                  <>
                    <Cell key={group.id + `-group`} span={12}>
                      <LabelMedium marginBottom="scale200" marginTop="scale600">
                        {group.label}
                      </LabelMedium>
                      <hr
                        className={css({
                          borderWidth: "0px",
                          height: "1px",
                          backgroundColor: "#eee",
                        })}
                      />
                    </Cell>
                  </>
                ),
              group.fields
                .filter((f) => f.edit?.visible)
                .map(
                  (item, index) =>
                    item.id &&
                    ((item.id === "controllingNote" &&
                      checkPermission(
                        PERMISSIONS.controlling.updateRequestFormNote
                      )) ||
                      item.id !== "controllingNote") &&
                    ((item.id === "nrRejestr" &&
                      checkPermission(
                        PERMISSIONS.controlling.updateRequestFormNrRejestr
                      )) ||
                      item.id !== "nrRejestr") && (
                      <Cell
                        span={item.span || 6}
                        skip={item.skip || 0}
                        key={group.id + `-field` + index}
                      >
                        <FormControl
                          label={
                            item.type === FIELDS.Checkbox ? "" : item.label
                          }
                          caption={item.caption}
                          disabled={
                            isLoading ||
                            (data?.requestForm?.currentStatus?.documentFlowStep
                              ?.isLegalSectionEnabled &&
                              ![
                                "legalInformation",
                                "otherInformations",
                              ].includes(group.id))
                          }
                          required={item.edit?.required}
                          error={
                            ((errors as any)[item.id] &&
                              (errors as any)[item.id].message) ||
                            (mutationError &&
                              mutationError.graphQLErrors[0]?.extensions
                                ?.code === "INPUT_VALIDATION_ERROR" &&
                              mutationError.graphQLErrors[0]?.extensions?.validationErrors
                                ?.find((vE: InputValidationError) =>
                                  item.id.includes("address")
                                    ? vE?.property ===
                                      item.id.slice(7).toLowerCase()
                                    : vE?.property === item.id
                                )
                                ?.errors.map((message: string) => (
                                  <div
                                    key="error"
                                    className={css({
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    })}
                                  >
                                    {message}
                                    <AlertOctagon color="#999" size={12} />
                                  </div>
                                ))[0])
                          }
                        >
                          {isFetching ? (
                            <Skeleton
                              rows={0}
                              height="36px"
                              width="100%"
                              animation
                            />
                          ) : item.type === FIELDS.CasesSelect ? (
                            <ControlledCasesSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.OrganizationalUnitsSelect ? (
                            <ControlledOrganizationalUnitsSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type ===
                            FIELDS.UsersOrganizationalUnitsSelect ? (
                            <ControlledUsersOrganizationalUnitsSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder="Wybierz"
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.DatePicker ? (
                            <ControlledDatePicker
                              name={item.id}
                              control={control}
                              {...(item.id === "beginAt" &&
                                watchFields.endAt && {
                                  maxDate: watchFields.endAt,
                                })}
                              {...(item.id === "endAt" &&
                                watchFields.beginAt && {
                                  minDate: watchFields.beginAt,
                                })}
                              {...(item.id === "preferedExecutionBeginAt" &&
                                watchFields.preferedExecutionEndAt && {
                                  maxDate: watchFields.preferedExecutionEndAt,
                                })}
                              {...(item.id === "preferedExecutionEndAt" &&
                                watchFields.preferedExecutionBeginAt && {
                                  minDate: watchFields.preferedExecutionBeginAt,
                                })}
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.DictionaryValuesSelect ? (
                            <ControlledDictionaryValuesSelect
                              dictionarySystemName="legal.basis"
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder={item.placeholder}
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.OrderTypeSelect ? (
                            <ControlledOrderTypeSelect
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder={item.placeholder}
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : item.type === FIELDS.RequestFormTable ? (
                            <ControlledRequestFormTable
                              formType="edit"
                              control={control}
                              name={item.id}
                              id={item.id}
                              hasFocus={focusedInput === item.id}
                            />
                          ) : item.type === FIELDS.Checkbox ? (
                            <ControlledCheckbox
                              control={control}
                              name={item.id}
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            >
                              {item.label}
                            </ControlledCheckbox>
                          ) : item.type === FIELDS.TextArea ? (
                            <ControlledTextArea
                              control={control}
                              name={item.id}
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                            />
                          ) : (
                            <ControlledInput
                              control={control}
                              name={item.id}
                              id={item.id}
                              placeholder={item.placeholder}
                              {...(item.edit.required && {
                                rules: {
                                  required: formValidation.messages.required,
                                },
                              })}
                              {...(item.id === "addressEmail" && {
                                rules: {
                                  pattern: {
                                    value: formValidation.patterns.email,
                                    message:
                                      formValidation.messages.incorrectEmail,
                                  },
                                },
                              })}
                              masked={item.mask !== undefined}
                              mask={item.mask}
                              maskChar="_"
                            />
                          )}
                        </FormControl>
                      </Cell>
                    )
                ),
              group.id === "table" && (
                <>
                  <Cell span={12}>
                    <LabelMedium marginBottom="scale200" marginTop="scale600">
                      Wartość wniosku
                    </LabelMedium>
                    <hr
                      className={css({
                        borderWidth: "0px",
                        height: "1px",
                        backgroundColor: "#eee",
                      })}
                    />
                  </Cell>
                  <Cell span={12} $style={{ position: "relative" }}>
                    {isFetching ? (
                      <Skeleton
                        rows={0}
                        height="100px"
                        width="100%"
                        animation
                      />
                    ) : (
                      data && (
                        <RequestFormValueTable
                          requestFormItems={data?.requestForm?.requestFormItems}
                          reloadPage={refetch}
                        />
                      )
                    )}
                  </Cell>
                </>
              ),
            ])}

            <ConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              label="Anulowanie edycji wniosku o wydatkowanie"
              close={() => setIsCancelConfirmDialogOpen(false)}
              confirm={() => history.goBack()}
            />
          </Grid>
        </form>
      </Content>
    </article>
  );
}
