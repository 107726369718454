import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  ArrowBack,
  ArrowBigRightLines,
  ArrowForwardUp,
  Cash,
  Check,
  ChevronDown,
  ClipboardList,
  Edit,
  EditOff,
  FileArrowLeft,
  FileCheck,
  FileX,
  FileZip,
  FolderPlus,
  Folders,
  HandGrab,
  HandOff,
  Link,
  Lock,
  LockOpen,
  Pencil,
  Send,
  Settings,
  Share,
  UserPlus,
  X,
} from "tabler-icons-react";

import AssignedDocumentsTable from "../../../../components/assigned-documents-table";
import AttachmentsTable from "../../../../components/attachments-table";
import Button from "../../../../components/button";
import Cell from "../../../../components/cell";
import Content from "../../../../components/content";
import DocumentBarcode from "../../../../components/document-barcode";
import {
  DocumentCommonModalsRenderer,
  DocumentModalName,
} from "../../../../components/document-common-modals-renderer";
import FlowActionModal from "../../../../components/flow-action-modal";
import FlowProgress from "../../../../components/flow-progress";
import FormControl from "../../../../components/form-control";
import FormattedValue from "../../../../components/formatted-value";
import Grid from "../../../../components/grid";
import Header from "../../../../components/header";
import HistoryTable from "../../../../components/history-table";
import FinancialAccountingDocumentItemsTable from "../../../../components/invoice-items-table";
import InvoicePaymentsTable from "../../../../components/invoice-payments-table";
import InvoiceWarningModal from "../../../../components/invoice-warning-modal";
import {
  DOCUMENT_STATES,
  HIDDEN_FILE,
  NOT_FOUND_ERROR,
} from "../../../../constants";
import { HIDDEN_ADDRESS } from "../../../../constants";
import { useAssignment } from "../../../../contexts/assignment-context";
import { useAuth } from "../../../../contexts/auth-context";
import { useDictionaries } from "../../../../contexts/dictionaries-context";
import { useLoading } from "../../../../contexts/loading-context";
import { useSubstitution } from "../../../../contexts/substitution-context";
import { FILTERS } from "../../../../filters.d";
import { checkPermission } from "../../../../utils/check-permission";
import {
  handleDataForProgressBar,
  handleHistoryFlowSteps,
} from "../../../../utils/document-flow";
import {
  checkIsDispatchable,
  checkIsShareable,
} from "../../../../utils/documents/assignment-actions";
import { renderMutationSuccessLabel } from "../../../../utils/documents/mutations-snackbar";
import { generateUUID } from "../../../../utils/misc";
import { PERMISSIONS } from "../../../../utils/permissions";
import Error404 from "../../../ErrorPages/404";
import {
  DocumentFlowAction,
  HistoryFlowStep,
} from "../../../Flow/document-flow";
import {
  AssignmentLocationState,
  AssignmentType,
} from "../../../Folders/folders.d";
import {
  DocumentLink,
  DocumentMutationAction,
  DocumentStatus,
  FinancialAccountingDocumentItem,
} from "../../documents.d";
import {
  ADDRESS_REVEAL,
  DOCUMENT_FLOW_AVAILABLE_ACTIONS,
  DOCUMENTS_ACCEPT,
  DOCUMENTS_CANCEL_DISPATCH,
  DOCUMENTS_COMPLETE_EDITION,
  DOCUMENTS_RELEASE_RESPONSIBLE,
  DOCUMENTS_SET_RESPONSIBLE,
  DOCUMENTS_START_EDITION,
  DOCUMENTS_TRACK_OPEN,
  DOCUMENTS_ZIP_EXPORT,
  FILES_REVEAL,
} from "../../documents.gql";
import { FINANCIAL_ACCOUNTING_DOCUMENTS_FIELDS } from "../financial-accounting-document.form";
import { FINANCIAL_ACCOUNTING_DOCUMENT_SHOW } from "../financial-accounting-document.gql";

export default function DocumentsShowFinancialAccoutingDocument(): React.ReactElement {
  const [isFlowActionModalOpen, setIsFlowActionModalOpen] = useState(false);
  const [targetFlowAction, setTargetFlowAction] = useState<
    DocumentFlowAction | undefined
  >(undefined);

  const [flowStepsHistory, setFlowStepsHistory] = useState<HistoryFlowStep[]>(
    []
  );
  const [statusesForProgressBar, setStatusesForProgressBar] = useState<
    DocumentStatus[]
  >([]);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);

  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [
    openedCommonModal,
    setOpenedCommonModal,
  ] = useState<DocumentModalName>();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);

  const [isInvoiceWarningModalOpen, setIsInvoiceWarningModalOpen] = useState(
    false
  );
  const { refetch: userRefetch, user } = useAuth();
  const { isFetching, setIsFetching, setIsLoading } = useLoading();
  const { findValue } = useDictionaries();
  const { substitution, isSubstitutionActive } = useSubstitution();

  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();
  const { state } = useLocation<AssignmentLocationState>();

  const assignmentContext = {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  };

  const { refetch, data, error } = useQuery(
    FINANCIAL_ACCOUNTING_DOCUMENT_SHOW,
    {
      variables: { id: documentId },
      ...((state?.assignmentId || assignment?.id) &&
        state?.assignmentId !== null && {
          context: {
            headers: {
              Assignment: state?.assignmentId || assignment?.id,
            },
          },
        }),
    }
  );

  const documentLinks = data?.financialAccountingDocument?.documentLinks;

  const parentDocuments = documentLinks?.filter(
    ({ type, linkerId }: DocumentLink) =>
      type === "Parent" && documentId === linkerId
  );

  const childrenDocuments = documentLinks?.filter(
    ({ type, linkedId }: DocumentLink) =>
      type === "Parent" && documentId === linkedId
  );

  const assignedDocuments = documentLinks?.filter(
    ({ type }: DocumentLink) => type === null
  );

  const files = data?.financialAccountingDocument?.files;

  const hasHiddenFiles =
    !!files?.length && files[0]?.__typename === HIDDEN_FILE;
  const hasBankAccount = !!data?.financialAccountingDocument?.sender
    ?.bankAccounts?.length;
  const {
    data: availableActionsData,
    refetch: availableActionsRefetch,
  } = useQuery(DOCUMENT_FLOW_AVAILABLE_ACTIONS, {
    variables: {
      documentId,
    },
  });

  const hasPaymentsAssigned = !!data?.financialAccountingDocument?.payments
    ?.length;

  const availableActionsButtons = availableActionsData?.documentFlowAvailableActions?.map(
    (action: DocumentFlowAction) => {
      return {
        label: action.label,
        kind: KIND.primary,
        startEnhancer:
          action.style === "positive" ? (
            <Check size={18} />
          ) : action.style === "negative" ? (
            <X size={18} />
          ) : (
            <Send size={18} />
          ),
        isPositive: action.style === "positive",
        isNegative: action.style === "negative",
        onClick: () => {
          setTargetFlowAction(action);
          !!action?.warnings?.length && !hasPaymentsAssigned
            ? setIsInvoiceWarningModalOpen(true)
            : setIsFlowActionModalOpen(true);
        },
      };
    }
  );

  const hasFlowSteps = !!data?.financialAccountingDocument?.currentStatus;
  const hasAnySignaturesBefore = statusesForProgressBar?.some(
    (status: DocumentStatus) => !!status.signatureDownloadUrl
  );

  const lastStatusWithSignature = statusesForProgressBar
    ?.filter((status: DocumentStatus) => !!status.signatureDownloadUrl)
    .pop();

  const tables = data?.financialAccountingDocument?.financialAccountingDocumentItems
    .map((item: { tableId: string }) => item.tableId)
    .filter(
      (value: string, index: number, self: any) => self.indexOf(value) === index
    )
    .map((id: string) => ({
      id,
    }));

  useEffect(() => {
    refetch();
  }, [data?.financialAccountingDocument?.currentStatus]);

  const [acceptDocument] = useMutation(DOCUMENTS_ACCEPT, assignmentContext);

  const [cancelDispatch] = useMutation(DOCUMENTS_CANCEL_DISPATCH);

  const [completeEdition] = useMutation(
    DOCUMENTS_COMPLETE_EDITION,
    assignmentContext
  );

  const [exportZipFile] = useMutation(DOCUMENTS_ZIP_EXPORT, assignmentContext);

  const [releaseResponsible] = useMutation(
    DOCUMENTS_RELEASE_RESPONSIBLE,
    assignmentContext
  );

  const [revealAddress, { loading: revealAddressLoading }] = useMutation(
    ADDRESS_REVEAL,
    assignmentContext
  );

  const [revealFiles, { loading: revealFilesLoading }] = useMutation(
    FILES_REVEAL,
    assignmentContext
  );

  const [setResponsible] = useMutation(
    DOCUMENTS_SET_RESPONSIBLE,
    assignmentContext
  );

  const [startEdition] = useMutation(
    DOCUMENTS_START_EDITION,
    assignmentContext
  );

  const [trackOpen] = useMutation(DOCUMENTS_TRACK_OPEN, assignmentContext);

  const documentMutationVariables = {
    variables: {
      documentId,
    },
  };

  const documentAddressMutationVariables = {
    variables: {
      documentId,
      addressId: data?.financialAccountingDocument?.sender?.id,
    },
  };

  const onDocumentMutationSubmit = async (
    action: DocumentMutationAction
  ): Promise<void> => {
    setIsLoading(true);

    try {
      if (action === DocumentMutationAction.Accept)
        await acceptDocument(documentMutationVariables);

      if (action === DocumentMutationAction.CancelDispatch)
        await cancelDispatch(documentMutationVariables);

      if (action === DocumentMutationAction.CompleteEdition)
        await completeEdition(documentMutationVariables);

      if (action === DocumentMutationAction.ExportZip) {
        const response = await exportZipFile(documentMutationVariables);

        const apiUrl = process.env.REACT_APP_GRAPHQL_API_URL?.replace(
          "/graphql",
          ""
        );

        window.open(
          `${apiUrl}/documents/download-zip-export/${id}/${response?.data?.documentExportZip}`,
          "_self"
        );
      }

      if (action === DocumentMutationAction.ReleaseResponsible)
        await releaseResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.RevealAddress) {
        await revealAddress(documentAddressMutationVariables);

        setIsAnyAddressRevealed(true);
      }

      if (action === DocumentMutationAction.RevealFiles)
        await revealFiles(documentMutationVariables);

      if (action === DocumentMutationAction.SetResponsible)
        await setResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.StartEdition)
        await startEdition(documentMutationVariables);

      enqueueSnackbar({
        message: renderMutationSuccessLabel(action),
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
      setOpenedCommonModal(undefined);
    }
  };

  useEffect(() => {
    if (
      error?.graphQLErrors &&
      (error as ApolloError).graphQLErrors?.map(
        ({ extensions }) => extensions?.code
      )[0] !== NOT_FOUND_ERROR
    )
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.financialAccountingDocument) refetch();
    if (availableActionsData?.documentFlowAvailableActions)
      availableActionsRefetch();
    setIsFetching(true);
    setStatusesForProgressBar([]);

    trackOpen({ variables: { id: documentId } }).then(() => userRefetch());
  }, []);

  useEffect(() => {
    if (availableActionsData) availableActionsRefetch();
  }, [data?.financialAccountingDocument?.currentStatus]);

  useEffect(() => {
    if (data?.financialAccountingDocument?.currentAssignment) {
      setAssignment(data?.financialAccountingDocument?.currentAssignment);
      setIsAssignmentActive(true);
    }
    setFlowStepsHistory(
      handleHistoryFlowSteps(data?.financialAccountingDocument?.statuses)
    );
    setStatusesForProgressBar(
      handleDataForProgressBar(data?.financialAccountingDocument?.statuses)
    );
    if (data?.financialAccountingDocument) setIsFetching(false);
    data?.financialAccountingDocument?.sender?.isSensitive
      ? setIsAnyAddressRevealed(true)
      : setIsAnyAddressRevealed(false);
  }, [data]);

  if (
    !checkPermission(PERMISSIONS.financialAccountingDocument.read) &&
    !checkPermission(PERMISSIONS.document.readAll)
  ) {
    return <Redirect to="/" />;
  } else if (
    error?.graphQLErrors &&
    (error as ApolloError).graphQLErrors?.map(
      ({ extensions }) => extensions?.code
    )[0] === NOT_FOUND_ERROR
  ) {
    return <Error404 />;
  }

  return (
    <article>
      <Header
        title={data?.financialAccountingDocument?.internalNumber}
        labels={[
          <FormattedValue
            key="label_1"
            dataType={"model:cases"}
            data={data?.financialAccountingDocument?.case?.id}
            additionalData={
              data?.financialAccountingDocument?.case?.isConductedElectronically
            }
            showBlankWhenEmpty
          >
            {`${data?.financialAccountingDocument?.case?.number}`}
          </FormattedValue>,
          "Dokument",
        ]}
        goBackOption
        hasActiveAssignmentBar
        hasCancellationBar={
          data?.financialAccountingDocument?.state === DOCUMENT_STATES.Canceled
        }
        buttons={[
          {
            label: "Przejmij",
            startEnhancer: <HandGrab size={18} />,
            kind: KIND.secondary,
            isPositive: true,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.SetResponsible),
            permission:
              data?.financialAccountingDocument?.isResponsibleSettable,
          },
          {
            label: "Zwolnij",
            startEnhancer: <HandOff size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(
                DocumentMutationAction.ReleaseResponsible
              ),
            permission:
              data?.financialAccountingDocument?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) &&
              data?.financialAccountingDocument?.isReleasable &&
              !data?.financialAccountingDocument?.isEditCompleted,
          },
          {
            label: "Zakończ edycję",
            startEnhancer: <EditOff size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.CompleteEdition),
            permission:
              data?.financialAccountingDocument?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) &&
              !data?.financialAccountingDocument?.isEditCompleted &&
              !data?.financialAccountingDocument?.isDocumentUsedInFlow,
          },
          {
            label: "Anuluj zakończenie edycji",
            startEnhancer: <Edit size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.StartEdition),
            permission:
              data?.financialAccountingDocument?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) &&
              data?.financialAccountingDocument?.isEditCompleted,
          },
          {
            label: "Załóż sprawę",
            kind: KIND.primary,
            startEnhancer: <Link size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            permission:
              !data?.financialAccountingDocument?.case?.id &&
              data?.financialAccountingDocument?.isEditable,
            subactions: [
              {
                label: "Załóż nową sprawę",
                icon: FolderPlus,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("case-create"),
                permission:
                  checkPermission(PERMISSIONS.case.create) &&
                  data?.financialAccountingDocument?.isEditable &&
                  data?.financialAccountingDocument?.state !==
                    DOCUMENT_STATES.Canceled,
              },
              {
                label: "Przypisz do istniejącej",
                icon: Folders,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("assign-to-case"),
                permission:
                  checkPermission(PERMISSIONS.document.update) &&
                  data?.financialAccountingDocument?.isEditable &&
                  data?.financialAccountingDocument?.state !==
                    DOCUMENT_STATES.Canceled,
              },
            ],
          },
          {
            label: "Udostępnij",
            kind: KIND.primary,
            startEnhancer: <Share size={18} />,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Udostępnij",
                icon: UserPlus,
                color: theme.colors.primary,
                permission: checkIsShareable(data?.financialAccountingDocument),
                onClick: () =>
                  history.push(
                    location.pathname + `/share`,
                    state ||
                      data?.financialAccountingDocument?.currentAssignment?.id
                      ? {
                          assignmentId:
                            data.financialAccountingDocument.currentAssignment
                              .id,
                        }
                      : undefined
                  ),
              },
              {
                label: "Zarządzaj dostępem",
                icon: Settings,
                color: theme.colors.contentPrimary,
                onClick: () =>
                  history.push(
                    `/folders/shared?filters=${window.btoa(
                      unescape(
                        encodeURIComponent(
                          JSON.stringify([
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "primaryAssignableId",
                                label: "Obiekt",
                                type: FILTERS.PrimaryAssignable,
                              },
                              state: {
                                type: "Document",
                                value: data?.financialAccountingDocument?.id,
                                internalNumber:
                                  data?.financialAccountingDocument
                                    ?.internalNumber,
                              },
                            },
                            {
                              uuid: generateUUID(),
                              filter: {
                                id: "status",
                                label: "Status",
                                type: FILTERS.AssignmentStatus,
                              },
                              state: {
                                value: [
                                  { id: "New", label: "Nowe" },
                                  { id: "InProgress", label: "W realizacji" },
                                ],
                              },
                            },
                          ])
                        )
                      )
                    )}`
                  ),
              },
            ],
            permission: !data?.financialAccountingDocument?.documentFlowId,
          },
          {
            label: "Przekaż",
            startEnhancer: <ArrowForwardUp size={18} />,
            kind: KIND.primary,
            endEnhancer: <ChevronDown size={18} />,
            subactions: [
              {
                label: "Przekaż dokument",
                icon: ArrowForwardUp,
                color: theme.colors.contentPrimary,
                onClick: () => setOpenedCommonModal("document-dispatch"),
              },
              {
                label: "Zwróć dokument",
                icon: ArrowBack,
                color: theme.colors.negative,
                onClick: () =>
                  setOpenedCommonModal("document-dispatch-with-dispatcher"),

                permission:
                  !!data?.financialAccountingDocument?.currentAssignment
                    ?.assigner?.id &&
                  data?.financialAccountingDocument?.currentAssignment?.type !==
                    AssignmentType.Initial,
              },
            ],
            permission: checkIsDispatchable(data?.financialAccountingDocument),
          },
          {
            label: "Anuluj przekazanie dokumentu",
            startEnhancer: <ArrowBack size={18} />,
            kind: KIND.secondary,
            isNegative: true,
            onClick: () => setOpenedCommonModal("cancel-assignment"),
            permission: data?.financialAccountingDocument?.isDispatchCancelable,
          },
          {
            label: "Akceptuj",
            startEnhancer: <Check size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              !data?.financialAccountingDocument?.documentFlowId &&
              data?.financialAccountingDocument?.isDispatchable &&
              !!data?.financialAccountingDocument?.currentAssignment
                ?.isAssessmentRequested &&
              data?.financialAccountingDocument?.state !==
                DOCUMENT_STATES.Canceled,
            onClick: () =>
              setOpenedCommonModal("document-assignment-assessment-accept"),
          },
          {
            label: "Odrzuć",
            startEnhancer: <X size={18} />,
            kind: KIND.primary,
            isNegative: true,
            permission:
              !data?.financialAccountingDocument?.documentFlowId &&
              data?.financialAccountingDocument?.isDispatchable &&
              !!data?.financialAccountingDocument?.currentAssignment
                ?.isAssessmentRequested &&
              data?.financialAccountingDocument?.state !==
                DOCUMENT_STATES.Canceled,
            onClick: () =>
              setOpenedCommonModal("document-assignment-assessment-reject"),
          },
          {
            label: "Rozpocznij obieg",
            startEnhancer: <ArrowBigRightLines size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              data?.financialAccountingDocument?.isEditable &&
              !data?.financialAccountingDocument?.documentFlowId &&
              !!data?.financialAccountingDocument?.documentFlow &&
              !data?.financialAccountingDocument?.isEditCompleted,
            onClick: () => setOpenedCommonModal("document-flow-start"),
          },
          ...(availableActionsButtons || []),
        ]}
        actions={[
          {
            label: "Edytuj dokument",
            icon: Pencil,
            permission: data?.financialAccountingDocument?.isEditable,
            onClick: () =>
              history.push(
                `/documents/${data?.financialAccountingDocument?.documentKind.toLowerCase()}/FinancialAccountingDocument/${id}/edit`,
                state
              ),
          },
          {
            label: "Akceptuj dokument",
            icon: FileCheck,
            color: theme.colors.positive,
            permission:
              data?.financialAccountingDocument?.isEditable &&
              ((data?.financialAccountingDocument?.isDispatchable &&
                !data?.financialAccountingDocument?.documentFlowId &&
                !data?.financialAccountingDocument?.currentAssignment
                  ?.isAssessmentRequested) ||
                !data?.financialAccountingDocument?.isDispatchable),
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.Accept),
          },
          {
            label: "Powiąż z dokumentem",
            icon: Link,
            color: theme.colors.primary,
            onClick: () => setOpenedCommonModal("document-link"),
            permission:
              data?.financialAccountingDocument?.isEditable &&
              data?.financialAccountingDocument?.state !==
                DOCUMENT_STATES.Canceled,
          },
          {
            label: "Utwórz polecenie płatności",
            icon: Cash,
            color: theme.colors.primary,
            onClick: () =>
              history.push({
                pathname: "/payments/create",
                state: {
                  document: data?.financialAccountingDocument,
                },
              }),
            permission:
              checkPermission(PERMISSIONS.payments.create) &&
              !!data?.financialAccountingDocument?.currentStatus
                ?.documentFlowStep?.isPaymentCreationEnabled,
          },
          {
            label: "Anuluj dokument",
            icon: FileX,
            color: theme.colors.negative,
            onClick: () => setOpenedCommonModal("document-cancel"),
            permission:
              checkPermission(PERMISSIONS.document.cancel) &&
              data?.financialAccountingDocument?.isEditable &&
              (data?.financialAccountingDocument?.state ===
                DOCUMENT_STATES.New ||
                data?.financialAccountingDocument?.currentStatus
                  ?.documentFlowStep?.isInitial),
          },
          {
            label: "Cofnij anulowanie dokumentu",
            icon: FileArrowLeft,
            color: theme.colors.positive,
            onClick: () => setOpenedCommonModal("document-uncancel"),
            permission:
              checkPermission(PERMISSIONS.document.cancel) &&
              data?.financialAccountingDocument?.state ===
                DOCUMENT_STATES.Canceled,
          },
          {
            label: "Pokaż logi dokumentu",
            icon: ClipboardList,
            color: theme.colors.black,
            onClick: () => setOpenedCommonModal("activity-logs"),
            permission: checkPermission(PERMISSIONS.document.read),
          },
          {
            label: "Pobierz plik ZIP",
            icon: FileZip,
            color: theme.colors.black,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.ExportZip),
          },
        ]}
      >
        <DocumentBarcode
          documentId={documentId}
          internalNumber={
            data?.financialAccountingDocument?.internalNumber as string
          }
        />
      </Header>

      <Content>
        <Grid>
          <Cell span={12}>
            <>
              {hasFlowSteps &&
                availableActionsData &&
                (isFetching ? (
                  <Skeleton rows={0} height="150px" width="100%" animation />
                ) : (
                  <FlowProgress
                    currentStep={
                      data?.financialAccountingDocument?.currentStatus
                        ?.documentFlowStep
                    }
                    originalOrganizationalUnitName={
                      data?.financialAccountingDocument?.organizationalUnit
                        ?.name
                    }
                    statuses={statusesForProgressBar}
                    actions={availableActionsData.documentFlowAvailableActions}
                  />
                ))}
            </>
          </Cell>
          {FINANCIAL_ACCOUNTING_DOCUMENTS_FIELDS.filter(
            (g) =>
              g.fields.filter(
                (f) =>
                  f.show?.visible &&
                  (!f.documentType ||
                    (f.documentType &&
                      f.documentType.includes(
                        data?.financialAccountingDocument?.documentType.toLowerCase()
                      )))
              ).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <>
                  <Block
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom="scale200"
                    marginTop="scale600"
                  >
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {data?.financialAccountingDocument?.sender?.__typename ===
                        HIDDEN_ADDRESS && group.id === "sender" ? (
                        <Lock
                          size={18}
                          color={theme.colors.negative}
                          className={css({ marginRight: "10px" })}
                        />
                      ) : (
                        group.id === "sender" && (
                          <LockOpen
                            size={18}
                            color={theme.colors.positive}
                            className={css({ marginRight: "10px" })}
                          />
                        )
                      )}
                      <LabelMedium>{group.label}</LabelMedium>
                      {data?.financialAccountingDocument?.sender?.__typename ===
                        HIDDEN_ADDRESS &&
                        group.id === "sender" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setOpenedCommonModal("confirm-address-reveal")
                            }
                            isLoading={revealAddressLoading}
                          >
                            Pokaż dane
                          </Button>
                        )}
                    </Block>
                    {data?.financialAccountingDocument?.sender?.__typename !==
                      HIDDEN_ADDRESS &&
                      isAnyAddressRevealed &&
                      group.id === "sender" && (
                        <FormattedValue
                          dataType="pre"
                          $style={{ fontSize: "13px" }}
                        >
                          Dostęp jest aktywny do końca trwania sesji
                        </FormattedValue>
                      )}
                  </Block>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </>
              </Cell>
            ),
            group.fields
              .filter(
                (f) =>
                  f.show?.visible &&
                  (!f.documentType ||
                    (f.documentType &&
                      f.documentType.includes(
                        data?.financialAccountingDocument?.documentType.toLowerCase()
                      )))
              )
              .map((item, index) => (
                <Cell
                  span={item.span || 6}
                  skip={item.skip || 0}
                  key={group.id + `-field` + index}
                >
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : item.dataType?.includes("model:users") ? (
                      <FormattedValue
                        dataType={item.dataType}
                        data={data?.financialAccountingDocument?.[item.id]?.id}
                      >
                        {data?.financialAccountingDocument?.[item.id] &&
                          `${
                            data?.financialAccountingDocument?.[item.id]
                              ?.firstName
                          } ${
                            data?.financialAccountingDocument?.[item.id]
                              ?.lastName
                          }`}
                      </FormattedValue>
                    ) : data?.financialAccountingDocument?.sender
                        ?.__typename === HIDDEN_ADDRESS &&
                      item.additionalData === "sensitive" ? (
                      <Skeleton
                        rows={0}
                        height="20px"
                        width="15ch"
                        overrides={{
                          Root: {
                            style: {
                              backgroundColor: "#faebeb",
                            },
                          },
                        }}
                      />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "case"
                            ? data &&
                              data?.financialAccountingDocument?.case?.id
                            : item.id === "organizationalUnit"
                            ? data &&
                              data?.financialAccountingDocument
                                ?.organizationalUnit?.id
                            : data &&
                              data?.financialAccountingDocument?.[item.id]
                        }
                        additionalData={
                          data?.financialAccountingDocument?.case?.[
                            item.additionalData
                          ]
                        }
                        {...(item.id !== "exchangeRate" && {
                          currency:
                            data?.financialAccountingDocument?.currency?.code,
                        })}
                        {...((item.id === "accountCurrency" ||
                          item.id === "currency") && {
                          textWhenEmpty: "PLN",
                        })}
                      >
                        {item.dataType?.includes("cases") ||
                        item.dataType?.includes("documents")
                          ? data?.financialAccountingDocument?.[item.id]
                            ? item.dataType === "model:documents"
                              ? `[${
                                  data?.financialAccountingDocument
                                    ?.documentLinks?.[0]?.linked &&
                                  (data?.financialAccountingDocument
                                    ?.documentLinks?.[0]?.linked
                                    ?.documentType === "requestForm"
                                    ? data?.financialAccountingDocument
                                        ?.documentLinks?.[0]?.linked
                                        ?.documentNumber
                                    : data?.financialAccountingDocument
                                        ?.documentLinks?.[0]?.linked
                                        ?.internalNumber)
                                }] ${findValue(
                                  data?.financialAccountingDocument
                                    ?.documentLinks?.[0]?.linked?.documentType
                                )}`
                              : item.dataType === "model:cases"
                              ? `[${data?.financialAccountingDocument?.case?.number}] ${data?.financialAccountingDocument?.case?.name}`
                              : data?.financialAccountingDocument?.[item.id].id
                            : null
                          : item.id === "documentType"
                          ? findValue(
                              data?.financialAccountingDocument?.documentType
                            )
                          : item.id === "legalAct"
                          ? findValue(
                              data?.financialAccountingDocument
                                ?.documentLinks?.[0]?.linked?.legalAct
                            )
                          : item.id === "invoiceType"
                          ? findValue(
                              data?.financialAccountingDocument?.invoiceType
                            )
                          : item.id === "payeeName" && hasBankAccount
                          ? data?.financialAccountingDocument?.bankAccount
                              ?.payeeName
                          : item.id === "accountNumber" && hasBankAccount
                          ? data?.financialAccountingDocument?.bankAccount
                              ?.accountNumber
                          : item.id === "bankName" && hasBankAccount
                          ? data?.financialAccountingDocument?.bankAccount
                              ?.bankName
                          : item.id === "bankCode" && hasBankAccount
                          ? data?.financialAccountingDocument?.bankAccount
                              ?.bankCode
                          : item.id === "accountType" && hasBankAccount
                          ? data?.financialAccountingDocument?.bankAccount
                              ?.accountType
                          : item.id === "addressAddress"
                          ? data?.financialAccountingDocument?.sender
                              ?.streetName
                            ? `${
                                data?.financialAccountingDocument?.sender
                                  ?.streetName
                              } ${
                                data?.financialAccountingDocument?.sender
                                  ?.buildingNumber
                              }${
                                data?.financialAccountingDocument?.sender
                                  ?.apartmentNumber
                                  ? "/" +
                                    data?.financialAccountingDocument?.sender
                                      ?.apartmentNumber
                                  : ""
                              }`
                            : data?.financialAccountingDocument?.sender?.address
                          : item.show.accessor
                          ? data?.financialAccountingDocument?.[
                              item.show.accessor[0]
                            ]?.[item.show.accessor[1]]
                          : data?.financialAccountingDocument?.[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Pozycje dokumentu
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          {tables?.length && data ? (
            tables?.map((table: { id: string }) => {
              const tableItems = data?.financialAccountingDocument?.financialAccountingDocumentItems?.filter(
                (item: FinancialAccountingDocumentItem) =>
                  item?.tableId === table?.id
              );
              const hasRequestForm = tableItems.some(
                (item: FinancialAccountingDocumentItem) =>
                  !!item.requestFormItem
              );
              const requestForm = hasRequestForm
                ? tableItems[
                    tableItems.findIndex(
                      (item: FinancialAccountingDocumentItem) =>
                        !!item.requestFormItem
                    )
                  ].requestFormItem.requestForm
                : null;

              return (
                <Cell
                  span={12}
                  $style={{ position: "relative" }}
                  key={table.id}
                >
                  {isFetching ? (
                    <Skeleton rows={0} height="300px" width="100%" animation />
                  ) : (
                    <>
                      <Block paddingTop="scale200">
                        <FormControl disabled label="Wniosek o wydatkowanie">
                          {hasRequestForm ? (
                            <FormattedValue
                              {...(hasRequestForm && {
                                dataType: "model:documents",
                                data: `internal/RequestForm/${requestForm?.id}`,
                              })}
                            >
                              {requestForm?.documentNumber}
                            </FormattedValue>
                          ) : (
                            <FormattedValue />
                          )}
                        </FormControl>
                      </Block>
                      <Block paddingTop="scale200" paddingBottom="scale600">
                        <FinancialAccountingDocumentItemsTable
                          tableItems={tableItems}
                          currency={
                            data?.financialAccountingDocument?.currency?.code
                          }
                        />
                      </Block>
                    </>
                  )}
                </Cell>
              );
            })
          ) : (
            <Cell span={12}>
              {isFetching ? (
                <Skeleton rows={0} height="200px" width="100%" animation />
              ) : (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <FinancialAccountingDocumentItemsTable
                    tableItems={[]}
                    currency={data?.financialAccountingDocument?.currency?.code}
                  />
                </Block>
              )}
            </Cell>
          )}

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Polecenia płatności
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>

          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : (
              <Block paddingTop="scale200" paddingBottom="scale600">
                <InvoicePaymentsTable
                  financialAccountingDocumentId={documentId}
                />
              </Block>
            )}
          </Cell>

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty nadrzędne {!parentDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>

          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!parentDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={data?.financialAccountingDocument?.id}
                    data={parentDocuments}
                    assignmentId={
                      data.financialAccountingDocument.currentAssignment?.id
                    }
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty podrzędne {!childrenDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>

          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!childrenDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={documentId}
                    data={childrenDocuments}
                    assignmentId={
                      data.financialAccountingDocument.currentAssignment?.id
                    }
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>

          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Dokumenty powiązane {!assignedDocuments?.length && "(0)"}
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>

          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data &&
              !!assignedDocuments?.length && (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <AssignedDocumentsTable
                    documentId={documentId}
                    data={assignedDocuments}
                    assignmentId={
                      data.financialAccountingDocument.currentAssignment?.id
                    }
                    onCompleted={refetch}
                  />
                </Block>
              )
            )}
          </Cell>

          {hasFlowSteps && (
            <>
              <Cell span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  Historia obiegu dokumentu{" "}
                  {!flowStepsHistory?.length && "(brak)"}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
              <Cell span={12}>
                {isFetching ? (
                  <Skeleton rows={0} height="200px" width="100%" animation />
                ) : flowStepsHistory?.length ? (
                  <Block paddingTop="scale200" paddingBottom="scale600">
                    <HistoryTable
                      documentStatuses={
                        data?.financialAccountingDocument?.statuses
                      }
                      flowStepsHistory={flowStepsHistory}
                    />
                  </Block>
                ) : (
                  <></>
                )}
              </Cell>
            </>
          )}

          <Cell span={12}>
            <>
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="scale200"
                marginTop="scale600"
              >
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {hasHiddenFiles
                    ? !!files?.length && (
                        <Lock
                          size={18}
                          color={theme.colors.negative}
                          className={css({ marginRight: "10px" })}
                        />
                      )
                    : !!files?.length && (
                        <LockOpen
                          size={18}
                          color={theme.colors.positive}
                          className={css({ marginRight: "10px" })}
                        />
                      )}
                  <LabelMedium>Załączniki {` (${files?.length})`}</LabelMedium>

                  {hasHiddenFiles && (
                    <Button
                      size={SIZE.mini}
                      kind={KIND.secondary}
                      $style={{ marginLeft: "10px" }}
                      onClick={() =>
                        setOpenedCommonModal("confirm-files-reveal")
                      }
                      isLoading={revealFilesLoading}
                    >
                      Pokaż pliki
                    </Button>
                  )}
                </Block>
                {!hasHiddenFiles && !!files?.length && (
                  <FormattedValue dataType="pre" $style={{ fontSize: "13px" }}>
                    Dostęp jest aktywny do końca trwania sesji
                  </FormattedValue>
                )}
              </Block>

              {!!files?.length && (
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              )}
            </>
          </Cell>
          <Cell span={12}>
            {isFetching ? (
              <Skeleton rows={0} height="200px" width="100%" animation />
            ) : hasHiddenFiles ? (
              <Skeleton
                rows={0}
                height="20px"
                width="100%"
                overrides={{
                  Root: {
                    style: {
                      backgroundColor: "#faebeb",
                    },
                  },
                }}
              />
            ) : files?.length ? (
              <Block paddingTop="scale200" paddingBottom="scale600">
                <AttachmentsTable data={files} />
              </Block>
            ) : (
              <></>
            )}
          </Cell>

          {targetFlowAction && (
            <FlowActionModal
              documentId={documentId}
              action={targetFlowAction}
              hasAnySignaturesBefore={hasAnySignaturesBefore}
              isOpen={isFlowActionModalOpen}
              label={
                data?.financialAccountingDocument?.internalNumber
                  ? `faktura ${data?.financialAccountingDocument?.internalNumber}`
                  : ""
              }
              lastStatusWithSignature={lastStatusWithSignature}
              reloadPage={() => {
                refetch();
                availableActionsRefetch();
              }}
              setIsOpen={setIsFlowActionModalOpen}
            />
          )}

          <InvoiceWarningModal
            isOpen={isInvoiceWarningModalOpen}
            setIsOpen={setIsInvoiceWarningModalOpen}
            accept={() => setIsFlowActionModalOpen(true)}
          />

          <DocumentCommonModalsRenderer
            openedCommonModal={openedCommonModal}
            close={() => setOpenedCommonModal(undefined)}
            documentId={documentId}
            document={data?.financialAccountingDocument}
            refetch={refetch}
            onDocumentMutationSubmit={onDocumentMutationSubmit}
          />
        </Grid>
      </Content>
    </article>
  );
}
