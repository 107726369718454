import { gql } from "@apollo/client";

export const DOCUMENT_FLOWS = gql`
  query {
    documentFlows {
      id
      name
      documentType
      documentKind
      description
      isActive
      createdAt
      updatedAt
      steps {
        id
      }
    }
  }
`;

export const DOCUMENT_FLOW = gql`
  query($id: Int!) {
    documentFlow(id: $id) {
      id
      name
      documentType
      documentKind
      description
      isActive
      createdAt
      updatedAt
      steps {
        id
        name
        isInitial
        hiddenDocumentFields
        disabledDocumentFields
        isDeletable
        isPaymentCreationEnabled
        isLegalSectionEnabled
        isProcessedByDocumentFlowStarter
        organizationalUnitId
        organizationalUnit {
          id
          name
          symbol
        }
        groupId
        group {
          name
        }
        documentFlowId
        users {
          id
          firstName
          lastName
        }
        positions {
          name
        }
      }
      actions {
        id
        label
        style
        isCommentRequired
        isSignatureFileRequired
        sendDocumentToPlatnosci
        isEndingDocumentFlow
        resultingDocumentState
        requiredDocumentFields
        startDocumentFlowStepId
        endDocumentFlowStepId
        startDocumentFlowStep {
          id
          name
        }
        endDocumentFlowStep {
          id
          name
        }
        documentFlowId
      }
    }
  }
`;

export const DOCUMENT_FLOW_CREATE = gql`
  mutation($documentFlowCreateInput: DocumentFlowCreateInput!) {
    documentFlowCreate(documentFlowCreateInput: $documentFlowCreateInput) {
      id
    }
  }
`;

export const DOCUMENT_FLOW_UPDATE = gql`
  mutation($documentFlowUpdateInput: DocumentFlowUpdateInput!) {
    documentFlowUpdate(documentFlowUpdateInput: $documentFlowUpdateInput) {
      id
    }
  }
`;

export const DOCUMENT_FLOW_DELETE = gql`
  mutation(
    $documentFlowDeleteInput: DocumentFlowDeleteInput!
    $passwordConfirmationInput: PasswordConfirmationInput!
  ) {
    documentFlowDelete(
      documentFlowDeleteInput: $documentFlowDeleteInput
      passwordConfirmationInput: $passwordConfirmationInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_FLOW_SET_AS_ACTIVE = gql`
  mutation($documentFlowSetAsActiveInput: DocumentFlowSetAsActiveInput!) {
    documentFlowSetAsActive(
      documentFlowSetAsActiveInput: $documentFlowSetAsActiveInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_FLOW_STEPS = gql`
  query {
    documentFlowSteps {
      id
      name
      isInitial
      hiddenDocumentFields
      disabledDocumentFields
      isDeletable
      isPaymentCreationEnabled
      isLegalSectionEnabled
      isProcessedByDocumentFlowStarter
      organizationalUnitId
      organizationalUnit {
        id
        name
        symbol
      }
      documentFlowId
      users {
        id
        firstName
        lastName
      }
      positions {
        name
      }
    }
  }
`;

export const DOCUMENT_FLOW_STEP = gql`
  query($id: Int!) {
    documentFlowStep(id: $id) {
      id
      name
      isInitial
      hiddenDocumentFields
      disabledDocumentFields
      isDeletable
      isPaymentCreationEnabled
      isLegalSectionEnabled
      isProcessedByDocumentFlowStarter
      organizationalUnitId
      organizationalUnit {
        id
        name
        symbol
      }
      groupId
      group {
        name
      }
      documentFlowId
      documentFlow {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
      positions {
        id
        name
      }
    }
  }
`;

export const DOCUMENT_FLOW_STEP_CREATE = gql`
  mutation($documentFlowStepCreateInput: DocumentFlowStepCreateInput!) {
    documentFlowStepCreate(
      documentFlowStepCreateInput: $documentFlowStepCreateInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_FLOW_STEP_UPDATE = gql`
  mutation($documentFlowStepUpdateInput: DocumentFlowStepUpdateInput!) {
    documentFlowStepUpdate(
      documentFlowStepUpdateInput: $documentFlowStepUpdateInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_FLOW_STEP_DELETE = gql`
  mutation($documentFlowStepDeleteInput: DocumentFlowStepDeleteInput!) {
    documentFlowStepDelete(
      documentFlowStepDeleteInput: $documentFlowStepDeleteInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_FLOW_ACTIONS = gql`
  query {
    documentFlowActions {
      nodes {
        id
        label
        style
        isCommentRequired
        isSignatureFileRequired
        resultingDocumentState
        requiredDocumentFields
        synchronizeDocumentWithRejestr
        sendDocumentToPlatnosci
        isEndingDocumentFlow
        startDocumentFlowStep {
          name
        }
        startDocumentFlowStepId
        endDocumentFlowStep {
          name
        }
        endDocumentFlowStepId
        documentFlowId
      }
    }
  }
`;

export const DOCUMENT_FLOW_ACTION = gql`
  query($id: Int!) {
    documentFlowAction(id: $id) {
      id
      label
      style
      notificationContent
      isCommentRequired
      isSignatureFileRequired
      resultingDocumentState
      requiredDocumentFields
      sendDocumentToPlatnosci
      isEndingDocumentFlow
      synchronizeDocumentWithRejestr
      startDocumentFlowStep {
        name
      }
      startDocumentFlowStepId
      endDocumentFlowStep {
        name
      }
      endDocumentFlowStepId
      documentFlowId
      documentFlow {
        id
        name
        documentType
      }
      warnings
    }
  }
`;

export const DOCUMENT_FLOW_ACTION_CREATE = gql`
  mutation($documentFlowActionCreateInput: DocumentFlowActionCreateInput!) {
    documentFlowActionCreate(
      documentFlowActionCreateInput: $documentFlowActionCreateInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_FLOW_ACTION_UPDATE = gql`
  mutation($documentFlowActionUpdateInput: DocumentFlowActionUpdateInput!) {
    documentFlowActionUpdate(
      documentFlowActionUpdateInput: $documentFlowActionUpdateInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_FLOW_ACTION_DELETE = gql`
  mutation($documentFlowActionDeleteInput: DocumentFlowActionDeleteInput!) {
    documentFlowActionDelete(
      documentFlowActionDeleteInput: $documentFlowActionDeleteInput
    ) {
      id
    }
  }
`;
