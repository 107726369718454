import { gql } from "@apollo/client";

export const ALL_DOCUMENTS_FOLDER = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [FullDocumentSort!]
    $filter: FullDocumentFilter
  ) {
    allDocumentsFolder(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        __typename
        ... on Document {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          isEditable
          title
          name
          additionalCode
          isDispatchable
          isShareable
          description
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on ContractPreparationRequest {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          isEditable
          title
          name
          isDispatchable
          isShareable
          additionalCode
          description
          grossValueInPLN
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on RequestFormNote {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          name
          isDispatchable
          isShareable
          additionalCode
          documentDate
          createdAt
          postStampAt
          isEditable
          title
          noteDescription: description
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on ContractAgreement {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          name
          isDispatchable
          isShareable
          additionalCode
          documentDate
          createdAt
          postStampAt
          isEditable
          title
          description
          grossValueInPLN
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on RequestForm {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          name
          isDispatchable
          isShareable
          additionalCode
          documentDate
          createdAt
          postStampAt
          isEditable
          title
          description
          totalValue
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on FinancialAccountingDocument {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          isEditable
          title
          name
          isDispatchable
          isShareable
          additionalCode
          description
          grossValue
          amountInCurrency
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          currency {
            code
            id
          }
          currencyExchangeRate {
            id
            value
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on BillIssuanceRequest {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          isEditable
          title
          name
          isDispatchable
          isShareable
          additionalCode
          description
          grossValue
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on Document {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          name
          isDispatchable
          isShareable
          additionalCode
          documentDate
          createdAt
          postStampAt
          isEditable
          title
          description
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
      }
    }
  }
`;

export const ALL_DOCUMENTS = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [FullDocumentSort!]
    $filter: FullDocumentFilter
  ) {
    documents(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        __typename
        ... on Document {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          createdAt
          title
          description
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on ContractPreparationRequest {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          createdAt
          title
          description
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on RequestFormNote {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          createdAt
          title
          noteDescription: description
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on ContractAgreement {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          createdAt
          title
          description
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on RequestForm {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          createdAt
          title
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on FinancialAccountingDocument {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          createdAt
          title
          description
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on BillIssuanceRequest {
          id
          documentNumber
          internalNumber
          documentKind
          documentType
          type
          createdAt
          title
          description
          responsible {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
      }
    }
  }
`;

export const DOCUMENTS_CREATE = gql`
  mutation DocumentCreate(
    $documentCreateInput: DocumentCreateInput!
    $files: [Upload!]
    $addressInput: AddressInput
  ) {
    documentCreate(
      documentCreateInput: $documentCreateInput
      documentFiles: $files
      addressInput: $addressInput
    ) {
      id
      documentKind
    }
  }
`;

export const DOCUMENTS_UPDATE = gql`
  mutation DocumentUpdate(
    $documentUpdateInput: DocumentUpdateInput!
    $files: [Upload!]
    $addressInput: AddressInput
  ) {
    documentUpdate(
      documentUpdateInput: $documentUpdateInput
      documentFiles: $files
      addressInput: $addressInput
    ) {
      id
    }
  }
`;

export const DOCUMENTS_SHOW = gql`
  query($id: Int!) {
    document(id: $id) {
      id
      isReleasable
      documentLinks {
        id
        linkerId
        linker {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        linkedId
        linked {
          ... on RequestForm {
            internalNumber
            legalAct
            id
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on RequestFormNote {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on BillIssuanceRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractPreparationRequest {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on ContractAgreement {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on FinancialAccountingDocument {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Payment {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
          ... on Document {
            id
            internalNumber
            documentType
            type
            documentNumber
            documentKind
            createdAt
          }
        }
        type
        createdAt
      }
      raksDiaryNumber
      documentRoot {
        ... on RequestForm {
          internalNumber
          legalAct
          id
          documentType
          type
          documentNumber
          documentKind
        }
        ... on ContractAgreement {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on FinancialAccountingDocument {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on Payment {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on Document {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on BillIssuanceRequest {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
        ... on ContractPreparationRequest {
          id
          internalNumber
          documentType
          type
          documentNumber
          documentKind
        }
      }
      responsible {
        id
        firstName
        lastName
      }
      isResponsibleSettable
      isEditCompleted
      createdBy {
        id
        firstName
        lastName
      }
      children {
        id
        documentNumber
        internalNumber
        documentKind
        documentType
        type
        documentDate
        createdAt
        postStampAt
      }
      isFullyDigitallyReproduced
      documentNumber
      description
      title
      name
      isElectronical
      shippingProvider
      additionalCode
      programName
      internalNumber
      documentKind
      isEditable
      documentDate
      inflowDate
      handDelivered
      documentType
      type
      createdAt
      postStampAt
      handDelivered
      state
      documentPickupId
      documentPickup {
        id
        state
      }
      sender {
        __typename
        ... on User {
          id
          firstName
          lastName
          organizationalUnitToUsers {
            organizationalUnit {
              id
              name
            }
          }
        }
        ... on Address {
          id
          address
          streetName
          buildingNumber
          apartmentNumber
          mobileNumber
          name
          city
          postalCode
          nip
          phoneNumber
          name
          countryCode
          email
          isSensitive
        }
        ... on HiddenAddress {
          id
          name
          bankAccounts {
            id
            payeeName
            currency
            accountNumber
            bankName
            accountType
            bankCode
          }
        }
      }
      recipient {
        __typename
        ... on User {
          id
          firstName
          lastName
          organizationalUnitToUsers {
            organizationalUnit {
              id
              name
            }
          }
        }
        ... on Address {
          id
          address
          streetName
          buildingNumber
          apartmentNumber
          mobileNumber
          city
          postalCode
          nip
          phoneNumber
          name
          countryCode
          email
          isSensitive
        }
        ... on HiddenAddress {
          id
          name
        }
      }
      case {
        id
        name
        number
        isConductedElectronically
      }
      caseId
      organizationalUnit {
        id
        symbol
        name
      }
      createdAt
      updatedAt
      currentStatus {
        documentFlowStepId
        documentFlowStep {
          id
          name
          isInitial
          isProcessedByDocumentFlowStarter
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
          }
          group {
            id
            name
          }
          users {
            firstName
            lastName
          }
        }
        createdAt
      }
      statuses {
        id
        comment
        signatureFilePath
        signatureFileMimetype
        signatureDownloadUrl
        user {
          id
          firstName
          lastName
          position
        }
        userPosition
        userOrganizationalUnits
        documentFlowRecipients
        substituter {
          id
          firstName
          lastName
          position
        }
        documentFlowStep {
          isPaymentCreationEnabled
          isLegalSectionEnabled
          organizationalUnit {
            id
            name
            symbol
          }
          users {
            id
            firstName
            lastName
          }
          positions {
            id
            name
          }
          id
          name
          isProcessedByDocumentFlowStarter
        }
        documentFlowAction {
          label
          style
          resultingDocumentState
          isEndingDocumentFlow
        }
        createdAt
      }
      documentFlowId
      isDocumentUsedInFlow
      documentFlow {
        id
        name
        steps {
          id
          name
        }
      }
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
      documentParcels {
        id
        width
        height
        length
        weight
        document {
          documentType
          id
        }
        shipmentRate {
          id
          description
          name
          symbol
          price
          shipmentContractId
          eNadawcaShipmentType
        }
      }
      activityLogs {
        id
        activity
        description
        user {
          firstName
          lastName
          id
        }
        substituter {
          id
          firstName
          lastName
        }
        properties
        createdAt
        userOrganizationalUnits
        assignmentUsers
      }
      isDispatchable
      isDispatchCancelable
      isShareable
      shippedAt
      currentAssignment {
        id
        primaryAssignableId
        isAssessmentRequested
        assignerComment
        createdAt
        type
        status
        assigner {
          id
          firstName
          lastName
        }
        assignee {
          id
          firstName
          lastName
        }
        substituter {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const DOCUMENTS_REGISTER = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [FullDocumentSort!]
    $filter: FullDocumentFilter
  ) {
    documents(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        documentNumber
        internalNumber
        documentKind
        documentType
        type
        isEditable
        documentDate
        createdAt
        postStampAt
        recipient {
          __typename
          ... on User {
            id
            firstName
            lastName
          }
          ... on Address {
            id
            address
          }
        }
        sender {
          __typename
          ... on User {
            id
            firstName
            lastName
          }
          ... on Address {
            id
            address
          }
        }
        responsible {
          id
          firstName
          lastName
        }
        createdBy {
          id
          firstName
          lastName
        }
        case {
          id
          name
        }
        organizationalUnit {
          id
          name
        }
        documentPickupId
      }
    }
  }
`;

export const DOCUMENTS_ASSIGN_TO_CASE = gql`
  mutation($documentCaseAssignInput: DocumentCaseAssignInput!) {
    documentCaseAssign(documentCaseAssignInput: $documentCaseAssignInput) {
      id
    }
  }
`;

export const DOCUMENTS_LINK = gql`
  mutation LinkDocument($documentLinkCreateInput: DocumentLinkCreateInput!) {
    documentLinkCreate(documentLinkCreateInput: $documentLinkCreateInput) {
      id
    }
  }
`;
export const DOCUMENTS_UNLINK = gql`
  mutation($documentLinkDeleteInput: DocumentLinkDeleteInput!) {
    documentLinkDelete(documentLinkDeleteInput: $documentLinkDeleteInput) {
      id
    }
  }
`;

export const ASSIGN_INVOICE_TO_REQUEST_FORM = gql`
  mutation($requestFormAssignInvoiceInput: RequestFormAssignInvoiceInput!) {
    assignInvoice(
      requestFormAssignInvoiceInput: $requestFormAssignInvoiceInput
    ) {
      financialAccountingDocument {
        id
      }
      requestForm {
        id
      }
    }
  }
`;

export const DOCUMENT_FLOW_AVAILABLE_ACTIONS = gql`
  query($documentId: Int!) {
    documentFlowAvailableActions(documentId: $documentId) {
      id
      label
      style
      isCommentRequired
      isSignatureFileRequired
      endDocumentFlowStepId
      endDocumentFlowStep {
        id
        name
      }
      warnings
      documentFlow {
        documentType
      }
    }
  }
`;

export const DOCUMENT_FLOW_ACTION_EXECUTE = gql`
  mutation(
    $documentFlowActionExecuteInput: DocumentFlowActionExecuteInput!
    $documentFlowActionExecuteSignatureFile: Upload
  ) {
    documentFlowActionExecute(
      documentFlowActionExecuteInput: $documentFlowActionExecuteInput
      documentFlowActionExecuteSignatureFile: $documentFlowActionExecuteSignatureFile
    ) {
      id
    }
  }
`;

export const ADDRESS_REVEAL = gql`
  mutation($documentId: Int!, $addressId: Int!) {
    documentAddressReveal(
      documentAddressRevealInput: {
        documentId: $documentId
        addressId: $addressId
      }
    ) {
      id
    }
  }
`;

export const FILES_REVEAL = gql`
  mutation RevealFiles($documentId: Int!) {
    documentFilesReveal(documentFilesRevealInput: { documentId: $documentId }) {
      id
    }
  }
`;

export const BUDGET_CATEGORIES_INDEX = gql`
  query {
    budgetCategories {
      id
      name
      path
    }
  }
`;

export const BUDGETS_INDEX = gql`
  query {
    budgets {
      id
      name
      financingName
    }
  }
`;

export const BUDGETS_ITEMS = gql`
  query {
    budgetItems(paging: { limit: 1000 }) {
      nodes {
        id
        budgetValue
        budgetCategory {
          id
          name
          uniquePath
          path
        }
        budgetCategoryId
        budget {
          id
          name
          financingName
          note
          description
        }
      }
    }
  }
`;

export const GET_ADDRESS_FROM_GUS = gql`
  query($addressFromGUSQueryInput: AddressFromGUSQueryInput!) {
    addressFromGUS(addressFromGUSQueryInput: $addressFromGUSQueryInput) {
      name
      nip
      city
      address
      streetName
      buildingNumber
      apartmentNumber
      mobileNumber
      postalCode
      countryCode
    }
  }
`;

export const SHOW_MATCHING_SHIPMENT_RATES = gql`
  query(
    $width: Int!
    $height: Int!
    $length: Int!
    $weight: Int!
    $isCountryService: Boolean
    $countryCode: String!
  ) {
    allShipmentRatesForPackageSize(
      packageSize: {
        width: $width
        height: $height
        length: $length
        weight: $weight
        isCountryService: $isCountryService
        countryCode: $countryCode
      }
    ) {
      id
      symbol
      name
      price
      widthMin
      widthMax
      lengthMin
      lengthMax
      heightMin
      heightMax
      weightMin
      weightMax
      price
    }
  }
`;

export const DOCUMENTS_ADD_TO_SENDING_LIST = gql`
  mutation($addDocumentToSendingListInput: AddDocumentToSendingListInput!) {
    addToSendingList(
      addDocumentToSendingListInput: $addDocumentToSendingListInput
    ) {
      id
    }
  }
`;

export const DOCUMENTS_XML_EXPORT = gql`
  mutation($documentXmlExportInput: DocumentXmlExportInput!) {
    documentXmlExport(documentXmlExportInput: $documentXmlExportInput)
  }
`;

export const DOCUMENTS_BARCODE_EXPORT = gql`
  mutation($documentPdfLabelGenerateInput: DocumentPdfLabelGenerateInput!) {
    documentPdfLabelGenerate(
      documentPdfLabelGenerateInput: $documentPdfLabelGenerateInput
    )
  }
`;

export const DOCUMENT_CANCEL = gql`
  mutation($documentCancelInput: DocumentCancelInput!) {
    documentCancel(documentCancelInput: $documentCancelInput) {
      id
    }
  }
`;

export const DOCUMENT_UNCANCEL = gql`
  mutation($documentUncancelInput: DocumentUncancelInput!) {
    documentUncancel(documentUncancelInput: $documentUncancelInput) {
      id
    }
  }
`;

export const DOCUMENTS_TRACK_OPEN = gql`
  mutation($id: Int!) {
    documentTrackOpen(id: $id)
  }
`;

export const DOCUMENT_ASSIGNMENT_CREATE = gql`
  mutation($documentAssignmentCreateInput: DocumentAssignmentCreateInput!) {
    documentAssignmentCreate(
      documentAssignmentCreateInput: $documentAssignmentCreateInput
    ) {
      id
    }
  }
`;

export const DOCUMENT_DISPATCH = gql`
  mutation($documentDispatchInput: DocumentDispatchInput!) {
    documentDispatch(documentDispatchInput: $documentDispatchInput) {
      id
    }
  }
`;

export const DOCUMENT_ASSESS = gql`
  mutation($documentAssessInput: DocumentAssessInput!) {
    documentAssess(documentAssessInput: $documentAssessInput) {
      id
    }
  }
`;

export const DOCUMENTS_UPLOAD = gql`
  mutation($documentUploadInput: DocumentUploadInput!, $documentFile: Upload!) {
    documentUpload(
      documentUploadInput: $documentUploadInput
      documentFile: $documentFile
    ) {
      id
    }
  }
`;

export const DOCUMENTS_SHARE = gql`
  mutation($documentShareInput: DocumentShareInput!) {
    documentShare(documentShareInput: $documentShareInput) {
      id
    }
  }
`;

export const DOCUMENTS_UNSHARE = gql`
  mutation($documentUnshareInput: DocumentUnshareInput!) {
    documentUnshare(documentUnshareInput: $documentUnshareInput) {
      id
    }
  }
`;

export const DOCUMENTS_ACCEPT = gql`
  mutation AcceptDocument($documentId: Int!) {
    documentAccept(documentAcceptInput: { documentId: $documentId }) {
      id
    }
  }
`;

export const DOCUMENTS_SHIP = gql`
  mutation(
    $addressInput: AddressInput
    $documentShipInput: DocumentShipInput!
  ) {
    documentShip(
      documentShipInput: $documentShipInput
      addressInput: $addressInput
    ) {
      id
    }
  }
`;

export const DOCUMENTS_SELECT_INDEX = gql`
  query($offset: Int, $filter: FullDocumentFilter) {
    documents(
      paging: { limit: 5, offset: $offset }
      sorting: [
        { field: updatedAt, direction: DESC }
        { field: createdAt, direction: DESC }
      ]
      filter: $filter
    ) {
      totalCount
      nodes {
        __typename
        ... on Document {
          id
          documentNumber
          internalNumber
          name
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          title
          description
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            number
            name
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on RequestFormNote {
          id
          documentNumber
          internalNumber
          name
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          title
          noteDescription: description
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on ContractAgreement {
          id
          documentNumber
          internalNumber
          name
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          title
          description
          grossValueInPLN
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on RequestForm {
          id
          documentNumber
          internalNumber
          name
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          title
          description
          totalValue
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on FinancialAccountingDocument {
          id
          documentNumber
          internalNumber
          name
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          title
          description
          grossValue
          amountInCurrency
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on BillIssuanceRequest {
          id
          documentNumber
          internalNumber
          name
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          title
          description
          grossValue
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on ContractPreparationRequest {
          id
          documentNumber
          internalNumber
          name
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          title
          description
          grossValueInPLN
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
        ... on Payment {
          id
          documentNumber
          internalNumber
          name
          documentKind
          documentType
          type
          documentDate
          createdAt
          postStampAt
          title
          description
          recipient {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          sender {
            __typename
            ... on User {
              id
              firstName
              lastName
            }
            ... on Address {
              id
              name
            }
            ... on HiddenAddress {
              id
              name
            }
          }
          case {
            id
            name
            number
            isConductedElectronically
          }
          organizationalUnit {
            id
            name
            symbol
          }
          currentStatus {
            documentFlowStepId
            documentFlowStep {
              id
              name
              documentFlow {
                steps {
                  id
                }
              }
            }
            createdAt
          }
        }
      }
    }
  }
`;

export const DOCUMENTS_ZIP_EXPORT = gql`
  mutation DownloadZip($documentId: Int!) {
    documentExportZip(documentExportZipInput: { documentId: $documentId })
  }
`;

export const DOCUMENTS_FLOW_START = gql`
  mutation($documentFlowStartInput: DocumentFlowStartInput!) {
    documentFlowStart(documentFlowStartInput: $documentFlowStartInput) {
      id
    }
  }
`;

export const DOCUMENT_FILES_SHOW = gql`
  query($id: Int!) {
    document(id: $id) {
      files {
        __typename
        ... on File {
          id
          downloadUrl
          originalName
          mimetype
          createdAt
        }
        ... on HiddenFile {
          id
        }
      }
    }
  }
`;

export const DOCUMENTS_CANCEL_DISPATCH = gql`
  mutation DocumentDispachCancel($documentId: Int!) {
    documentDispatchCancel(
      documentDispatchCancelInput: { documentId: $documentId }
    ) {
      id
    }
  }
`;

export const DOCUMENTS_SET_RESPONSIBLE = gql`
  mutation($documentId: Int!) {
    documentResponsibleSet(documentId: $documentId) {
      id
    }
  }
`;

export const DOCUMENTS_RELEASE_RESPONSIBLE = gql`
  mutation($documentId: Int!) {
    documentResponsibleRelease(documentId: $documentId) {
      id
    }
  }
`;

export const DOCUMENTS_COMPLETE_EDITION = gql`
  mutation($documentId: Int!) {
    documentEditCompleted(documentId: $documentId) {
      id
    }
  }
`;

export const DOCUMENTS_START_EDITION = gql`
  mutation($documentId: Int!) {
    documentEditStart(documentId: $documentId) {
      id
    }
  }
`;
