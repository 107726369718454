import { FIELDS, FieldsGroup } from "../../fields.d";
import { PaymentItem, PaymentType } from "./payments.d";

export type FormInputs = {
  documentType: string;
  documentKind: string;
  organizationalUnitId: number;
  paymentTypes?: { id: string; label?: string }[];
  paymentType?: PaymentType;
  nrRejestr: string;
  financialAccountingDocumentId: number;
  substantivelyAcceptedById?: number;
  controllingAcceptedById?: number;
  accountingAcceptedById?: number;
  payoutAcceptedById?: number;
  paymentItems: PaymentItem[];
};

export const PAYMENTS_FIELDS: FieldsGroup[] = [
  {
    id: "financialAccountingDocument",
    label: "Dokument",
    fields: [
      {
        id: "financialAccountingDocument",
        label: "Dokument F-K",
        type: FIELDS.Input,
        dataType: "model:documents",
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "internalNumber"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "organizationalUnit",
        label: "Jednostka organizacyjna",
        type: FIELDS.Input,
        dataType: "model:organizational-units",
        span: 3,
        show: { visible: true, accessor: ["organizationalUnit", "name"] },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "financialAccountingDocumentDate",
        label: "Data wystawienia",
        type: FIELDS.Input,
        dataType: "date",
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "documentDate"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "paymentAt",
        label: "Termin płatności",
        type: FIELDS.Input,
        dataType: "date",
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "paymentAt"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "amount",
        label: "Kwota",
        type: FIELDS.Input,
        dataType: "quota",
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "grossValue"],
        },
        create: { visible: false },
        edit: { visible: false },
      },

      // {
      //   id: "invoiceType",
      //   label: "Typ faktury",
      //   type: FIELDS.Input,
      //   span: 3,
      //   show: { visible: true, accessor: ["invoice", "invoiceType"] },
      //   create: { visible: false },
      //   edit: { visible: false },
      // },
      {
        id: "legalAct",
        label: "Podstawa prawna z wniosku",
        type: FIELDS.Input,
        span: 9,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "financialAccountingDocumentTitle",
        label: "Tytuł",
        type: FIELDS.Input,
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "title"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "financialAccountingDocumentProgramName",
        label: "Program",
        type: FIELDS.Input,
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "programName"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "financialAccountingDocumentDescription",
        label: "Opis",
        type: FIELDS.Input,
        span: 6,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "description"],
        },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "paymentType",
        label: "Typ płatności",
        type: FIELDS.Input,
        dataType: "payment-type",
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "paymentTypes",
        label: "Typ płatności",
        type: FIELDS.PaymentTypesSelect,
        placeholder: "Wybierz",
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "nrRejestr",
        label: "Numer dokumentu z rejestru",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        span: 3,
        dataType: "datetime",
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        span: 3,
        dataType: "datetime",
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
    ],
  },
  {
    id: "sender",
    label: "Kontrahent",
    fields: [
      {
        id: "addressName",
        label: "Nazwa kontrahenta",
        type: FIELDS.Input,
        span: 9,
        show: {
          accessor: ["financialAccountingDocument", "sender", "name"],
          visible: true,
        },
        create: { visible: false },
        edit: { visible: false },
        typeName: ["Incoming"],
      },
      // {
      //   id: "addressNip",
      //   label: "NIP",
      //   type: FIELDS.Input,
      //   span: 3,
      //   show: {
      //     accessor: ["financialAccountingDocument", "sender", "nip"],
      //     visible: true,
      //   },
      //   create: { visible: false },
      //   edit: { visible: false },
      //   dataType: "nip",
      //   typeName: ["Incoming"],
      // },
      // {
      //   id: "addressAddress",
      //   label: "Ulica i numer",
      //   type: FIELDS.Input,
      //   span: 3,
      //   show: {
      //     visible: true,
      //     accessor: ["financialAccountingDocument", "sender", "address"],
      //   },
      //   create: { visible: false },
      //   edit: { visible: false },
      //   typeName: ["Incoming"],
      // },
      // {
      //   id: "addressPostalCode",
      //   label: "Kod pocztowy",
      //   type: FIELDS.Input,
      //   span: 3,
      //   show: {
      //     accessor: ["financialAccountingDocument", "sender", "postalCode"],
      //     visible: true,
      //   },
      //   create: { visible: false },
      //   edit: { visible: false },
      //   typeName: ["Incoming"],
      // },
      // {
      //   id: "addressCity",
      //   label: "Miejscowość",
      //   type: FIELDS.Input,
      //   span: 3,
      //   show: {
      //     accessor: ["financialAccountingDocument", "sender", "city"],
      //     visible: true,
      //   },
      //   create: { visible: false },
      //   edit: { visible: false },
      //   typeName: ["Incoming"],
      // },
      // {
      //   id: "addressCountryCode",
      //   label: "Kraj",
      //   type: FIELDS.DictionaryValuesSelect,
      //   span: 3,
      //   show: {
      //     accessor: ["financialAccountingDocument", "sender", "countryCode"],
      //     visible: true,
      //   },
      //   create: { visible: false },
      //   edit: { visible: false },
      //   dataType: "countryCode",
      //   typeName: ["Incoming"],
      // },
      // {
      //   id: "addressPhoneNumber",
      //   label: "Numer telefonu",
      //   type: FIELDS.Input,
      //   span: 3,
      //   show: {
      //     accessor: ["financialAccountingDocument", "sender", "phoneNumber"],
      //     visible: true,
      //   },
      //   create: { visible: false },
      //   edit: { visible: false },
      //   typeName: ["Incoming"],
      //   mask: "99 999-99-99",
      //   placeholder: "99 999-99-99",
      // },
      // {
      //   id: "addressMobileNumber",
      //   label: "Numer telefonu kom.",
      //   type: FIELDS.Input,
      //   span: 3,
      //   show: {
      //     accessor: ["financialAccountingDocument", "sender", "mobileNumber"],
      //     visible: true,
      //   },
      //   create: { visible: false },
      //   edit: { visible: false },
      //   typeName: ["Incoming"],
      //   mask: "+99 999-999-999",
      //   placeholder: "+99 999-999-999",
      // },
      // {
      //   id: "addressEmail",
      //   label: "Adres e-mail",
      //   type: FIELDS.Input,
      //   span: 3,
      //   show: { visible: false },
      //   create: { visible: false },
      //   edit: { visible: false },
      //   dataType: "email",
      //   typeName: ["Incoming"],
      // },
      // {
      //   id: "addressEmail",
      //   label: "Adres e-mail",
      //   type: FIELDS.Input,
      //   span: 6,
      //   show: {
      //     accessor: ["financialAccountingDocument", "sender", "email"],
      //     visible: true,
      //   },
      //   create: { visible: false },
      //   edit: { visible: false },
      //   dataType: "email",
      //   typeName: ["Incoming"],
      // },
      {
        id: "addressBankAccount",
        label: "Konto bankowe",
        type: FIELDS.BankAccountsSelect,
        span: 12,
        show: {
          visible: false,
          accessor: ["financialAccountingDocument", "bankAccount", "payeeName"],
        },
        edit: { visible: false, required: true },
        create: { visible: false, required: true },
        typeName: ["Incoming"],
        placeholder: "Wybierz",
      },
      {
        id: "payeeName",
        label: "Właściciel rachunku",
        type: FIELDS.Input,
        span: 6,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "bankAccount", "payeeName"],
        },
        edit: { visible: false, required: true },
        create: { visible: false, required: true },
        typeName: ["Incoming"],
      },
      {
        id: "accountNumber",
        label: "Numer rachunku",
        type: FIELDS.Input,
        span: 6,
        show: {
          visible: true,
          accessor: [
            "financialAccountingDocument",
            "bankAccount",
            "accountNumber",
          ],
        },
        edit: { visible: false, required: true },
        create: { visible: false, required: true },
        typeName: ["Incoming"],
        // mask: "aa 99 9999 9999 9999 9999 9999 9999 9999",
        // maskChar: "",
        placeholder: "XX 99 9999 9999 9999 9999 9999 9999 9999",
        caption: "Wskazówka: XX — kod kraju",
      },
      {
        id: "bankName",
        label: "Nazwa banku",
        type: FIELDS.Input,
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "bankAccount", "bankName"],
        },
        edit: { visible: false },
        create: { visible: false },
        typeName: ["Incoming"],
      },
      {
        id: "bankCode",
        label: "Kod SWIFT/BIC",
        caption: `Link pomocniczny: <a target="_blank" href="https://www2.swift.com/bsl/">Wyszukiwarka kodów</a>`,
        type: FIELDS.Input,
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "bankAccount", "bankCode"],
        },
        edit: { visible: false },
        create: { visible: false },
        typeName: ["Incoming"],
      },
      {
        id: "accountType",
        label: "Typ konta",
        dataType: "account-type",
        caption: `Link pomocniczny: <a target="_blank" href="https://pl.wikipedia.org/wiki/Mi%C4%99dzynarodowy_numer_rachunku_bankowego">IBAN</a>`,
        type: FIELDS.BankAccountTypesSelect,
        span: 3,
        show: {
          visible: true,
          accessor: [
            "financialAccountingDocument",
            "bankAccount",
            "accountType",
          ],
        },
        edit: { visible: false },
        create: { visible: false },
        typeName: ["Incoming"],
      },
      {
        id: "accountCurrency",
        label: "Waluta",
        type: FIELDS.CurrencySelect,
        span: 3,
        show: {
          visible: true,
          accessor: ["financialAccountingDocument", "bankAccount", "currency"],
        },
        edit: { visible: false },
        create: { visible: false },
        typeName: ["Incoming"],
      },
    ],
  },
  // {
  //   id: "case",
  //   label: "Znak sprawy",
  //   fields: [
  //     {
  //       id: "case",
  //       label: "Sprawa",
  //       type: FIELDS.Input,
  //       span: 12,
  //       show: { visible: true },
  //       create: { visible: false },
  //       edit: { visible: false },
  //     },
  //   ],
  // },
  {
    id: "bankAccount",
    label: "Przelewy",
    fields: [
      {
        id: "paymentItems",
        label: "",
        type: FIELDS.TransferTable,
        span: 12,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
];
